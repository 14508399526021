import React from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import { TClient } from "src/common/types";
import { renderContactModal } from "src/contact-modal/contact-modal.component";

type AddContactProps = {
  client?: TClient;
  btnType?: "primary" | "secondary" | "tertiary" | "flat";
};

export function AddContact({ btnType = "primary" }: AddContactProps) {
  const hasAccess = useHasAccess("clients_create_edit");

  if (!hasAccess) return null;

  return (
    <>
      <CpButton btnType={btnType} onClick={() => renderContactModal({ mode: "create" })}>
        Add contact
      </CpButton>
    </>
  );
}
