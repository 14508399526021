import React from "react";
// project
import { useFormInput } from "src/forms-lib";
import { ClientTypePicker } from "src/common/form/client/base";

export default function TypeSection(props) {
  const [type, updateType] = useFormInput({
    name: props.name,
    initialValue: props.initialValue,
    shared: props.shareValue,
  });

  return <ClientTypePicker value={type} onChange={(value) => setType(value)} />;

  function setType(type) {
    updateType(type);
  }
}
