import * as React from "react";
import { Scoped } from "kremling";
// project
import styles from "./deleted-item.krem.css";
// Sofe
import { CpButton } from "canopy-styleguide!sofe";

export default class DeletedItem extends React.Component {
  timeout = undefined;

  static defaultProps = {
    timeout: 3000,
  };

  componentDidMount() {
    this.setupTimeout();
  }

  componentWillUnmount() {
    this.clear();
  }

  render() {
    return (
      <Scoped css={styles}>
        <div>
          <div
            className="deletedBlock cps-bg-color-gray5 cps-margin-bottom-16"
            onMouseEnter={this.clear}
            onMouseLeave={this.setupTimeout}
          >
            <div className="deletedBlockContents">
              <div>{this.props.itemDescription} has been removed</div>
              <CpButton
                btnType="flat"
                onClick={this.handleUndo}
                onFocus={this.clear}
                onBlur={this.setupTimeout}
                type="button"
              >
                Undo
              </CpButton>
            </div>
          </div>
        </div>
      </Scoped>
    );
  }

  handleUndo = () => {
    this.clear();
    this.props.cancelPendingDelete(this.props.item.id);
  };

  setupTimeout = () => {
    if (!this.timeout) {
      this.timeout = window.setTimeout(() => {
        this.props.deleteItem(this.props.item.id);
      }, this.props.timeout);
    }
  };

  clear = () => {
    window.clearTimeout(this.timeout);
    this.timeout = undefined;
  };
}
