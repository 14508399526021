import React from "react";
import { CpInput } from "canopy-styleguide!sofe";
import { StateSelect } from "./state-select.component";
import { CountrySelect } from "./country-select.component";

export type TAddress = {
  address_1: string;
  address_2: string;
  country: string;
  locality: string;
  postal_code: string;
  region: string;
};

interface AddressInputsProps {
  value: TAddress;
  onChange: (value: TAddress) => void;
}

export function AddressInputs({ value, onChange }: AddressInputsProps) {
  function updateValue(key: string, updatedValue: string) {
    onChange({
      ...value,
      [key]: updatedValue,
    });
  }

  return (
    <div className="flex flex-col cp-gap-16">
      <CpInput
        placeholder="Street 1"
        value={value?.address_1}
        onChange={(val: string) => updateValue("address_1", val)}
        maxLength={100}
      />
      <CpInput
        placeholder="Street 2"
        value={value?.address_2}
        onChange={(val: string) => updateValue("address_2", val)}
        maxLength={100}
      />
      <div className="flex justify-between cp-gap-24">
        <div className="grow">
          <CpInput
            placeholder="City"
            value={value?.locality}
            onChange={(val: string) => updateValue("locality", val)}
            maxLength={100}
          />
        </div>
        {value?.country === "United States" ? (
          <StateSelect value={value?.region} onChange={(val) => updateValue("region", val)} />
        ) : (
          <CpInput
            value={value?.region}
            onChange={(val: string) => updateValue("region", val)}
            placeholder="Province/Region"
          />
        )}
        <CpInput
          placeholder="Zip code"
          value={value?.postal_code}
          onChange={(val: string) => updateValue("postal_code", val)}
          maxLength={12}
        />
      </div>
      <CountrySelect value={value?.country} onChange={(val) => updateValue("country", val)} />
    </div>
  );
}
