import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function getClientSources() {
  // Ignore pagination on this for now, because the limit is 800 per page and that's a lot of sources.
  return fetchAsObservable("/api/client_sources").pipe(pluck("client_sources"));
}

export function createSource(sourceName) {
  const url = `/api/client_sources`;
  const body = {
    client_sources: {
      name: sourceName,
    },
  };

  return fetchAsObservable(url, {
    method: "POST",
    body,
  }).pipe(pluck("client_sources"));
}

export function deleteSources(sourceIds) {
  const url = `/api/client_sources/${sourceIds}`;

  return fetchAsObservable(url, {
    method: "DELETE",
  }).pipe(pluck("client_sources"));
}

export function mergeSources(newName, sourceIds) {
  const url = `/api/client_sources`;
  const body = {
    client_sources: {
      name: newName,
      merges: sourceIds,
    },
  };

  return fetchAsObservable(url, {
    method: "PATCH",
    body,
  }).pipe(pluck("client_sources"));
}

export function editSource(newName, sourceId) {
  const url = `/api/client_sources/${sourceId}`;
  const body = {
    client_sources: {
      name: newName,
      id: sourceId,
    },
  };

  return fetchAsObservable(url, {
    method: "PUT",
    body,
  }).pipe(pluck("client_sources"));
}
