import { useMemo } from "react";
import { useQuery } from "src/react-query";
import { customFieldQueries } from "src/queries";

export default function useCustomFieldsQuery({ individualOrBusiness = "both" } = {}) {
  const query = useQuery(customFieldQueries.customFields());

  const customFields = useMemo(() => {
    if (individualOrBusiness === "both") {
      return query.data;
    } else {
      return query.data?.filter((field) => {
        const filterKey = individualOrBusiness === "business" ? "businesses" : "individuals";
        return field.client_type === filterKey || field.client_type === "both";
      });
    }
  }, [query.data, individualOrBusiness]);

  return {
    customFieldsQuery: query,
    customFields,
  };
}
