// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-dedupe-modal-merge-card-group-merge-card-group-styles__duplicate {
  margin: 0px 16px 0px 16px;
}

.src-dedupe-modal-merge-card-group-merge-card-group-styles__duplicateSet {
  background: #eaebef;
  padding: 1px 12px;
  border-radius: 8px;
  margin: 16px 4px 0 4px;
}
`, "",{"version":3,"sources":["webpack://./src/dedupe-modal/merge-card-group/merge-card-group.styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".duplicate {\n  margin: 0px 16px 0px 16px;\n}\n\n.duplicateSet {\n  background: #eaebef;\n  padding: 1px 12px;\n  border-radius: 8px;\n  margin: 16px 4px 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"duplicate": `src-dedupe-modal-merge-card-group-merge-card-group-styles__duplicate`,
	"duplicateSet": `src-dedupe-modal-merge-card-group-merge-card-group-styles__duplicateSet`
};
export default ___CSS_LOADER_EXPORT___;
