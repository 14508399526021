import { useQuery } from "src/react-query";
import { getStates } from "src/resources/states.resource";

export function useStatesQuery() {
  const query = useQuery<any>({
    queryKey: ["states"],
    queryFn: () => new Promise((resolve, reject) => getStates().subscribe(resolve, reject)),
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return {
    statesQuery: query,
    states: query.data,
  };
}
