import React from "react";
import { CpLabel, CprDatepicker } from "canopy-styleguide!sofe";
import { useController } from "react-hook-form";

interface DateInputFieldProps {
  control: any;
  fieldName: string;
  label?: string;
  placeholder?: string;
  orientation?: string;
}

export function DateInputField({ control, fieldName, label, placeholder, orientation }: DateInputFieldProps) {
  const {
    field: { value, onChange },
  } = useController({ name: fieldName, control });

  function onDateChange(e: any) {
    onChange(e.detail);
  }

  return (
    <div>
      {label && <CpLabel htmlFor={fieldName}>{label}</CpLabel>}
      <CprDatepicker
        id={fieldName}
        placeholder={placeholder || "mm/dd/yyyy"}
        orientation={orientation || "bottom"}
        date={value}
        events={{
          datechange: onDateChange,
        }}
      />
    </div>
  );
}
