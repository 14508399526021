import * as React from "react";
import FormContext from "./form.context";
import { handleError } from "src/error";

export default function (DecoratedComponent) {
  const originalDisplayName = DecoratedComponent.displayName || DecoratedComponent.name || "unknown";

  class FormSharedValueConsumer extends React.Component {
    static displayName = `FormSharedValueConsumer(${originalDisplayName})`;

    state = { value: undefined };

    componentDidMount() {
      this.subscription = this.props.context.sharedStateObs.subscribe((sharedState) => {
        const sharedKey = this.props.sharedKey;
        const valueToShare = sharedKey ? sharedState[sharedKey] : sharedState;
        if (valueToShare === undefined && sharedKey && !this.props.alwaysShow) {
          console.warn(
            `Provided sharedKey "${sharedKey}" did not exist on the sharedModel. ${originalDisplayName} was not rendered.`
          );
        }
        this.setState({ value: valueToShare });
      }, handleError);
    }

    render() {
      const { alwaysShow, context, sharedKey, ...rest } = this.props;
      if (this.props.alwaysShow || this.state.value !== undefined) {
        return <DecoratedComponent {...rest} sharedValue={this.state.value} />;
      } else {
        return null;
      }
    }

    componentWillUnmount() {
      this.subscription.unsubscribe();
    }
  }

  return function (props) {
    return (
      <FormContext.Consumer>{(value) => <FormSharedValueConsumer {...props} context={value} />}</FormContext.Consumer>
    );
  };
}
