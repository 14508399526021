import React, { lazy, Suspense, useState } from "react";
import { useHasAccess } from "cp-client-auth!sofe";
import { getCreateRoleInput } from "global-settings!sofe";
import { CpButton, CpDropdown, CpLoader } from "canopy-styleguide!sofe";
import { createRole } from "src/resources/roles.resource.js";
import { roleQueries } from "global-settings/externals!sofe";

const CreateRoleInput = lazy(getCreateRoleInput);

export default function CreateRole() {
  const [value, setValue] = useState("");
  const [inputError, setInputError] = useState("");
  const hasCreateAccess = useHasAccess("company_settings_roles_create_edit");

  const onSave = (close) => {
    createRole(value).subscribe(() => {
      roleQueries.invalidate();
      setValue("");
      close();
    });
  };

  const onCancel = (close) => {
    setValue("");
    close();
  };

  if (!hasCreateAccess) {
    return null;
  }

  return (
    <CpDropdown
      contentWidth={300}
      appendTo="parent"
      allowContentClicks
      renderWhenClosed={false}
      renderTrigger={({ toggle }) => (
        <CpButton className="shrink-0" btnType="flat" onClick={toggle}>
          Create new role
        </CpButton>
      )}
      renderContent={({ close }) => (
        <div className="cp-p-16">
          <Suspense fallback={<CpLoader />}>
            <CreateRoleInput
              className="cp-mb-16"
              placeholder="Enter role name"
              value={value}
              setValue={setValue}
              onEnterKey={inputError ? null : () => onSave(close)}
              onErrorChange={setInputError}
            />
          </Suspense>
          <CpButton onClick={() => onSave(close)} disabled={!value || !!inputError} className="cp-mr-8">
            Save
          </CpButton>
          <CpButton btnType="flat" onClick={() => onCancel(close)}>
            Cancel
          </CpButton>
        </div>
      )}
    />
  );
}
