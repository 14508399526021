import React, { useState } from "react";
import { CpButton, CpIcon, CpModal, CpRadio, CpSwitch, CpCase, CpTooltip, CpWell } from "canopy-styleguide!sofe";
import RolesSection from "src/common/roles-section/roles-section.component";
import UsersTeamsSection from "src/common/users-teams-section/users-teams-section.component";
import RoleAssignmentSelect from "src/form/role-assignment-select/role-assignment-select.component";
import SelectTemplateButton from "../../templates/select-template-button/select-template-button.component";
import { rolesConvertTemplateApiToForm } from "src/templates/template.helpers";
import { patchClients } from "src/client-list/client-list.resource";
import { successToast } from "toast-service!sofe";
import { forIn, isEmpty } from "lodash";
import useTeamMembersQuery from "src/common/queries/use-team-members-query.hook.js";
import { handleError } from "src/error";
import { useHasAccess } from "cp-client-auth!sofe";
import { clientQueries, useRolesQuery } from "src/queries";

const actions = {
  add: "add_role_assignments",
  remove: "remove_role_assignments",
  removeAll: "remove_all_assignments",
};

export default function BulkManageAssignmentsModal({ onClose, filters, selection, search }) {
  const { roles } = useRolesQuery();
  const { teamMembers: users, teams } = useTeamMembersQuery();

  const [show, setShow] = useState(true);
  const [action, setAction] = useState(actions.add);
  const [rolesValues, setRolesValues] = useState({});
  const [usersTeamsValues, setUsersTeamsValues] = useState([]);
  const [clearAllValues, setClearAllValues] = useState([]);

  const canApplyTemplates = useHasAccess("templates_client_records_apply");

  const saveDisabled =
    action === actions.removeAll ? isEmpty(clearAllValues) : isEmpty(rolesValues) && isEmpty(usersTeamsValues);

  const closeModal = () => {
    setShow(false);
  };

  const rolesOnChange = (value, roleId) => {
    setRolesValues((prev) => {
      if (isEmpty(value)) {
        // remove by key if value is empty
        const newState = { ...prev };
        delete newState[roleId];
        return newState;
      }
      return {
        ...prev,
        [roleId]: value,
      };
    });
  };

  const onSave = () => {
    let role_assignments = [];
    const body = {
      search,
      filters,
      action,
      [selection.type]: selection.toArray(),
      user_ids: [],
      team_ids: [],
    };

    if (action === actions.removeAll) {
      clearAllValues.forEach((val) => {
        if (!val) return;
        val.role === "TeamMember" ? body.user_ids.push(val.id) : body.team_ids.push(val.id);
      });
    } else {
      usersTeamsValues.forEach((val) => {
        if (!val) return;
        val.role === "TeamMember" ? body.user_ids.push(val.id) : body.team_ids.push(val.id);
      });
      forIn(rolesValues, (roles, role_id) => {
        const users = [];
        const teams = [];
        roles.forEach((item) => {
          if (!item) return;
          item.role === "TeamMember" ? users.push(item.id) : teams.push(item.id);
        });
        role_assignments.push({
          role_id,
          user_ids: users,
          team_ids: teams,
        });
      });
      body.role_assignments = role_assignments;
    }

    patchClients(body).subscribe((response) => {
      const toastMsg =
        action === "add_role_assignments" ? "Users & Teams successfully added" : "Users & Teams successfully removed";
      successToast(toastMsg);
      clientQueries.invalidate();
      closeModal();
    }, handleError);
  };

  const applyTemplate = (templateData) => {
    const { roles: newRoles, usersTeams: newUsersTeams } = rolesConvertTemplateApiToForm(templateData, users, teams);
    setRolesValues(newRoles);
    setUsersTeamsValues(newUsersTeams);
  };

  return (
    <CpModal show={show} onClose={closeModal} onAfterClose={onClose} width={736}>
      <CpModal.Header title="Manage Assignments" />
      <CpModal.Body>
        <div className="cp-mb-16">
          <CpRadio onChange={setAction} value={action} name="actions" inline>
            <CpRadio.Item className="cp-mb-16" id={actions.add}>
              Add assignments
            </CpRadio.Item>
            <CpRadio.Item className="cp-mb-16" id={actions.remove}>
              Remove assignments
            </CpRadio.Item>
            <CpRadio.Item className="cp-mb-16" id={actions.removeAll}>
              Clear all assignments
            </CpRadio.Item>
          </CpRadio>
          <div className="cp-body cp-mb-16">
            {action === "add_role_assignments" && (
              <i>
                These users and teams will be <b>added</b> to {selection.totalSelected} clients.{" "}
              </i>
            )}
            {action === "remove_role_assignments" && (
              <>
                <i>
                  These users and teams will be <b>removed</b> from {selection.totalSelected} clients.{" "}
                </i>
                <CpTooltip text="A removed user may retain access to a client record as part of another role or team assignment.">
                  <CpIcon name="information-circle-open-small" />
                </CpTooltip>
              </>
            )}
            {action === actions.removeAll && (
              <>
                <i>
                  These users and teams will be <b>removed from all roles</b> for {selection.totalSelected} clients.{" "}
                </i>
                <CpTooltip text="A removed user may retain access to a client record as part of another team assignment.">
                  <CpIcon name="information-circle-open-small" />
                </CpTooltip>
              </>
            )}
          </div>
        </div>
        <CpSwitch expression={action}>
          <CpCase value={actions.removeAll}>
            <RoleAssignmentSelect value={clearAllValues} onChange={setClearAllValues} />
          </CpCase>
          <CpCase default>
            {canApplyTemplates && action === actions.add && (
              <div className="cp-mb-16 flex justify-end items-center">
                <SelectTemplateButton
                  onApplyTemplate={applyTemplate}
                  isManageAssignments
                  validateForm={() => {
                    return [!isEmpty(rolesValues) || usersTeamsValues.length > 0];
                  }}
                />
                <CpTooltip text="Client record templates will only affect Roles and General User/Team assignments">
                  <CpIcon name="information-circle-open-small" />
                </CpTooltip>
              </div>
            )}
            <CpWell className="cp-p-16 cp-mb-16 cp-flex-column cp-gap-16">
              {roles.length > 0 && (
                <>
                  <div className="cp-body-sm cp-wt-semibold">Roles</div>
                  <RolesSection roles={roles} value={rolesValues} onChange={rolesOnChange} />
                </>
              )}
              <UsersTeamsSection value={usersTeamsValues} onChange={setUsersTeamsValues} hasRoles={roles.length > 0} />
            </CpWell>
          </CpCase>
        </CpSwitch>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" className="cp-mr-8" disabled={saveDisabled} onClick={onSave}>
          Save
        </CpButton>
        <CpButton btnType="flat" onClick={closeModal}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
