import React, { ReactNode } from "react";

type SingleItemProps = {
  label: string;
  value: string | ReactNode;
};

export function SingleItem({ label, value }: SingleItemProps) {
  if (!value) {
    return null;
  }
  return (
    <div>
      <div className="cp-color-app-secondary-text cp-caption">{label}</div>
      <div className="cp-body-sm cp-ellipsis">{value}</div>
    </div>
  );
}
