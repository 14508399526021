import { useCallback, useState } from "react";
import { useDebounce } from "canopy-styleguide!sofe";

export function useDebouncedSearch({
  initialValue = "",
  debounceTime = 500,
  onDebouncedUpdate,
}: {
  initialValue?: string | (() => string);
  debounceTime?: number;
  onDebouncedUpdate?: (searchVal: string) => void;
} = {}) {
  const [search, setSearch] = useState(initialValue);
  const [debouncedSearch, setDebouncedSearch] = useState(initialValue);
  const debounceSearch = useDebounce(
    (val: string) => {
      setDebouncedSearch(val);
      onDebouncedUpdate?.(val);
    },
    debounceTime,
    {},
    []
  );

  const updateSearch = useCallback(
    (val: string) => {
      setSearch(val);
      debounceSearch(val.trim());
    },
    [debounceSearch]
  );

  return {
    search,
    debouncedSearch,
    updateSearch,
  };
}
