import * as React from "react";
import { Scoped } from "kremling";
// project
import { InputDecorator } from "src/forms-lib";
import styles from "./select-input.krem.css";
// sofe
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { FieldLabel } from "src/common/form/field-label.component";

type Props = {
  value: string;
  updateValue: (string: string) => void;
  options: Array<{ key: string; value: string }>;
  clearable?: boolean;
  labelText?: string;
};

export function SimpleSelect(props: Props) {
  const { value: selectValue, options, updateValue, clearable, ...rest } = props;

  function getValue() {
    if (!selectValue) return null;
    const label = options?.find((o) => o.key === selectValue)?.value || selectValue;
    return {
      key: selectValue,
      value: label,
    };
  }

  return (
    /* @ts-expect-error Scoped needs type defs */
    <Scoped css={styles}>
      <div className="select">
        {props.labelText && <FieldLabel>{props.labelText}</FieldLabel>}
        <CpSelectSingle
          clearable={clearable}
          triggerIsBlock
          value={getValue()}
          data={options}
          transformData={(item: any) => ({
            id: item.key || null,
            name: item.value || null,
          })}
          onChange={(val: any) => updateValue(val?.key || val)}
        />
      </div>
    </Scoped>
  );
}

export default InputDecorator(SimpleSelect);
