import { pluck } from "rxjs/operators";

import { fetchAsObservable } from "fetcher!sofe";

export function getIntegrationsObs() {
  return fetchAsObservable(`/wg/accounting-integrations`).pipe(pluck("integrations"));
}

export function getQboIntegrations() {
  return new Promise((resolve, reject) =>
    getIntegrationsObs().subscribe((integrations) => {
      const qboIntegrationInfo = integrations.find((i) => i.type === "qbo") || {};
      resolve(qboIntegrationInfo);
    }, reject)
  );
}

export function qboMatchNew(credentialsId, clientId) {
  return new Promise((resolve, reject) =>
    fetchAsObservable(
      `/wg/accounting-integrations/${credentialsId}/clients/${clientId}/match-create-new-entity-in-third-party?third_party=qbo`,
      { method: "POST" }
    ).subscribe(resolve, reject)
  );
}

export function getAvailableContactsForClient(id, clientId, type) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/clients/${clientId}/get-available-contacts?third_party=${type}`
  ).pipe(pluck("contacts"));
}

export function getAvailableContacts(id, type, contacts) {
  return fetchAsObservable(`/wg/accounting-integrations/${id}/get-available-contacts?third_party=${type}`, {
    method: "POST",
    body: { contacts },
  }).pipe(pluck("contacts"));
}
