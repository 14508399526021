import React from "react";
import { CpRadio, CpSelectSingleField, CpLabel } from "canopy-styleguide!sofe";
import { ClientSelectMultiPillField } from "src/client-groups-page/client-group-modals/form/client-select-multi-pill-field.component";
import { useClientGroupsQuery } from "src/common/queries/use-client-groups-query.hook";
import { useDebouncedSearch } from "src/common/use-debounced-search.hook";
import { ClientGroupNameField } from "src/client-groups-page/client-group-modals/form/client-group-name-field.component";
import { useFormContext } from "react-hook-form";

type AddToClientGroupFormProps = {
  action: string;
  setAction: (action: "create" | "add") => void;
  showClients?: boolean;
};
export function AddToClientGroupForm({ action, setAction, showClients = true }: AddToClientGroupFormProps) {
  const { debouncedSearch, search, updateSearch } = useDebouncedSearch();
  const { clientGroups } = useClientGroupsQuery({ search: debouncedSearch, limit: "50", page: 1 });
  const { control } = useFormContext();

  return (
    <>
      <CpRadio onChange={setAction} value={action} name="actions" inline>
        <CpRadio.Item className="cp-mb-16" id="create">
          Create client group
        </CpRadio.Item>
        <CpRadio.Item className="cp-mb-16" id="add">
          Add to existing client group
        </CpRadio.Item>
      </CpRadio>
      <div className="flex flex-col cp-gap-16">
        {action === "create" && <ClientGroupNameField fieldName="name" />}
        {action === "add" && (
          <div>
            <CpLabel required>Client group</CpLabel>
            <CpSelectSingleField
              control={control}
              fieldName="clientGroup"
              required
              data={clientGroups || []}
              searchValue={search}
              searchOnChange={updateSearch}
              triggerIsBlock
            />
          </div>
        )}
        {showClients && <ClientSelectMultiPillField fieldName="clients" label="Clients" />}
      </div>
    </>
  );
}
