import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { FilterViewCard } from "./filter-view-card.component";

type EditFilterViewsBodyProps = {
  filterViews: any[];
  markedForDelete: string[];
  onOrderChange: (orderedViewIds: string[]) => void;
  onVisibilityChange: (params: { filterView: any; visible: boolean }) => void;
  onDuplicate: (filterView: any) => void;
  onDelete: (filterView: any) => void;
  onRestore: (filterView: any) => void;
  renderRenameDropdown: any;
  renderShareListDropdown: any;
};

export function EditFilterViewsBody({
  filterViews,
  markedForDelete, // An array of filter view ids that have been marked for deletion
  onOrderChange,
  onVisibilityChange,
  onDuplicate,
  onDelete,
  onRestore,
  renderRenameDropdown,
  renderShareListDropdown,
}: EditFilterViewsBodyProps) {
  const [reorderedViews, setReorderedViews] = useState(filterViews);

  useEffect(() => {
    setReorderedViews(filterViews);
  }, [filterViews]);

  function handleReorder(result: any) {
    const { destination, source } = result;
    if (!source || !destination) return;
    const items = [...reorderedViews];
    const [movedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, movedItem);
    setReorderedViews(items);
    const orderedViewIds = items.map((item) => item.id);
    onOrderChange(orderedViewIds);
  }

  return (
    <DragDropContext onDragEnd={handleReorder}>
      <Droppable droppableId="filter-view-droppable-area">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {reorderedViews.map((fv, i) => {
              return (
                <Draggable key={fv.id} draggableId={fv.id} index={i}>
                  {(dragProvider) => (
                    <div ref={dragProvider.innerRef} {...dragProvider.draggableProps} {...dragProvider.dragHandleProps}>
                      <FilterViewCard
                        filterView={fv}
                        isMarkedForDelete={markedForDelete.includes(fv.id)}
                        onVisibilityChange={onVisibilityChange}
                        onDuplicate={onDuplicate}
                        onDelete={onDelete}
                        onRestore={onRestore}
                        renderRenameDropdown={renderRenameDropdown}
                        renderShareListDropdown={renderShareListDropdown}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
