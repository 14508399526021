import React from "react";
import { Control } from "react-hook-form";
import { CpInputField } from "canopy-styleguide!sofe";

type BusinessNameFieldProps = {
  control: Control;
  fieldName: string;
  label?: string;
};

export function BusinessNameField({ control, fieldName, label = "Business name" }: BusinessNameFieldProps) {
  return (
    <CpInputField
      fieldName={fieldName}
      control={control}
      label={label}
      required
      maxLength={150}
      rules={{
        required: {
          value: true,
          message: `${label} is required`,
        },
      }}
    />
  );
}
