import { useQuery } from "src/react-query";
import { tagQueries } from "src/queries";

export function useTagsQuery() {
  const query = useQuery(tagQueries.tags());
  return {
    tagsQuery: query,
    tags: query.data,
  };
}
