import { UseQueryOptions } from "@tanstack/react-query";
import { contactQueries } from "src/queries";
import { useQuery } from "src/react-query";
import { GetContactsParams } from "src/resources/contacts.resource";

export default function useContactsQuery(params: GetContactsParams, queryOptions?: Omit<UseQueryOptions, "queryKey">) {
  const query = useQuery<any>({
    ...contactQueries.contacts(params),
    ...queryOptions,
  });

  return {
    contactsQuery: query,
    contacts: query.data?.contacts,
  };
}
