import React, { useMemo, useState } from "react";
import { useDebounce } from "canopy-styleguide!sofe";
import { ColumnsSection } from "./columns-section.component";

type FilterViewCheckboxesProps = {
  sections: any[];
  columns: any[];
  selectedFilterView: any;
  debounceOnColumnVisibilityChange: (selectedFilterView: any, visibleColumns: string[]) => void;
  getIsFilterApplied: (filterView: any, column: { id: string }) => boolean;
};

export function FilterViewCheckboxes({
  sections,
  columns,
  selectedFilterView,
  debounceOnColumnVisibilityChange,
  getIsFilterApplied,
}: FilterViewCheckboxesProps) {
  const columnsBySection = useMemo(() => {
    const map: any = {};
    for (const column of columns.sort((a, b) => a.name.localeCompare(b.name))) {
      if (!map[column.section]) {
        map[column.section] = [];
      }
      map[column.section].push(column);
    }
    return map;
  }, [columns]);

  const [checkedColumnsBySection, setCheckedColumnsBySection] = useState(() => {
    const map: any = {};
    for (const section of sections) {
      map[section.id] = [];
    }
    for (const column of columns) {
      if (selectedFilterView.visibleColumns.includes(column.id)) {
        map[column.section].push(column.id);
      }
    }
    return map;
  });

  const debounceUpdateColumnOrder = useDebounce(
    (selectedFilterView: any, visibleColumns: string[]) => {
      debounceOnColumnVisibilityChange(selectedFilterView, visibleColumns);
    },
    1000,
    {},
    []
  );

  return (
    <div className="flex flex-col cp-gap-24">
      {sections.map((section) => (
        <ColumnsSection
          key={section.id}
          name={section.name}
          columns={columnsBySection[section.id]}
          checkedColumns={checkedColumnsBySection[section.id]}
          readOnly={selectedFilterView.readOnly}
          selectedFilterView={selectedFilterView}
          getIsFilterApplied={getIsFilterApplied}
          onColumnsChange={(checkedColumns) => {
            const newCheckedColumnsBySection = {
              ...checkedColumnsBySection,
              [section.id]: checkedColumns,
            };
            const visibleColumns = Object.values(newCheckedColumnsBySection).flatMap((val) => val);
            setCheckedColumnsBySection(newCheckedColumnsBySection);
            debounceUpdateColumnOrder(selectedFilterView, visibleColumns);
          }}
        />
      ))}
    </div>
  );
}
