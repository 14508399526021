import { resolution_actions, lookUpColumnLabel, lookUpColumnDataType } from "../dedupe-modal.helper.js";

export function mergeClients(conflictingClients = [], mergeChoices = {}) {
  const mergedClient = conflictingClients.reduce((prev, curr) => {
    return { ...prev, ...curr };
  });
  const resolvedClient = { ...mergedClient, ...mergeChoices, action: resolution_actions.merge };

  delete resolvedClient.conflicting_columns;
  delete resolvedClient.matching_columns;
  delete resolvedClient.conflicting_canopy_clients;

  return resolvedClient;
}

export function determineConflicts(duplicateSet, conflictingColumns, crmColumns) {
  const conflicts = conflictingColumns.map((conflictingColumn) => {
    return {
      name: conflictingColumn,
      label: lookUpColumnLabel(crmColumns, conflictingColumn),
      values: duplicateSet.map((duplicate) => duplicate[conflictingColumn]),
      dataType: lookUpColumnDataType(crmColumns, conflictingColumn),
    };
  });

  return conflicts;
}
