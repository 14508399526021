import { get } from "lodash";

export const clientToModel = {
  topSection: topSection,
  emails: mapEmailToModel,
  phones: mapPhoneToModel,
  addresses,
  bottomSection,
  always,
};

function always(client) {
  let created_on;
  if (client.created_on) {
    created_on = new Date(client.created_on);
  }

  const always = {
    id: client.id,
    created_on: created_on,
    owner_id: client.client_owner_id,
    note: client.notes,
    is_hidden: client.is_hidden,
    custom_fields: parseCustomFields(client),
    filing_status: client.filing_status,
    external_id: client.external_id,
  };

  return always;
}

function parseCustomFields(client) {
  const arrayRepresentation = client.custom_fields || [];
  let objectRepresentation = {};

  arrayRepresentation.map((field) => {
    if (field.type === "dropdown") {
      if (field.dropdown_values.length) {
        const selection = field.dropdown_values[0];
        objectRepresentation[field.field_id] = {
          type: field.type,
          value: { key: selection.id, value: selection.value },
        };
      }
    } else if (field.type === "multiselect") {
      if (field.dropdown_values.length) {
        const selections = field.dropdown_values.map((selection) => {
          return { key: selection.id, value: selection.value };
        });
        objectRepresentation[field.field_id] = { type: field.type, value: selections };
      }
    } else {
      objectRepresentation[field.field_id] = { type: field.type, value: field.value };
    }
  });
  return objectRepresentation;
}

function topSection(client) {
  const always = {
    first_name: client.first_name,
    middle_name: client.middle_name,
    last_name: client.last_name,
    is_business: client.is_business,
    client_type: client.client_type,
    business_name: client.business_name,
    is_active: client.is_active,
    display_name: client.display_name,
  };
  let maybe = {};

  if (client.client_since) {
    maybe.client_since = new Date(client.client_since);
  }

  return {
    ...always,
    ...maybe,
  };
}

function bottomSection(client) {
  let bottomSectionBusinessFields = {};
  let bottomSectionIndividualFields = {};
  if (client.is_business === true) {
    bottomSectionBusinessFields = {
      ein: client.tin,
      business_type: client.business_type,
      industry: client.industry,
      contact_person: client.contact_person,
    };
    if (client.date_established) {
      bottomSectionBusinessFields.date_established = new Date(client.date_established);
    }
  } else {
    bottomSectionIndividualFields = {
      ssn: client.tin,
      employer: client.employer_name,
      occupation: client.occupation,
    };
    if (client.birthdate) {
      bottomSectionIndividualFields.birth_date = new Date(client.birthdate);
    }
  }
  const tagsModel = tags(client);
  const sourceModel = source(client);
  return {
    ...bottomSectionBusinessFields,
    ...bottomSectionIndividualFields,
    ...tagsModel,
    note: client.notes,
    ...sourceModel,
    client_owner: client.client_owner_id,
  };
}

function tags(client) {
  if (client.relationships && client.relationships.tags) {
    return {
      tags: client.relationships.tags.map((tag) => tag.name),
    };
  } else {
    return {};
  }
}

function source(client) {
  const client_sources = get(client, "relationships.client_sources", []);
  const source = get(client_sources, "[0]", {});
  const referralReference = get(client, "relationships.clients", [])
    .filter((relationship) => relationship.relationship_type === "referral")
    .reduce((acc, relationship, index) => {
      if (index === 0) {
        acc = {
          relationship_type: relationship.relationship_type,
          id: relationship.id,
          name: relationship.name,
          user_has_access: relationship.user_has_access,
        };
      }
      return acc;
    }, undefined);
  if (referralReference !== undefined) {
    source.selected = referralReference;
  }
  return { source };
}

function mapPhoneToModel(client) {
  const numbers = get(client, "phones", [])
    .map((phone) => {
      if (phone.id !== undefined) {
        const [splitNumber, extension] = phone.value.split(" x ");
        return {
          id: phone.id,
          number: phone.number || splitNumber,
          ext: phone.extension || extension,
          type: phone.key === "main" ? "work" : phone.key,
          is_primary: phone.isPrimary,
        };
      }
    })
    .filter((phone) => !!phone);
  return {
    phone_numbers: numbers.length > 0 ? numbers : undefined,
  };
}

function mapClientSinceToModel(client) {
  return {
    client_since: client.client_since,
  };
}

function mapEmailToModel(client) {
  const emailArray = get(client, "emails", [])
    .map((email) => {
      if (email.id) {
        return {
          id: email.id,
          type: email.key === "main" ? "work" : email.key,
          address: email.value,
          is_primary: email.isPrimary,
        };
      }
    })
    .filter((email) => !!email);
  return {
    email_addresses: emailArray.length > 0 ? emailArray : undefined,
  };
}

function addresses(client) {
  const addressArray = get(client, "addresses", [])
    .map((address) => {
      if (checkForAnyAddressValue(address.value)) {
        const mappedAddress = mapAddress(address.value);
        return {
          id: address.id,
          type: addressKeyMap(address.key),
          is_primary: address.isPrimary,
          ...mappedAddress,
        };
      }
    })
    .filter((address) => !!address);
  return {
    addresses: addressArray.length > 0 ? addressArray : undefined,
  };
}

function checkForAnyAddressValue(address) {
  return (
    address.address_1 ||
    address.address_2 ||
    address.country ||
    address.region ||
    address.locality ||
    address.postal_code
  );
}

function addressKeyMap(key) {
  if (key === "home") {
    return "physical";
  } else {
    return key;
  }
}

function mapAddress(address) {
  return {
    line_1: address.address_1,
    line_2: address.address_2,
    country: address.country,
    region: address.region,
    city: address.locality,
    postal_code: address.postal_code,
  };
}
