import { sortColumns } from "./table/columns.helper";

// Formats visible_columns into export_columns for the export filter view api
export function getExportColumns({ exportAll, visibleColumns, columnOrder, customFields, defaultVisibleColumns }) {
  if (exportAll) return [];

  if (!visibleColumns?.length) {
    return [...defaultVisibleColumns, ...customFields.map((c) => "cf_" + c.field_name)];
  }

  const cols = !!columnOrder?.length ? sortColumns(visibleColumns, columnOrder) : visibleColumns;
  return cols.map((c) => {
    // We have to pass custom field names instead of ids
    if (c.indexOf("custom_field_") >= 0) {
      const customFieldId = c.replace("custom_field_", "");
      const customField = customFields.find((f) => f.field_id === customFieldId);
      return customField ? "cf_" + customField.field_name : null;
    }
    return c;
  });
}
