import { handleError } from "src/error";
import { clientQueries, contactQueries } from "src/queries";
import { useMutation } from "src/react-query";
import { removePortalAccess } from "src/resources/client-portal-access.resource";
import { successToast } from "toast-service!sofe";

export const useRemovePortalAccessMut = ({ contactName }: { contactName?: string }) => {
  return useMutation<unknown, Error, { contactId: string; clientIds: string[] }>({
    mutationFn: (args) => removePortalAccess(args),
    onSuccess: async () => {
      successToast(`Successfully removed access for ${contactName || "contact"}`);
      await clientQueries.invalidate();
      return contactQueries.invalidate();
    },
    onError: handleError,
  });
};
