import { useState, useEffect, useMemo } from "react";
import { contactQueries } from "src/queries";
import { useInfiniteQuery } from "src/react-query";
import { getContactsObs } from "src/resources/contacts.resource";

export default function useContactsListQuery({
  page,
  limit,
  infiniteScroll,
  filters,
  sortData,
  search,
  visibleFields,
  disabled,
}: {
  page: number;
  limit: string;
  infiniteScroll: boolean;
  filters: any;
  sortData: any;
  search: string;
  /** An array of field IDs that are visible */
  visibleFields: string[];
  disabled: boolean;
}) {
  const [pageCount, setPageCount] = useState();
  const [totalContactsCount, setTotalContactsCount] = useState(0);

  // Order is not important for visible columns, so we can sort and flatten as a string to use as the cache key
  const visibleFieldsKey = useMemo(() => [...visibleFields].sort().join(), [visibleFields]);

  const queryKey = [
    contactQueries.baseKey[0],
    page,
    limit,
    infiniteScroll,
    filters,
    sortData,
    search,
    visibleFieldsKey,
  ];
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam, signal }) =>
      new Promise<any>((resolve, reject) => {
        const pageToFetch = (infiniteScroll ? pageParam : page) || 1;
        const pageLimit = infiniteScroll ? "50" : limit;
        const sub = getContactsObs({
          page: pageToFetch,
          limit: pageLimit,
          sort_data: sortData,
          filters: filters,
          search,
          visibleFields: Array.from(new Set(["first_name", "last_name", "primary_email", "clients", ...visibleFields])),
        }).subscribe(resolve, reject);
        signal?.addEventListener("abort", () => sub.unsubscribe());
      }),
    initialPageParam: 1,
    enabled: !disabled && !!limit && !!filters,
    // Cache for 5 minutes (ms). Do not cache infinite queries to prevent refetching all pages
    gcTime: infiniteScroll ? 0 : 300000,
    // Stale time for 15 secs to prevent excessive re-fetching when quickly switching between tabs
    staleTime: 15000,
    getNextPageParam: (lastPage) => {
      if (!lastPage.pagination) return undefined;
      const pagination = lastPage.pagination;
      if (pagination.current_page < pagination.total_pages) {
        return pagination.current_page + 1;
      } else {
        return undefined;
      }
    },
  });

  useEffect(() => {
    if (query.data?.pages?.length) {
      const latestPage = query.data?.pages[query.data.pages.length - 1];
      if (latestPage.pagination) {
        setPageCount(latestPage.pagination.total_pages);
        setTotalContactsCount(latestPage.pagination.total_count);
      }
    }
  }, [query.data]);

  const contacts = useMemo(() => query.data?.pages?.flatMap((page) => page.contacts) || [], [query.data]);

  return {
    contactsQuery: query,
    contacts,
    pageCount,
    totalContactsCount,
  };
}
