import React, { ForwardedRef, forwardRef } from "react";
import { Controller } from "react-hook-form";
import FieldSection from "src/common/field-section.component";
import { TagsSelect } from "src/common/form/client/base";

interface TagsSectionProps {
  control: any;
}

export const TagsSection = forwardRef(({ control }: TagsSectionProps, ref: ForwardedRef<any>) => {
  return (
    <FieldSection label="Tags" ref={ref}>
      <Controller
        name="tags"
        control={control}
        render={({ field: { value, onChange } }) => <TagsSelect value={value || []} onChange={onChange} />}
      />
    </FieldSection>
  );
});
