// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-templates-select-template-button-template-list-styles__container .src-templates-select-template-button-template-list-styles__section:first-child {
  border-bottom: 0.1rem solid var(--cp-color-nav-active-border);
}

.src-templates-select-template-button-template-list-styles__section {
  max-height: 50vh;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/templates/select-template-button/template-list.styles.css"],"names":[],"mappings":"AAAA;EACE,6DAA6D;AAC/D;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".container .section:first-child {\n  border-bottom: 0.1rem solid var(--cp-color-nav-active-border);\n}\n\n.section {\n  max-height: 50vh;\n  overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-templates-select-template-button-template-list-styles__container`,
	"section": `src-templates-select-template-button-template-list-styles__section`
};
export default ___CSS_LOADER_EXPORT___;
