import { CpTable } from "canopy-styleguide!sofe";
import { isBoolean, isArray } from "lodash";

// Contains methods for converting form filter data from CpTable.useFilters to query data to send to BE and vice versa.

// Converts CpTable.InputFilter data to query data to send to BE
function inputFilterDataToQuery(filterData) {
  const combinedFilterObject = {};

  filterData.forEach((condition) => {
    const conditionType = condition.condition; // e.g., 'contains', 'starts_with'
    let filterValue = isArray(condition.filterValue) ? condition.filterValue : [condition.filterValue];
    if (conditionType === "include_empty") {
      filterValue = true;
    }
    const operator = condition.operator || undefined;

    !combinedFilterObject[conditionType]
      ? (combinedFilterObject[conditionType] = filterValue)
      : combinedFilterObject[conditionType].push(...filterValue);
    if (operator) {
      combinedFilterObject.operator = operator;
    }
  });

  return [combinedFilterObject];
}

// Converts CpTable.ConditionalSelectFilter data to query data to send to BE
function conditionalSelectFilterDataToQuery(filterData, fieldId) {
  function conditionToData({ condition: conditionName, filterValue }) {
    let values;
    if (fieldId.includes("roles_")) {
      // We need to pass the entire array of objects to the BE for roles
      values = filterValue;
    } else {
      values = filterValue?.map((v) => v?.id || v);
    }
    switch (conditionName) {
      case "is_blank":
        return { include_empty: true };
      case "is_any_of":
        return { values };
      case "has_any_value":
        return { select_all: true };
      case "is_not_any_of":
        return {
          select_all: true,
          values,
        };
      case "is_all_of":
        return {
          explicit_match: true,
          values,
        };
      default:
        return {};
    }
  }
  const firstCondition = filterData[0];
  const secondCondition = filterData[1];

  const queryFilter = [conditionToData(firstCondition)];
  if (secondCondition) {
    queryFilter.push(conditionToData(secondCondition));
  }
  return queryFilter;
}

// Converts CpTable.SelectFilter data to query data to send to BE
function selectFilterDataToQuery(filterData) {
  return [
    {
      values: filterData,
    },
  ];
}

// Converts CpTable.DateFilter data to query data to send to BE
function dateFilterDataToQuery(filterData) {
  return [
    filterData[0].include_empty
      ? { include_empty: true }
      : {
          start_date: filterData[0].start_date,
          end_date: filterData[0].end_date,
        },
  ];
}

// Converts CpTable.BoolFilter data to query data to send to BE
function boolFilterDataToQuery(filterData) {
  return [
    {
      equal_to: filterData[0] === "true",
    },
  ];
}

// Converts filter data from CpTable.useFilters to query data to send to BE
export function formFilterDataToQuery({ filterData, filterFieldId, filterComponent }) {
  if (!filterData) {
    return null;
  }
  switch (filterComponent) {
    case CpTable.ConditionalSelectFilter:
      return conditionalSelectFilterDataToQuery(filterData, filterFieldId);
    case CpTable.SelectFilter:
      return selectFilterDataToQuery(filterData);
    case CpTable.DateFilter:
      return dateFilterDataToQuery(filterData);
    case CpTable.BoolFilter:
      return boolFilterDataToQuery(filterData);
    default:
      return inputFilterDataToQuery(filterData);
  }
}

// Conditions BE supports for input filters
const inputConditions = [
  "contains",
  "include_empty",
  "starts_with",
  "equal_to",
  "greater_than",
  "less_than",
  "start_date",
  "end_date",
];

// Condition is not passed to BE for search+select, so we must infer based on the properties present.
const getSelectCondition = (data) => {
  if (!!data.values) {
    if (data.select_all) {
      return "is_not_any_of";
    } else if (data.explicit_match) {
      return "is_all_of";
    } else {
      return "is_any_of";
    }
  } else {
    if (data.include_empty) {
      return "is_blank";
    } else {
      return "has_any_value";
    }
  }
};

// Converts BE data to the `data` attribute to be used in CpTable filters.
function getFormDataByFilterType(filterType, dataArray) {
  switch (filterType) {
    case "bool":
      return [dataArray[0].equal_to === true ? "true" : "false"];
    case "input":
      const out = [];
      dataArray.forEach((data) => {
        const conditions = Object.keys(data).filter((key) => inputConditions.includes(key));
        conditions.forEach((condition) => {
          const filterValue = data[condition];
          if (isArray(filterValue)) {
            // Support multiple values in a single condition e.g. contains: ['a', 'b']
            filterValue.forEach((value) => {
              out.push({
                filterValue: value,
                condition: condition,
                operator: data.operator,
              });
            });
          } else {
            out.push({
              filterValue: isBoolean(filterValue) ? "" : filterValue,
              condition: condition,
              operator: data.operator,
            });
          }
        });
      });
      return out;
    case "date":
      return [
        {
          start_date: dataArray[0].start_date,
          end_date: dataArray[0].end_date,
        },
      ];
    case "conditional_select":
      return dataArray.map((data) => {
        return {
          condition: getSelectCondition(data),
          filterValue: data.values,
          operator: "and",
        };
      });
    case "select":
      return dataArray[0].values;
    default:
      return [];
  }
}

function getFilterInfoByFieldId(schema) {
  const nameByComponent = {
    [CpTable.InputFilter.name]: "input",
    [CpTable.ConditionalSelectFilter.name]: "conditional_select",
    [CpTable.SelectFilter.name]: "select",
    [CpTable.DateFilter.name]: "date",
    [CpTable.BoolFilter.name]: "bool",
  };
  return Object.values(schema).reduce((acc, columnSchema) => {
    if (columnSchema.filter) {
      acc[columnSchema.filter.fieldId] = {
        filterType: nameByComponent[columnSchema.filter.component.name],
        columnId: columnSchema.id,
      };
    }
    return acc;
  }, {});
}

// Converts filterView.filter_data to form data that CpTable can use.
export function filterViewToFormFilters(filterViewFilterData, schema) {
  const filters = filterViewFilterData?.filters;
  if (!filters) return {};

  const infoByFieldId = getFilterInfoByFieldId(schema);
  const out = {};
  for (const [fieldId, dataArray] of Object.entries(filters)) {
    const fieldInfo = infoByFieldId[fieldId];
    const filterType = fieldInfo?.filterType;
    if (!filterType) {
      continue;
    }
    out[fieldInfo.columnId] = {
      fieldId: fieldId,
      data: getFormDataByFilterType(filterType, dataArray),
    };
  }
  return out;
}

export function filterViewToFormSortData(filterViewFilterData, schema) {
  const columnIdByField = Object.values(schema).reduce((acc, columnSchema) => {
    if (columnSchema.sort) {
      acc[columnSchema.sort.fieldId] = columnSchema.id;
    }
    return acc;
  }, {});

  return (
    filterViewFilterData?.sort_data?.reduce((acc, sortVal) => {
      const columnId = columnIdByField[sortVal.field];
      if (!columnId) return acc;
      acc[columnId] = {
        direction: sortVal.sort,
        fieldId: sortVal.field,
      };
      return acc;
    }, {}) || {}
  );
}
