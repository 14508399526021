import React, { useMemo, useState, useContext } from "react";
import { isEmpty, cloneDeep } from "lodash";
import { createFilterView, deleteFilterViews, patchFilterView, patchFilterViewOrder } from "./client-list.resource";
import { CustomizeFilterViewsModal } from "src/common/customize-filter-views-modal/customize-filter-views-modal.component";
import useCustomFieldsQuery from "src/common/queries/use-custom-fields-query.hook";
import useTeamMembersQuery from "src/common/queries/use-team-members-query.hook";
import { getDefaultVisibleColumns } from "src/client-list/table/columns.helper";
import RenameFilterView from "src/common/customize-filter-views-modal/edit-filter-views/rename-filter-view.component";
import ShareFilterView from "src/common/customize-filter-views-modal/edit-filter-views/share-filter-view.component";
import { featureEnabled } from "feature-toggles!sofe";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { handleError } from "src/error";
import { FilterContext } from "./client-list-filtering";
import { clientFilterViewQueries, useRolesQuery } from "src/queries";

function formatFilterView(filterView, { defaultVisibleCols, filterViewOverrides, teamMembers }) {
  if (!filterView) return null;
  return {
    id: filterView.id,
    name: filterView.name,
    sharingData: filterView.sharing_data,
    readOnly: filterView.read_only,
    isDefault: filterView.created_by === "__DEFAULT",
    createdBy: filterView.read_only ? teamMembers.find((tm) => tm.id === filterView.created_by) : null,
    isHidden: filterView.hidden,
    visibleColumns:
      filterViewOverrides[filterView.id]?.visible_columns ||
      filterView.filter_data.visible_columns ||
      defaultVisibleCols,
    filterData: filterView.filter_data,
  };
}

export default function ColumnCustomizationModal({
  schema,
  filterViews,
  initialFilterView,
  filterViewOverrides,
  onColumnVisibilityChange,
  onAfterClose,
}) {
  const [showModal, setShowModal] = useState(true);
  const { customFields } = useCustomFieldsQuery();
  const { roles } = useRolesQuery();
  const { teamMembers } = useTeamMembersQuery(false);
  const { getFilter, getSort } = useContext(FilterContext);
  const [, tenant] = useWithUserAndTenant();
  const ft_crm = featureEnabled("ft_crm") && tenant?.crm_status === "crm_hierarchy_complete";

  const formattedInitialFilterView = useMemo(() => {
    const defaultVisibleCols = getDefaultVisibleColumns(
      initialFilterView.id,
      customFields?.map((cf) => cf.field_id),
      ft_crm
    );
    return formatFilterView(initialFilterView, { defaultVisibleCols, filterViewOverrides, teamMembers });
  }, [initialFilterView, filterViewOverrides, teamMembers, customFields, ft_crm]);

  const formattedFilterViews = useMemo(() => {
    return filterViews.map((fv) => {
      const defaultVisibleCols = getDefaultVisibleColumns(
        fv.id,
        customFields?.map((cf) => cf.field_id),
        ft_crm
      );
      return formatFilterView(fv, { defaultVisibleCols, filterViewOverrides, teamMembers });
    });
  }, [filterViews, filterViewOverrides, teamMembers, customFields, ft_crm]);

  const formattedColumns = useMemo(() => {
    const cols = [];
    const columnDefs = { ...schema };
    if (ft_crm) {
      delete columnDefs.name;
    } else {
      delete columnDefs.full_name;
    }
    delete columnDefs.select;
    for (const column of Object.values(columnDefs)) {
      cols.push({
        id: column.id,
        name: column.label,
        section: column.section,
        sortField: column.sort?.fieldId,
        filterField: column.filter?.fieldId,
      });
    }
    return cols;
  }, [schema, ft_crm]);

  const sections = useMemo(() => {
    return [
      { id: "client", name: "Client attributes" },
      { id: "firm", name: "Firm attributes" },
      { id: "business", name: "Business attributes" },
      ...(!!roles?.length ? [{ id: "roles", name: "Roles" }] : []),
      ...(!!customFields?.length ? [{ id: "custom", name: "Custom fields" }] : []),
    ];
  }, [roles, customFields]);

  function onFilterViewOrderChange(orderedViewIds) {
    patchFilterViewOrder(orderedViewIds).subscribe(() => {
      clientFilterViewQueries.invalidate();
    }, handleError);
  }

  function onFilterViewDuplicate(formattedFilterView) {
    const body = cloneDeep(filterViews.find((fv) => fv.id === formattedFilterView.id));
    delete body.id;
    body.name = body.name + " (copy)";
    body.sharing_data = [];

    createFilterView(body).subscribe(() => {
      clientFilterViewQueries.invalidate();
    }, handleError);
  }

  function onFilterViewVisibilityChange({ filterView, visible }) {
    patchFilterView(filterView.id, {
      hidden: !visible,
    }).subscribe(() => {
      clientFilterViewQueries.invalidate();
    }, handleError);
  }

  function onFilterViewRename(filterView, name) {
    patchFilterView(filterView.id, { name }).subscribe(() => {
      clientFilterViewQueries.invalidate();
    }, handleError);
  }

  function onFilterViewShare(filterView, sharingData) {
    patchFilterView(filterView.id, { sharing_data: sharingData }).subscribe(() => {
      clientFilterViewQueries.invalidate();
    }, handleError);
  }

  function onClose({ filterViewIdsMarkedForDelete }) {
    if (filterViewIdsMarkedForDelete.length > 0) {
      deleteFilterViews(filterViewIdsMarkedForDelete).subscribe(() => {
        clientFilterViewQueries.invalidate();
      }, handleError);
    }
    setShowModal(false);
  }

  return (
    <CustomizeFilterViewsModal
      show={showModal}
      onClose={onClose}
      onAfterClose={onAfterClose}
      title="Customize Client List"
      initialFilterView={formattedInitialFilterView}
      filterViews={formattedFilterViews}
      columns={formattedColumns}
      sections={sections}
      debounceOnColumnVisibilityChange={onColumnVisibilityChange}
      onFilterViewOrderChange={onFilterViewOrderChange}
      onFilterViewDuplicate={onFilterViewDuplicate}
      onFilterViewVisibilityChange={onFilterViewVisibilityChange}
      getIsFilterApplied={(filterView, column) => {
        return !isEmpty(getFilter(column.filterField)?.[0]) || !!getSort(column.sortField)?.sort;
      }}
      renderRenameDropdown={({ filterView, closeSubView }) => (
        <RenameFilterView
          filterView={filterView}
          onClose={closeSubView}
          onRename={(name) => onFilterViewRename(filterView, name)}
        />
      )}
      renderShareListDropdown={({ filterView, closeSubView }) => (
        <ShareFilterView
          filterView={filterView}
          onClose={closeSubView}
          onShare={(sharingData) => onFilterViewShare(filterView, sharingData)}
        />
      )}
    />
  );
}
