import React from "react";
// project
import Star from "../../star.component.js";
// sofe
import { CpButton, CpTooltip } from "canopy-styleguide!sofe";

export default function Actions(props) {
  return (
    <>
      <Star isPrimary={props.isPrimary} setPrimary={props.setPrimary} html={props.starHtml} />
      <CpTooltip text={props.trashHtml}>
        <CpButton onClick={props.remove} icon="crud-trash-large" aria-label={props.trashHtml} />
      </CpTooltip>
    </>
  );
}
