/* eslint-disable */
import React from "react";

export type Reference = {
  getName: () => string;
  getValue: () => any;
  isInvalid?: () => boolean;
  focus?: () => any;
};

// what I want to use but can't figure out how to fix class/flow issues where it's treating the class as function and not an instance
// export type refHandler = (refs: ?Reference | Array<?Reference>) => void
export type refHandler = (args: any) => void; //TODO fix this here's where I was trying to fix it: https://flow.org/en/docs/types/classes/

export type FormContextTypes = {
  registerRef: refHandler;
  deregisterRef: refHandler;
  showInvalid: boolean;
  updateSharedValue: Function;
  sharedStateObs: {
    subscribe: Function;
  };
  initialValue?: any;
  sharedValues: Array<string>;
};

const defaultRef = {
  getName: () => "",
  getValue: () => undefined,
};

const context = {
  registerRef: () => {},
  deregisterRef: () => {},
  showInvalid: false,
  updateSharedValue: () => {},
  sharedStateObs: {
    subscribe: () => {},
  },
  initialValue: undefined,
  sharedValues: [],
};

const FormContext = React.createContext<FormContextTypes>(context);

export default FormContext;
/* eslint-enable */
