import React from "react";

type DisplayNameCellProps = {
  resource: {
    id: string;
    name: string;
  };
};

export default function DisplayNameCell({ resource }: DisplayNameCellProps) {
  return (
    <a href={`#/client/${resource.id}`} onContextMenu={(e) => e.stopPropagation()}>
      {resource.name || "—"}
    </a>
  );
}

DisplayNameCell.overrideEmptyCell = true;
