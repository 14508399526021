import React, { useState } from "react";
import { CpButton, CpCard, CpInput } from "canopy-styleguide!sofe";

type RenameFilterViewProps = {
  filterView: any;
  onClose: () => void;
  onRename: (name: string) => void;
};

export default function RenameFilterView(props: RenameFilterViewProps) {
  const { filterView, onClose, onRename } = props;
  const [value, setValue] = useState(filterView.name);

  function cancel() {
    onClose();
  }

  function renameFilterView() {
    onRename(value);
    onClose();
  }

  return (
    <CpCard>
      <CpCard.Body>
        <CpInput
          label="Rename"
          value={value}
          onChange={setValue}
          onKeyDown={(e: any) => e.code === "Enter" && renameFilterView()}
        />
      </CpCard.Body>
      <CpCard.Footer className="cp-flex">
        <CpButton btnType="primary" className="cp-mr-8" disabled={value === filterView.name} onClick={renameFilterView}>
          Done
        </CpButton>
        <CpButton btnType="flat" onClick={cancel}>
          Cancel
        </CpButton>
      </CpCard.Footer>
    </CpCard>
  );
}
