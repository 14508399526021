import React, { useState } from "react";
import { CpButton } from "canopy-styleguide!sofe";
import CreateEditClientModal from "src/create-edit-client-modal";
import OldCreateEditClientModal from "src/create-edit-client-old/create-edit-client-modal/create-edit-client-modal.component";
import { featureEnabled } from "feature-toggles!sofe";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { clientQueries } from "src/queries";

export default function AddClientButton({ className = "" }) {
  const [showModal, setShowModal] = useState(false);
  const [user, tenant] = useWithUserAndTenant();
  return (
    <>
      <CpButton className={className} onClick={() => setShowModal(true)}>
        Add client
      </CpButton>
      {showModal &&
        (featureEnabled("ft_crm") && tenant.crm_status === "crm_hierarchy_complete" ? (
          <CreateEditClientModal
            onModalHide={() => {
              setShowModal(false);
            }}
          />
        ) : (
          <OldCreateEditClientModal
            onModalHide={() => {
              clientQueries.invalidate();
              setShowModal(false);
            }}
          />
        ))}
    </>
  );
}
