import React from "react";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { m } from "kremling";
import { CpArea } from "canopy-styleguide!sofe";
import type { NavSectionType } from "./types";

interface NavSectionProps {
  activeSectionId: string;
  sections: NavSectionType[];
  onSectionClick: (section: NavSectionType) => void;
}

export function NavSection({ activeSectionId, sections, onSectionClick }: NavSectionProps) {
  useCssModules(css);
  return (
    <div className={s.container}>
      {sections.map((section) => (
        <CpArea
          key={section.id}
          className={m(s.activeSectionBtn, activeSectionId === section.id).a(s.sectionBtn)}
          onClick={() => onSectionClick(section)}
        >
          {section.name}
        </CpArea>
      ))}
    </div>
  );
}

const { s, css } = cssModules`
  .container {
    padding: 1.2rem;
    border: 0.1rem solid var(--cp-color-app-border);
    border-radius: 0.8rem;
    min-width: 21.6rem;
  }

  .sectionBtn {
    padding: 1rem;
  }

  .activeSectionBtn {
    color: var(--cp-color-app-primary);
  }

  .activeSectionBtn::after {
    content: ' ';
    position: absolute;
    width: 0.2rem;
    height: 4rem;
    background: var(--cp-color-app-primary);
    left: -1.2rem;
    top: 0;
  }
`;
