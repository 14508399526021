import React from "react";
import { CpWell } from "canopy-styleguide!sofe";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { a } from "kremling";

type ViewContactWellProps = {
  children: React.ReactNode;
  className?: string;
  title: string;
  level?: number;
};

export function ViewContactWell({ children, title, level = 2 }: ViewContactWellProps) {
  useCssModules(css);
  return (
    <CpWell className="cp-p-16" level={level}>
      <div className={a(s.title, "cp-pb-16 cp-body-sm cp-wt-semibold")}>{title}</div>
      <div className="cp-pt-16">{children}</div>
    </CpWell>
  );
}

const { s, css } = cssModules`
  .title {
    border-bottom: 1px solid var(--cp-color-app-border);
  }
`;
