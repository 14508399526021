import React, { useMemo } from "react";
import { debounce } from "lodash";
import { CpInputField } from "canopy-styleguide!sofe";
import { isDisplayNameAvailable } from "src/resources/clients.resource";
import { handleError } from "src/error";
import { Control } from "react-hook-form";

type ClientNameFieldProps = {
  control: Control<any>;
  fieldName: string;
  initialName?: string;
  onBlur?: () => void;
};

export function ClientNameField({ control, initialName, fieldName, onBlur }: ClientNameFieldProps) {
  const isUniqueDisplayName = useMemo(
    () =>
      debounce((value, callback) => {
        isDisplayNameAvailable(value).subscribe((res: any) => {
          callback(res.available ? true : "This client name is already being used for another client.");
        }, handleError);
      }, 500),
    []
  );

  return (
    <CpInputField
      control={control}
      fieldName={fieldName}
      required
      label="Client name"
      maxLength={150}
      onBlur={onBlur}
      /** Adding this data attr to try and track down a rare bug where isUniqueDisplayName is called when saving an existing client without changing the name */
      data-initial-name={new String(initialName)}
      rules={{
        validate: {
          unique: (value: string) =>
            value.trim().toLowerCase() === initialName?.trim()?.toLowerCase()
              ? true
              : new Promise((resolve) => {
                  isUniqueDisplayName(value.trim(), resolve);
                }),
        },
      }}
    />
  );
}
