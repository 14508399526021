import React, { useMemo, useState } from "react";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { FilterViewCheckboxes } from "./filter-view-checkboxes.component";

type CustomizeColumnsBodyProps = {
  initialFilterView: any;
  filterViews: any[];
  columns: any[];
  sections: any[];
  debounceOnColumnVisibilityChange: any;
  getIsFilterApplied: any;
};

export function CustomizeColumnsBody({
  initialFilterView,
  filterViews,
  columns,
  sections,
  debounceOnColumnVisibilityChange,
  getIsFilterApplied,
}: CustomizeColumnsBodyProps) {
  const [selectedFilterViewId, setSelectedFilterViewId] = useState(initialFilterView.id);
  const [search, setSearch] = useState("");

  const selectedFilterView = useMemo(() => {
    return filterViews.find((filterView) => filterView.id === selectedFilterViewId);
  }, [filterViews, selectedFilterViewId]);

  const numTeamMembersShared = selectedFilterView?.sharingData?.filter((val: any) => val.type === "user")?.length || 0;
  const numTeamsShared = selectedFilterView?.sharingData?.filter((val: any) => val.type === "team")?.length || 0;

  return (
    <>
      <div className="cp-mb-24">
        <div className="cp-body-sm">Choose which columns to show on</div>
        <CpSelectSingle
          className="cp-mb-8"
          data={filterViews}
          placeholder="Select a filter view"
          onChange={(filterView: any) => setSelectedFilterViewId(filterView.id)}
          value={selectedFilterView}
          searchFilter
          searchOnChange={setSearch}
          searchValue={search}
          triggerIsBlock
          transformData={(view: any) => ({
            ...view,
            icon: !!view.sharingData?.length ? "person-people" : null,
          })}
        />
        {!!selectedFilterView.sharingData?.length && (
          <div className="cp-body-sm">
            {selectedFilterView.readOnly ? (
              <i>This filter view has been shared with you. Only the owner of the filter view can make changes.</i>
            ) : (
              <i>
                You are sharing this filter with
                {!!numTeamMembersShared
                  ? ` ${numTeamMembersShared} team member${numTeamMembersShared > 1 ? "s" : ""}`
                  : ""}
                {!!numTeamMembersShared && !!numTeamsShared ? " and " : ""}
                {!!numTeamsShared ? ` ${numTeamsShared} team${numTeamsShared > 1 ? "s" : ""}` : ""}. Any changes made
                will be updated for those team members on their next login.
              </i>
            )}
          </div>
        )}
        {selectedFilterView.isDefault ? (
          <div className="cp-body-sm">
            <i>
              Changes made to a default filter view will not be saved if the page is refreshed. To save any column
              changes or filters you will need to create a new filter view.
            </i>
          </div>
        ) : (
          !selectedFilterView.readOnly && (
            <div className="cp-body-sm cp-mt-8">
              <i>Changes made to columns will be updated automatically.</i>
            </div>
          )
        )}
      </div>
      <FilterViewCheckboxes
        key={selectedFilterViewId}
        sections={sections}
        columns={columns}
        selectedFilterView={selectedFilterView}
        debounceOnColumnVisibilityChange={debounceOnColumnVisibilityChange}
        getIsFilterApplied={getIsFilterApplied}
      />
    </>
  );
}
