import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CpButton,
  CpTooltip,
  CpInput,
  CpPages,
  CpTable,
  CpTabsDynamic,
  CpEmptyState,
  CpLoader,
} from "canopy-styleguide!sofe";
import { throttle, debounce, capitalize, invert } from "lodash";
import { a } from "kremling";

import styles from "./client-list.styles.css";
import AddClientButton from "./add-client-button.component";
import ColumnCustomizationModal from "./column-customization-modal.component";
import KabobMenu from "./kabob-menu.component";
import BulkActionList from "./bulk-actions/bulk-action-list.component";
import SaveFilterDropdown from "./save-filter-dropdown.component";
import ExportClientListButton from "./export-client-list-button.component";
import SubViewActionButton from "./subview-action-button.component";
import { getDefaultVisibleColumns } from "./table/columns.helper";
import { track } from "src/resources/analytics.resource";

import useClientListQuery from "./hooks/use-client-list-query.hook";
import useFilterViewsQuery from "./hooks/use-filter-views-query.hook";
import useCustomFieldsQuery from "../common/queries/use-custom-fields-query.hook";
import { patchFilterView } from "src/client-list/client-list.resource";
import { genClientListSchema } from "src/client-list/table/client-list-table-schema";
import {
  filterViewToFormSortData,
  filterViewToFormFilters,
  formFilterDataToQuery,
} from "src/common/client-contact/filters/filters.helper";
import ContextMenu from "src/client-list/table/context-menu.component";
import { useDebouncedSearch } from "src/common/use-debounced-search.hook";
import { useHasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { FilterContext, getNewFiltersCount, useFilterContextValues } from "./client-list-filtering";
import { useSubView } from "./hooks/use-subview.hook";
import { handleError } from "src/error";
import { useDedupeModal } from "./hooks/use-dedupe-modal.hook";
import { clientFilterViewQueries, useRolesQuery } from "src/queries";

const defaultFilterViewId = "__ACTIVE_CLIENTS";
const routeByFilterViewId = {
  __ACTIVE_CLIENTS: "active",
  __INDIVIDUALS: "individual",
  __BUSINESSES: "business",
  __PROSPECTS: "prospect",
};
const filterViewIdByRoute = invert(routeByFilterViewId);
const getRouteByFilterViewId = (filterViewId) => routeByFilterViewId[filterViewId] || filterViewId;
const getFilterViewIdByRoute = (route) => filterViewIdByRoute[route] || route;
const navTo = (route) => window.location.assign("#/clients/list/" + route);

export default function ClientList() {
  const { id: routeId } = useParams();
  const [pageLimit, setPageLimit] = useState(() => {
    const limitId = localStorage.getItem("clients-ui:client-list-page-limit");
    return capitalize(limitId) || "50";
  });
  const infiniteScroll = pageLimit === "All";
  const [page, setPage] = useState(1);
  const [filters, setFiltersState] = useState();
  const setFilters = useCallback((value) => {
    setFiltersState(value);
    setPage(1);
  }, []);
  const [sortData, setSortData] = useState([]);
  const [moveToFilterView, setMoveToFilterView] = useState(null);
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});
  const [user, tenant] = useWithUserAndTenant();
  const canCreateEditClients = useHasAccess("clients_create_edit");

  const { search, debouncedSearch, updateSearch } = useDebouncedSearch({
    onDebouncedUpdate: () => setPage(1),
  });

  useEffect(() => {
    const trimmedSearch = debouncedSearch.trim();
    if (trimmedSearch) {
      track("client_list.search-executed", { search: trimmedSearch });
    }
  }, [debouncedSearch]);

  const location = useLocation();
  // Overlay the dedupe modal when import ids are passed
  useDedupeModal(location?.search);

  const filterContextValues = useFilterContextValues({ filters, sortData, setFilters, setSortData });
  const { filterViewsQuery, filterViews } = useFilterViewsQuery();
  const visibleFilterViews = useMemo(() => filterViews.filter((fv) => !fv.hidden), [filterViews]);
  const [filterViewOverrides, setFilterViewOverrides] = useState({});
  const [activeFilterViewId, setActiveFilterViewId] = useState(null);
  const [filterView, setFilterView] = useState(null);

  const { customFields } = useCustomFieldsQuery();
  const { roles } = useRolesQuery();
  const ft_crm = featureEnabled("ft_crm") && tenant?.crm_status === "crm_hierarchy_complete";

  const newFiltersCount = useMemo(
    () => getNewFiltersCount({ filters, sortData, filterView, filterViewOverrides }),
    [filters, sortData, filterView, filterViewOverrides]
  );

  const defaultVisibleColumns = useMemo(
    () =>
      getDefaultVisibleColumns(
        filterView?.id,
        customFields?.map((f) => f.field_id),
        ft_crm
      ),
    [customFields, filterView, ft_crm]
  );

  const visibleColumns = useMemo(() => {
    const cols =
      filterViewOverrides[filterView?.id]?.visible_columns ||
      filterView?.filter_data?.visible_columns ||
      defaultVisibleColumns;
    if (cols.some((c) => c.includes("roles_"))) {
      return [...cols, "roles"];
    } else {
      return cols;
    }
  }, [filterViewOverrides, filterView, defaultVisibleColumns]);

  const { clients, clientsQuery, pageCount, totalClientsCount } = useClientListQuery({
    page,
    limit: pageLimit,
    infiniteScroll,
    filters: filters,
    sortData: sortData,
    search: debouncedSearch,
    visibleColumns,
    disabled: !customFields,
    ft_crm,
  });

  const schema = CpTable.useSchema(
    () =>
      genClientListSchema({
        customFields,
        roles,
        crmHierarchyEnabled: ft_crm,
      }),
    [customFields, roles, ft_crm]
  );

  const filterControl = CpTable.useFilters({
    schema,
    onFilterApply: ({ sortDir, filterData, filterFieldId, sortFieldId, columnSchema }) => {
      filterContextValues.setFilter(
        filterFieldId,
        formFilterDataToQuery({ filterComponent: columnSchema.filter.component, filterFieldId, filterData }),
        columnSchema
      );
      filterContextValues.setSort({
        field: sortFieldId,
        sort: sortDir,
      });
    },
  });

  // Destructure needed for linting https://github.com/facebook/react/issues/16265
  const { setFilters: fcSetFilters, setSortData: fcSetSortData } = filterControl;
  const setControlFilters = useCallback(
    (filterData) => {
      fcSetFilters(filterViewToFormFilters(filterData, schema));
      fcSetSortData(filterViewToFormSortData(filterData, schema));
    },
    [fcSetFilters, fcSetSortData, schema]
  );

  const selection = CpTable.useSelection({ totalSize: totalClientsCount });
  const fetchNextPage = useMemo(() => debounce(() => clientsQuery.fetchNextPage(), 100), [clientsQuery]);
  const onPageChange = useMemo(() => throttle((page) => setPage(page), 500), []);

  const showingInactiveClients = useMemo(() => {
    if (!filterView) return false;
    if (!filters?.is_active) {
      return true;
    }
    return filters.is_active.some((f) => f.equal_to === false);
  }, [filterView, filters]);

  const columnOrder = useMemo(
    () => filterViewOverrides[filterView?.id]?.column_order || filterView?.filter_data?.column_order || [],
    [filterViewOverrides, filterView]
  );

  const subView = useSubView({
    routeId,
    onSubViewEnter: () => {
      selection.deselectAll();
      setFilters(subView.filter_data.filters);
      setSortData(subView.filter_data.sort_data);
      setControlFilters(subView.filter_data);
    },
    onSubViewExit: () => {
      // When coming back from a subView revert back to the filterView provided in the url
      selection.deselectAll();
      const filterViewMatch = visibleFilterViews.find((f) => f.id === getFilterViewIdByRoute(routeId));
      setFilters(filterViewMatch.filter_data.filters);
      setSortData(filterViewMatch.filter_data.sort_data);
      setControlFilters(filterViewMatch.filter_data);
    },
  });

  useEffect(() => {
    const newFilterViewId = getFilterViewIdByRoute(routeId) || defaultFilterViewId;
    setActiveFilterViewId(newFilterViewId);
    setPage(1);
  }, [routeId]);

  const prevFilterViewId = useRef();
  useEffect(() => {
    if (activeFilterViewId && prevFilterViewId.current !== activeFilterViewId) {
      const newFilterView = visibleFilterViews.find((f) => f.id === activeFilterViewId);
      if (newFilterView) {
        setFilters(newFilterView.filter_data?.filters || {});
        setSortData(newFilterView.filter_data?.sort_data || []);
        prevFilterViewId.current = newFilterView.id;
      }
    }
  }, [activeFilterViewId, visibleFilterViews, setFilters]);

  useEffect(() => {
    if (!filterView) return;
    if (newFiltersCount <= 0) {
      setControlFilters(filterView.filter_data);
    }
  }, [filterView, setControlFilters, newFiltersCount]);

  useEffect(() => {
    if (!filterViews?.length || !activeFilterViewId) return;
    const filterViewMatch = filterViews.find((fv) => {
      if (["inactive", "archived"].includes(routeId)) {
        return fv.id === defaultFilterViewId;
      } else {
        return fv.id === activeFilterViewId;
      }
    });
    if (filterViewMatch) {
      setFilterView(filterViewMatch);
    } else {
      navTo("active");
    }
  }, [filterViews, activeFilterViewId, routeId]);

  useEffect(() => {
    // When a new view is created we want to switch to it, so we need to wait until filterViews has been refetched
    if (moveToFilterView) {
      const view = visibleFilterViews.find((f) => f.id === moveToFilterView);
      if (view) {
        navTo(getRouteByFilterViewId(view.id));
        setMoveToFilterView(null);
      }
    }
  }, [moveToFilterView, visibleFilterViews]);

  // There are too many issues that arise when keeping selections across filter changes, so we need to reset them when filters change.
  // For example exporting selected clients will not work without some BE intervention because one of the selected clients may not exist in the filter view.
  const deselectAll = selection.deselectAll; // Doing this for dependency linting
  useEffect(() => {
    deselectAll();
  }, [filters, deselectAll]);

  function onLimitChange(val) {
    setPage(1);
    setPageLimit(val);
    track("client_list.pagination-changed", { pageLimit: val });
    localStorage.setItem("clients-ui:client-list-page-limit", val);
  }

  function resetFilters() {
    selection.deselectAll();
    setFilters(filterView.filter_data.filters);
    setSortData(filterView.filter_data.sort_data);
    setControlFilters(filterView.filter_data);
    if (!!filterViewOverrides[filterView.id]) {
      setFilterViewOverrides((prev) => {
        const updated = { ...prev };
        delete updated[filterView.id];
        return updated;
      });
    }
  }

  function overrideFilterView(filterViewId, overrideData) {
    setFilterViewOverrides((val) => ({
      ...val,
      [filterViewId]: {
        ...(val[filterViewId] || {}),
        ...overrideData,
      },
    }));
  }

  function onColumnOrderChange(columnOrder) {
    if (!!filterView.read_only || filterView.created_by === "__DEFAULT") {
      overrideFilterView(filterView.id, {
        column_order: columnOrder,
      });
    } else {
      patchFilterView(filterView.id, {
        filter_data: {
          ...filterView.filter_data,
          column_order: columnOrder,
        },
      }).subscribe(() => {
        clientFilterViewQueries.invalidate();
      }, handleError);
    }
  }

  function onColumnVisibilityChange(formattedFilterView, visibleColumns) {
    const defaultCol = ft_crm ? "name" : "full_name";
    const allVisibleColumns = [defaultCol, ...visibleColumns];
    if (!!formattedFilterView.readOnly || formattedFilterView.isDefault) {
      overrideFilterView(formattedFilterView.id, {
        visible_columns: allVisibleColumns,
      });
    } else {
      const filter_data = {
        ...formattedFilterView.filterData,
        visible_columns: allVisibleColumns,
      };
      patchFilterView(formattedFilterView.id, { filter_data }).subscribe(() => {
        clientFilterViewQueries.invalidate();
      }, handleError);
    }
  }

  function getAvailableBulkActions() {
    if (subView?.id === "archived") {
      return ["email", "tags"];
    } else if (subView?.id === "inactive" || showingInactiveClients) {
      return ["email", "tags", "assign_client_owner", "assign_team_members", "archive"];
    } else {
      return null;
    }
  }

  const subviewContextActions = useMemo(() => {
    if (!subView) return null;
    if (subView?.id === "archived") {
      return ["edit", "delete", "unarchive"];
    } else return ["edit"];
  }, [subView]);

  function getEmptyState() {
    if (clientsQuery.isLoading || !clientsQuery.isFetched) {
      return () => <CpLoader size="lg" />;
    } else if (debouncedSearch.length > 0 && clients.length <= 0) {
      return () => (
        <CpEmptyState img="es_clients_search" text="No search results" subText="Please refine your search criteria." />
      );
    } else if (clients.length <= 0) {
      return () => <CpEmptyState img="es_filter" text="No results" subText="Please refine your filters." />;
    } else {
      return null;
    }
  }

  return (
    <FilterContext.Provider value={filterContextValues}>
      <div className={styles.clientListContainer}>
        <div className={styles.topBar}>
          <div className={styles.header}>
            {!!subView && (
              <CpTooltip text="Back to Client List">
                <CpButton
                  btnType="icon"
                  aria-label="Back to Client List"
                  icon="caret-large-left"
                  className="cp-mr-12"
                  onClick={() => navTo(filterView?.id ? getRouteByFilterViewId(filterView.id) : "active")}
                />
              </CpTooltip>
            )}
            <div className={a("cp-subheader").m("cp-ml-12", !subView)}>{subView?.name || "Client List"}</div>
          </div>
          <div className="cp-flex">
            {canCreateEditClients && <AddClientButton />}
            <KabobMenu
              ft_crm={ft_crm}
              currentView={subView?.name || "Active Clients"}
              showActiveClients={() => navTo("active")}
              showInactiveClients={() => navTo("inactive")}
              showArchivedClients={() => navTo("archived")}
              filterViews={filterViews}
              filterViewOverrides={filterViewOverrides}
              selection={selection}
              currentFilterViewId={filterView?.id}
              currentFilters={filters}
              currentSortData={sortData}
              globalSearch={search}
              totalClientsCount={totalClientsCount}
            />
          </div>
        </div>
        {!subView && visibleFilterViews?.length > 0 && (
          <CpTabsDynamic
            className="cp-pr-24"
            tabsData={visibleFilterViews}
            activeId={routeId ? getFilterViewIdByRoute(routeId) : defaultFilterViewId}
            onChange={(id) => {
              const filterViewId = getRouteByFilterViewId(id);
              navTo(filterViewId);
            }}
          />
        )}
        <CpTable.ActionBar>
          <div className="cp-flex-center cp-gap-16 items-center cp-mr-24">
            {!subView && (
              <>
                <CpButton icon="organize-boards" aria-label="Customize" onClick={() => setShowCustomizeModal(true)} />
                {showCustomizeModal && (
                  <ColumnCustomizationModal
                    schema={schema}
                    filterViews={filterViews}
                    initialFilterView={filterView}
                    filterViewOverrides={filterViewOverrides}
                    onColumnVisibilityChange={onColumnVisibilityChange}
                    onAfterClose={() => setShowCustomizeModal(false)}
                  />
                )}
              </>
            )}
            <ExportClientListButton
              filterView={filterView}
              filterViewOverrides={filterViewOverrides}
              filters={filters}
              sortData={sortData}
              search={search}
              selection={selection}
              subView={subView}
              ft_crm={ft_crm}
            />
            {totalClientsCount > 0 && (
              <div>
                {selection.totalSelected > 0
                  ? `${selection.totalSelected.toLocaleString()} of ${totalClientsCount.toLocaleString()} ${
                      totalClientsCount === 1 ? "client" : "clients"
                    } selected`
                  : `${totalClientsCount.toLocaleString()} ${totalClientsCount === 1 ? "client" : "clients"}`}
              </div>
            )}
            {subView
              ? selection.totalSelected > 0 && (
                  <SubViewActionButton
                    className="cp-ml-12"
                    subView={subView}
                    filters={filters}
                    selection={selection}
                    deselectAll={selection.deselectAll}
                    search={search}
                  />
                )
              : newFiltersCount > 0 && (
                  <>
                    <SaveFilterDropdown
                      newFiltersCount={newFiltersCount}
                      filters={filters}
                      sortData={sortData}
                      filterView={filterView}
                      filterViewOverrides={filterViewOverrides}
                      onFilterViewCreate={(viewId) => {
                        if (filterView.created_by === "__DEFAULT" || filterView.read_only) resetFilters();
                        setMoveToFilterView(viewId);
                      }}
                    />
                    <CpButton onClick={resetFilters} className="cp-ml-8" btnType="tertiary">
                      Reset filter{newFiltersCount > 1 ? "s" : ""}
                    </CpButton>
                  </>
                )}
            {!subView && !!filterViewsQuery.data && showingInactiveClients && (
              <div className={styles.limitedBulkWarning}>
                Bulk actions are limited when inactive clients are filtered
              </div>
            )}
          </div>
          {selection.totalSelected > 0 ? (
            <BulkActionList
              selection={selection}
              actions={getAvailableBulkActions()}
              filters={filters}
              search={search}
              ft_crm={ft_crm}
            />
          ) : (
            <div className={styles.searchInput}>
              <CpInput
                isSearch
                placeholder="Search client list"
                value={search}
                onChange={updateSearch}
                onClear={() => updateSearch("")}
              />
            </div>
          )}
        </CpTable.ActionBar>
        <CpTable
          data={clients}
          schema={schema}
          selection={selection}
          filterControl={filterControl}
          columnWidths={columnWidths}
          columnOrder={columnOrder}
          onColumnWidthsChange={setColumnWidths}
          onColumnOrderChange={onColumnOrderChange}
          visibleColumns={visibleColumns}
          onScroll={(e) => {
            if (!infiniteScroll) return;
            const scrollAmt = e.target.scrollTop + e.target.clientHeight;
            const nearBottom = scrollAmt >= e.target.scrollHeight * 0.85;
            if (nearBottom) {
              if (!clientsQuery.isFetchingNextPage && clientsQuery.hasNextPage) {
                fetchNextPage();
              }
            }
          }}
          renderContextMenu={({ clickedResource }) => (
            <ContextMenu client={clickedResource} actions={subviewContextActions} selection={selection} />
          )}
          renderEmptyState={getEmptyState()}
        />
        <CpTable.Footer>
          <CpPages
            currentPage={page}
            onChange={onPageChange}
            totalPages={infiniteScroll ? 1 : pageCount || 1}
            onLimitChange={onLimitChange}
            limit={pageLimit}
            limitOptions={["50", "100", "200", "All"]}
          />
        </CpTable.Footer>
      </div>
    </FilterContext.Provider>
  );
}
