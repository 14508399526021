import React, { useMemo } from "react";
import ListCell from "./list-cell.component";

export default function RoleCell({ columnSchema, resource: client }) {
  const assignmentsByRoleId = useMemo(() => {
    if (client.role_assignments?.length > 0) {
      return client.role_assignments.reduce((acc, assignment) => {
        acc[assignment.role_id] = assignment;
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [client.role_assignments]);
  const assignments = assignmentsByRoleId[columnSchema.roleId];

  if (assignments?.users?.length > 0 || assignments?.teams?.length > 0) {
    return <ListCell value={[...assignments.users.map((u) => u.name), ...assignments.teams.map((t) => t.name)]} />;
  } else {
    return "—";
  }
}
RoleCell.overrideEmptyCell = true;
