import { clientToModel } from "./client-to-js.js";
import { modelToClient } from "./js-to-client.js";
export function mapClientToModel(client) {
  if (client === undefined) {
    return undefined;
  } else {
    const topSection = clientToModel.topSection(client);
    const emails = clientToModel.emails(client);
    const phones = clientToModel.phones(client);
    const addresses = clientToModel.addresses(client);
    const roleAssignments = client.role_assignments;
    const usersTeams = client.general_assigned;
    const bottomSection = clientToModel.bottomSection(client);
    const always = clientToModel.always(client);
    return {
      ...topSection,
      ...emails,
      ...phones,
      ...addresses,
      roleAssignments,
      usersTeams,
      ...bottomSection,
      ...always,
    };
  }
}

export function mapModelToAPI(model) {
  const topSection = modelToClient.topSection(model);
  const emails = modelToClient.emails(model);
  const phones = modelToClient.phones(model);
  const addresses = modelToClient.addresses(model);
  const rolesUsersTeams = modelToClient.rolesUsersTeamsSections(model);
  const bottomSection = modelToClient.bottomSection(model);
  const always = modelToClient.always(model);
  return {
    ...topSection,
    ...emails,
    ...phones,
    ...addresses,
    rolesUsersTeams,
    ...bottomSection,
    ...always,
  };
}
