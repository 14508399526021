import { union, findIndex, filter } from "lodash";

export const resolution_actions = { merge: "merge", new: "new" };

export const consistent_column_headers = [
  "first_name",
  "last_name",
  "default_phone",
  "tin",
  "default_country",
  "default_region",
  "default_locality",
  "default_email",
  "client_source",
  "type",
  "business_type",
  "spouse_first_name",
  "spouse_last_name",
];

export function getCombinedColumnHeaderNames(mapping = []) {
  return union(
    consistent_column_headers,
    cleanMapping(mapping).map((item) => item.to)
  );
}

export function getFilteredCRMColumns(crm_columns = [], header_names = []) {
  return filter(crm_columns, (field) => header_names.includes(field.name));
}

export function cleanMapping(mapping = []) {
  return filter(mapping, (mapItem) => (mapItem.to ? true : false));
}

export function sortConflictsByImportRow(conflicts) {
  return conflicts
    ? conflicts.sort((a, b) => parseInt(a.import_row.row_id, 10) - parseInt(b.import_row.row_id, 10))
    : [];
}

export function getResolutionIndex(resolutions = [], id, id_element = "row_id") {
  return findIndex(resolutions, (resolution) => resolution[id_element] === id);
}

export function getResolutionAtIndex(resolutions = [], index) {
  return resolutions[index] || null;
}

export function lookUpColumnLabel(crmColumns = [], columnName = "") {
  const columnIndex = findIndex(crmColumns, (column) => column.name === columnName);
  return ~columnIndex ? crmColumns[columnIndex].label : columnName;
}

export function lookUpColumnDataType(crmColumns = [], columnName = "") {
  const columnIndex = findIndex(crmColumns, (column) => column.name === columnName);
  return ~columnIndex ? crmColumns[columnIndex].type : "text";
}

export function isValid(resolutions, conflicts) {
  return resolutions.length === conflicts.length;
}

export function hasResolutionForCanopyId(resolutions, id) {
  return !!getResolutionAtIndex(resolutions, getResolutionIndex(resolutions, id, "id"));
}

export function hasResolutionForRowId(resolutions, id) {
  return !!getResolutionAtIndex(resolutions, getResolutionIndex(resolutions, id));
}

export function getUnresolvedConflicts(resolutions, conflicts) {
  //Rows without a resolution
  const unreslovedConflicts = conflicts.filter(
    (conflict) => !hasResolutionForRowId(resolutions, conflict.import_row.row_id)
  );
  //Rows with available conflicting canopy clients to merge
  return unreslovedConflicts.filter(
    (conflict) =>
      conflict.conflicting_canopy_clients.filter(
        (canopyClient) => !hasResolutionForCanopyId(resolutions, canopyClient.id)
      ).length
  );
}
