// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-dedupe-modal-modals-resolve-table-styles__resolveTable {
  background: rgba(0, 150, 230, 0.05);
  color: #0096e6;
  border: 2px solid #ecf4f8;
  overflow: auto;
}

.src-dedupe-modal-modals-resolve-table-styles__justifyLeft {
  justify-content: flex-start;
}

.src-dedupe-modal-modals-resolve-table-styles__resolveTableHeader {
  border-bottom: 2px solid #ecf4f8;
}

.src-dedupe-modal-modals-resolve-table-styles__resolveTableHeaderText {
  font-weight: normal;
  text-align: left;
  padding: 8px;
  border: inherit;
}

.src-dedupe-modal-modals-resolve-table-styles__resolveTableText {
  font-weight: bold;
}

.src-dedupe-modal-modals-resolve-table-styles__radioLabel {
  margin: 0px;
  white-space: nowrap;
  float: left;
  clear: both;
}

.src-dedupe-modal-modals-resolve-table-styles__resolveCard {
  width: 70rem !important;
  left: 44%;
}

.src-dedupe-modal-modals-resolve-table-styles__radioLabelSpan {
  padding: 0.4rem 2.4rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/dedupe-modal/modals/resolve-table.styles.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,cAAc;EACd,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".resolveTable {\n  background: rgba(0, 150, 230, 0.05);\n  color: #0096e6;\n  border: 2px solid #ecf4f8;\n  overflow: auto;\n}\n\n.justifyLeft {\n  justify-content: flex-start;\n}\n\n.resolveTableHeader {\n  border-bottom: 2px solid #ecf4f8;\n}\n\n.resolveTableHeaderText {\n  font-weight: normal;\n  text-align: left;\n  padding: 8px;\n  border: inherit;\n}\n\n.resolveTableText {\n  font-weight: bold;\n}\n\n.radioLabel {\n  margin: 0px;\n  white-space: nowrap;\n  float: left;\n  clear: both;\n}\n\n.resolveCard {\n  width: 70rem !important;\n  left: 44%;\n}\n\n.radioLabelSpan {\n  padding: 0.4rem 2.4rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resolveTable": `src-dedupe-modal-modals-resolve-table-styles__resolveTable`,
	"justifyLeft": `src-dedupe-modal-modals-resolve-table-styles__justifyLeft`,
	"resolveTableHeader": `src-dedupe-modal-modals-resolve-table-styles__resolveTableHeader`,
	"resolveTableHeaderText": `src-dedupe-modal-modals-resolve-table-styles__resolveTableHeaderText`,
	"resolveTableText": `src-dedupe-modal-modals-resolve-table-styles__resolveTableText`,
	"radioLabel": `src-dedupe-modal-modals-resolve-table-styles__radioLabel`,
	"resolveCard": `src-dedupe-modal-modals-resolve-table-styles__resolveCard`,
	"radioLabelSpan": `src-dedupe-modal-modals-resolve-table-styles__radioLabelSpan`
};
export default ___CSS_LOADER_EXPORT___;
