import React from "react";
// project
import { SharedValueDecorator } from "src/forms-lib";
import Input from "src/form/input/input.component";

@SharedValueDecorator
export default class ContactPerson extends React.Component {
  render() {
    return (
      this.props.sharedValue && (
        <div className="cps-margin-bottom-16">
          <Input name="contact_person" type="text" label="Contact person" />
        </div>
      )
    );
  }
}
