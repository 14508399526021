import React, { useState } from "react";
import { formatSsn, formatEin } from "src/common/utils.helpers";

function format(id: string, value: string, mask: boolean) {
  switch (id) {
    case "ssn":
      return formatSsn(value, { mask });
    case "ein":
      return formatEin(value, { mask });
    default:
      return value;
  }
}

export function MaskedCell({ value, columnId }: { value: string; columnId: string }) {
  const [showMasked, setShowMasked] = useState(true);
  return value ? (
    <div className="fs-exclude" onMouseEnter={() => setShowMasked(false)} onMouseLeave={() => setShowMasked(true)}>
      {format(columnId, value, showMasked)}
    </div>
  ) : (
    "—"
  );
}
