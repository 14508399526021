import React, { ReactNode, useEffect, useRef } from "react";

type ScrollIntoViewProps = {
  className?: string;
  children?: ReactNode;
  retriggerDeps?: any[];
  scrollBehavior?: "auto" | "instant" | "smooth";
};

/**
 * Wraps children in a div and attempts to scroll to it on mount or whenever the retriggerDeps change.
 *
 * @component
 * @param {string} [props.className] - The CSS class name for the wrapping div.
 * @param {ReactNode} props.children - The content to be rendered inside the div.
 * @param {any[]} [props.retriggerDeps] - The dependencies that trigger the scroll behavior when changed.
 */
export function ScrollIntoView({
  children,
  className = "",
  retriggerDeps = [],
  scrollBehavior = "auto",
}: ScrollIntoViewProps) {
  const elRef = useRef<HTMLDivElement | null>(null);
  const scroll = () => elRef.current?.scrollIntoView({ behavior: scrollBehavior });
  useEffect(() => scroll(), retriggerDeps); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={className} ref={elRef}>
      {children}
    </div>
  );
}
