import React from "react";
import PropTypes from "prop-types";
import { useSharedState, useFormInput } from "src/forms-lib";
import CustomField from "./custom-field.component.js";
import { useCss } from "kremling";
import FormErrorBoundary from "src/form/form-error-boundary/form-error-boundary.component.js";

CustomFieldsSection.propTypes = {
  customFields: PropTypes.array.isRequired,
  client: PropTypes.object,
};

export default function CustomFieldsSection(props) {
  const [value, updateValue] = useFormInput({ name: "custom_fields" });
  const sharedState = useSharedState();
  const filteredFields = filteredCustomFields(props, sharedState);
  const scope = useCss(css);

  return (
    <FormErrorBoundary message={`Unable to show custom fields. We're working on a fix.`}>
      <div className="customFields" {...scope}>
        {filteredFields &&
          filteredFields.map((field) => {
            return (
              <CustomField
                key={field.field_id}
                field={field}
                value={getFieldValue(field)}
                onChange={(inputValue) => updateCustomFieldsValue(field, inputValue)}
              />
            );
          })}
      </div>
    </FormErrorBoundary>
  );

  function updateCustomFieldsValue(field, inputValue) {
    if (inputValue === null) {
      inputValue = "";
    }

    const customFieldsObj = { ...value };
    customFieldsObj[field.field_id] = { type: field.field_type, value: inputValue };
    updateValue(customFieldsObj);
  }

  function getFieldValue(field) {
    if (value && value[field.field_id]) {
      return value[field.field_id].value;
    } else {
      return "";
    }
  }
}

function filterCustomFields(fieldClientType, clientIsBusiness) {
  if (clientIsBusiness === undefined) {
    return false;
  }

  if (fieldClientType === "both") {
    return true;
  }

  if (clientIsBusiness) {
    return fieldClientType === "businesses";
  } else {
    return fieldClientType === "individuals";
  }
}

function filteredCustomFields(props, sharedState) {
  const fields = props.customFields.length
    ? props.customFields.filter((field) => {
        return filterCustomFields(field.client_type, props.client ? props.client.is_business : sharedState.is_business);
      })
    : null;

  return fields;
}

const css = `
  & .customFields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
