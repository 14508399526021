import React, { useState, useMemo, useCallback } from "react";
import { CpButton, CpCard, CpSelectMulti } from "canopy-styleguide!sofe";
import useTeamMembersQuery from "src/common/queries/use-team-members-query.hook";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

type ShareFilterViewProps = {
  filterView: any;
  onClose: () => void;
  onShare: (sharingData: any) => void;
};

export default function ShareFilterView(props: ShareFilterViewProps) {
  const { filterView, onClose, onShare } = props;
  const { teamMembers, teams } = useTeamMembersQuery();

  const [searchTerm, setSearchTerm] = useState("");
  const [sharedList, setSharedList] = useState(filterView.sharingData || []);
  const [user] = useWithUserAndTenant();

  function cancel() {
    onClose();
  }

  const filterItems = useCallback(
    (item: any) => {
      const terms = [item.name, item.profile_name];
      return terms.join("").toLowerCase().includes(searchTerm.toLowerCase());
    },
    [searchTerm]
  );

  const teamMemberData = useMemo(
    () => [
      {
        id: 1,
        name: "Team Members",
        data: teamMembers
          .filter((tm: any) => tm.id !== user?.id)
          .map((tm: any) => ({
            ...tm,
            name: tm.profile_name || tm.name,
            subName: tm.name,
            namechip: {
              name: tm.name,
              imgSrc: tm.profile_picture_url,
            },
            type: "user",
          })),
      },
      {
        id: 2,
        name: "Teams",
        data: teams.map((team: any) => ({
          ...team,
          icon: "person-people",
          type: "team",
        })),
      },
    ],
    [teamMembers, teams, user]
  );

  const firstSelectedUser = useMemo(() => {
    const firstTmId = sharedList?.[0]?.id;
    return teamMembers.find((tm: any) => tm.id === firstTmId);
  }, [teamMembers, sharedList]);

  function shareFilterView() {
    const sharingData = sharedList.map((i: any) => ({ id: i.id, type: i.type }));
    onShare(sharingData);
    onClose();
  }

  return (
    <CpCard>
      <CpCard.Body>
        <div className="cp-mb-4">Share list with team members</div>
        <CpSelectMulti
          className="cp-mb-8"
          insertSearch
          onChange={setSharedList}
          renderValue={(value: any) =>
            value.length === 1 ? firstSelectedUser?.name : `${value.length} People Selected`
          }
          data={teamMemberData}
          isGroupData
          searchOnChange={setSearchTerm}
          searchValue={searchTerm}
          value={sharedList}
          placeholder="Search for team members or teams"
          searchFilter={filterItems}
          triggerIsBlock
        />
        <div className="cps-caption">
          <i>Please note, only the list creator can make edits to a shared list. </i>
        </div>
      </CpCard.Body>
      <CpCard.Footer className="cp-flex">
        <CpButton btnType="primary" onClick={shareFilterView}>
          Done
        </CpButton>
        <CpButton btnType="flat" onClick={cancel}>
          Cancel
        </CpButton>
      </CpCard.Footer>
    </CpCard>
  );
}
