import React from "react";
import { IndustrySelect } from "src/common/form/client/base";
import useFormInput from "src/forms-lib/use-form-input.hook";

export default function Industry(props) {
  const [value, updateValue] = useFormInput({
    name: "industry",
  });
  return (
    <div>
      <IndustrySelect {...props} value={value} onChange={updateValue}></IndustrySelect>
    </div>
  );
}
