import React, { ReactNode } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { CpButton, CpTooltip } from "canopy-styleguide!sofe";

type MultiFieldProps = {
  children: (opts: { index: number; resource: any; renderOptions: () => ReactNode }) => ReactNode;
  fieldName: string;
  control: Control;
  watch: (field: string) => any;
  resourceName?: string;
  maxAmount?: number;
  appendValue?: any;
};

export function MultiField({
  children,
  fieldName,
  resourceName = "item",
  control,
  watch,
  maxAmount,
  appendValue,
}: MultiFieldProps) {
  useCssModules(css);
  const { fields, append, remove, update } = useFieldArray({
    name: fieldName,
    control,
  });
  const resources = watch(fieldName);

  function setPrimary(updateIndex: number) {
    fields.forEach((field, i) => {
      update(i, {
        ...resources[i],
        isPrimary: i === updateIndex,
      });
    });
  }

  function removeResource(index: number) {
    if (resources[index].isPrimary) {
      let newPrimaryIndex = 0;
      if (index === 0 && resources.length > 1) {
        newPrimaryIndex = 1;
      }
      update(newPrimaryIndex, {
        ...resources[newPrimaryIndex],
        isPrimary: true,
      });
    }
    remove(index);
  }

  function renderOptions(resource: any, index: number) {
    return (
      <div className="flex cp-gap-8">
        <CpTooltip text={"Primary " + resourceName}>
          <CpButton
            type="button"
            icon={resource.isPrimary ? "shape-star-filled" : "shape-star-open"}
            className={resource.isPrimary ? s.yellow : ""}
            aria-label="Set primary"
            onClick={() => setPrimary(index)}
          />
        </CpTooltip>
        <CpTooltip text={"Remove " + resourceName}>
          <CpButton
            type="button"
            icon="crud-trash-large "
            aria-label={`Remove ${resourceName}`}
            onClick={() => removeResource(index)}
          />
        </CpTooltip>
      </div>
    );
  }

  return (
    <div className="flex flex-col cp-gap-16">
      {fields.map((field, index) => {
        const resource = resources[index];
        if (!resource) return null;
        return (
          <div key={field.id}>
            {children({
              index,
              resource,
              renderOptions: () => renderOptions(resource, index),
            })}
          </div>
        );
      })}
      <div className="self-end">
        <CpButton
          type="button"
          btnType="flat"
          onClick={() => append({ ...appendValue, isPrimary: resources.length === 0 })}
          disabled={maxAmount ? fields.length >= maxAmount : false}
        >
          + Add {resourceName}
        </CpButton>
      </div>
    </div>
  );
}

const { s, css } = cssModules`
  .yellow {
    color: var(--cps-color-yellow);
  }
`;
