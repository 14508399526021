import React from "react";
import CprMask from "cpr-mask";
import { useController } from "react-hook-form";
import { CpLabel } from "canopy-styleguide!sofe";

interface MaskedInputFieldProps {
  className?: string;
  control: any;
  fieldName: string;
  label?: string;
  maskCharacter?: string;
  masks: any[];
  nonValidMsg: string;
  validateFn?: (value: string) => boolean;
  required?: boolean;
}

export function MaskedInputField({
  className,
  control,
  fieldName,
  label,
  maskCharacter = "#",
  validateFn,
  nonValidMsg,
  masks,
  required
}: MaskedInputFieldProps) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name: fieldName,
    control,
    rules: {
      validate: {
        isValid: (value: string) => {
          if (validateFn) {
            return validateFn(value) ? true : nonValidMsg;
          } else {
            return true;
          }
        },
      },
    },
  });

  return (
    <div>
      <CpLabel required={required}>{label}</CpLabel>
      <CprMask
        className={className}
        filler={maskCharacter}
        initialValue={value}
        inputClass="cps-form-control"
        invalidClass="cps-has-error"
        onChange={onChange}
        validateMethod={() => !error}
        nonValidMsg={error?.message}
        masks={masks}
        onBlur={onBlur}
      />
    </div>
  );
}
