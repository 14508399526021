import React, { useMemo } from "react";
import { CpButton, CpModal, CpLoader } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import { TContact } from "src/common/types";
import { TContactModalMode } from "../contact-modal-content.component";
import { ContactInfoSection } from "./sections/contact-info-section.component";
import { ClientPortalSection } from "./sections/client-portal/client-portal-section.component";
import { AssignedClientsSection } from "./sections/assigned-clients/assigned-clients-section.component";
import { mapContactDataToSections } from "./view-contact.helpers";

export type ViewContactProps = {
  contact?: TContact | Omit<TContact, "id">;
  setMode: (mode: TContactModalMode) => void;
  close: () => void;
  disableEdit?: boolean;
};

export function ViewContact({ contact, setMode, close, disableEdit }: ViewContactProps) {
  const canEdit = useHasAccess("clients_create_edit");

  const { contactInfo, clientPortalInfo, assignedClients } = useMemo(() => {
    return mapContactDataToSections(contact);
  }, [contact]);

  return (
    <>
      <CpModal.Header title={contact?.name || ""} />
      <CpModal.Body className="cp-flex-column cp-gap-16">
        {!contact ? (
          <CpLoader />
        ) : (
          <>
            <ContactInfoSection contactInfo={contactInfo} />
            {clientPortalInfo.clientPortalEmail && "id" in contact && (
              <ClientPortalSection
                clientPortalInfo={clientPortalInfo}
                contactId={contact.id}
                contactName={contact.name ?? ""}
              />
            )}
            {!!assignedClients.length && (
              <AssignedClientsSection assignedClients={assignedClients} closeModal={close} />
            )}
          </>
        )}
      </CpModal.Body>
      <CpModal.Footer>
        {canEdit && !disableEdit && (
          <CpButton btnType="primary" className="cp-mr-8" onClick={() => setMode("edit")}>
            Edit
          </CpButton>
        )}
        <CpButton btnType={canEdit ? "flat" : "primary"} onClick={close}>
          Close
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
