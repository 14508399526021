import React from "react";
import ClientCardLoader from "./client-card-loader.component";
import { useFetcher } from "fetcher!sofe";
import { CpButton, CpEmptyState } from "canopy-styleguide!sofe";
import { useCss, m } from "kremling";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";

export default function ResolutionCasesList({ clientId, buttonClick }) {
  const history = useHistory();
  const { loading, results } = useFetcher(`/api/clients/${clientId}/resolution_cases_list`, {
    pluck: "resolution_cases",
  });

  const resolutionCases = results?.slice(0, 6);

  const emptyState = () => {
    return (
      <CpEmptyState
        className="cp-mt-32"
        img="es_engagements_list"
        text="No active resolution cases"
        subText="A resolution case allows you to bundle and invoice your work."
        cta={
          <CpButton btnType="secondary" onClick={buttonClick}>
            Create resolution case
          </CpButton>
        }
      />
    );
  };

  return (
    <div
      {...useCss(styles)}
      className={`${m("full", resolutionCases?.length === 6).m("empty", resolutionCases?.length === 0)}`}
    >
      {loading ? (
        <ClientCardLoader />
      ) : !resolutionCases?.length ? (
        emptyState()
      ) : (
        resolutionCases.map((resCase) => (
          <div
            key={resCase.id}
            className="row"
            onClick={() => history.push(`/taxes/client/${clientId}/resolution-cases/${resCase.id}/summary`)}
          >
            <div className="cps-ellipsis">{resCase.name}</div>
            <div className="cps-color-cool-gray cps-ellipsis">
              Updated {DateTime.fromISO(resCase.updated_at).toFormat("ff")}&nbsp;
            </div>
          </div>
        ))
      )}
    </div>
  );
}

const styles = `
& .row {
  flex-direction: column;
  justify-content: center;
  height: 70.1px;
}
`;
