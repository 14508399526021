import React from "react";
import { a, m } from "kremling";
// sofe
import { CpLoader } from "canopy-styleguide!sofe";

export default function TypeaheadMenu(props) {
  const {
    // downshift props
    isOpen,
    highlightedIndex,
    getItemProps,
    getMenuProps,
    // typahead state
    items,
    search,
    loading,
    // typeahead props
    itemToString,
    resultFormat,
    resultsAppendBlock,
    itemKey,
  } = props;

  return (
    <div className={a("cps-dropdown").m("cps-open", isOpen)}>
      <ul {...getMenuProps()} className="cps-dropdown-menu">
        {getUlContents()}
      </ul>
    </div>
  );

  function getUlContents() {
    if (isOpen && loading) {
      return <CpLoader />;
    } else if (isOpen && !loading) {
      return itemsOrNone(
        items, // props
        highlightedIndex, // downshift prop
        getItemProps, // downshift prop
        itemToString, // props
        resultFormat, // props
        itemKey, // props
        search, // local
        resultsAppendBlock // props
      );
    } else {
      return null;
    }
  }
}

function itemsOrNone(
  foundItems,
  highlightedIndex,
  getItemProps,
  itemToString,
  resultFormat,
  itemKey,
  search,
  appendBlock = []
) {
  let results = [];
  if (foundItems.length >= 1) {
    results = foundItems.map((item, index) => (
      <li
        className={m("downshiftItemSelected", highlightedIndex === index)}
        {...getItemProps({
          key: itemKey(item),
          index,
          item,
        })}
      >
        <button type="button" className="dropdown-menu-item">
          {resultFormat ? resultFormat(item) : itemToString(item)}
        </button>
      </li>
    ));
  } else {
    results = [
      <li
        className={m("downshiftItemSelected", highlightedIndex === 0)}
        {...getItemProps({
          key: "none",
          item: {},
        })}
      >
        <button type="button" className="dropdown-menu-item">
          No matches
        </button>
      </li>,
    ];
  }
  return withAppended(results, appendBlock);

  function withAppended(results, append) {
    return [...results, ...[append ? createAppendBlock(append, results.length) : []]];
  }

  function createAppendBlock(blockConfig = [], startingIndex = 0) {
    let map = blockConfig.map((liConfig, index) => {
      const { key, item } = liConfig.itemProps;
      return (
        <li
          className={m("downshiftItemSelected", highlightedIndex === startingIndex + index)}
          {...getItemProps({
            key,
            item: {
              ...item,
              search: search,
            },
          })}
        >
          {liConfig.liContents}
        </li>
      );
    });
    if (map.length > 0) {
      return [<li key="appendDivider" className="cps-divider" />, ...map];
    } else {
      return map;
    }
  }
}
