import { getDefaultVisibleColumns } from "../contact-list-schema.js";

// Formats visible_columns into export_columns for the export filter view api
export function getExportColumns({
  exportAll,
  visibleColumns,
  columnOrder,
}: {
  exportAll: boolean;
  visibleColumns: string[];
  columnOrder: string[];
}) {
  if (exportAll) return [];

  if (!visibleColumns?.length) {
    return [...getDefaultVisibleColumns()];
  }

  return !!columnOrder?.length ? sortColumns(visibleColumns, columnOrder) : visibleColumns;
}

// Returns a copy of colsToSort sorted by the columnOrder
export function sortColumns(colsToSort: string[], columnOrder: string[]) {
  return [...colsToSort].sort((a, b) => {
    let indexA = columnOrder.indexOf(a);
    let indexB = columnOrder.indexOf(b);
    // If index cannot be found then push these columns to the end of the list
    if (indexA === -1) indexA = Infinity;
    if (indexB === -1) indexB = Infinity;

    return indexA - indexB;
  });
}
