import React from "react";
import { CpInputField } from "canopy-styleguide!sofe";

export function ContactFirstNameField({
  control,
  disabled,
  fieldName = "first_name",
  autoFocus,
}: {
  control: any;
  disabled?: boolean;
  fieldName?: string;
  autoFocus?: boolean;
}) {
  return (
    <CpInputField
      fieldName={fieldName}
      control={control}
      label="First Name"
      placeholder="First Name"
      required
      maxLength={150}
      disabled={disabled}
      autoFocus={autoFocus}
      rules={{
        required: {
          value: true,
          message: "First name is required",
        },
      }}
    />
  );
}
