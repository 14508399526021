import React, { useState } from "react";
import { CpModal, modalService } from "canopy-styleguide!sofe";
import { TContact } from "src/common/types";
import { ContactModalContent, ContactModalContentProps, TContactModalMode } from "./contact-modal-content.component";

export type ContactModalProps = {
  onAfterClose: () => void;
  contactId?: string;
  placeholderContact?: Omit<TContact, "id">;
  focusField?: string;
  mode: TContactModalMode;
  onContactSave?: (contactResponse: any) => void;
  closeAfterSave?: boolean;
  showAssignedClients?: boolean;
};

export default function ContactModal({
  onAfterClose,
  contactId,
  placeholderContact,
  focusField,
  mode,
  onContactSave,
  closeAfterSave,
  showAssignedClients = true,
}: ContactModalProps) {
  const [show, setShow] = useState(true);

  function close() {
    setShow(false);
  }

  return (
    <CpModal show={show} onClose={close} onAfterClose={onAfterClose} width={832}>
      <ContactModalContent
        closeModal={close}
        mode={mode}
        contactId={contactId}
        focusField={focusField}
        placeholderContact={placeholderContact}
        onContactSave={onContactSave}
        closeAfterSave={closeAfterSave}
        showAssignedClients={showAssignedClients}
      />
    </CpModal>
  );
}

// renderContactModal provides the option to utilize modalService to render the ContactModal
export function renderContactModal(props: ContactModalContentProps) {
  return modalService.render(ContactModalContent, props, { width: 832 });
}
