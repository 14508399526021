import React, { useState } from "react";
import { CpButton, CpDropdown } from "canopy-styleguide!sofe";
import TemplateList from "./template-list.component";
import TemplateConfirmation from "./template-confirmation.component";
import { getTemplateObs } from "../templates.resource";
import { successToast } from "toast-service!sofe";
import { handleError } from "src/error";

// validateForm - function that returns an array, where the first value is a boolean indicating if the form is dirty (if so it will show a confirmation modal), and the second value is an optional context object that will be passed to onApplyTemplate.
export default function SelectTemplateButton({
  onApplyTemplate,
  isManageAssignments = false,
  isBusiness = false,
  validateForm,
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [validationContext, setValidationContext] = useState(null);

  const onTemplateSelect = (templateId, close) => {
    getTemplateObs(templateId).subscribe((template) => {
      let [isFormDirty, context] = validateForm?.(template.template_data) || [false];
      setValidationContext(context);
      if (isFormDirty) {
        setSelectedTemplate({ name: template.name, data: template.template_data });
        setShowConfirm(true);
      } else {
        onApplyTemplate(template.template_data, context);
        successToast(`${template.name} template successfully applied`);
        close();
      }
    }, handleError);
  };

  return (
    <CpDropdown
      allowContentClicks
      appendTo="parent"
      contentWidth={showConfirm ? 434 : 291}
      renderWhenClosed={false}
      renderTrigger={({ toggle }) => (
        <CpButton btnType="flat" onClick={toggle} type="button">
          Templates
        </CpButton>
      )}
      renderContent={({ close }) => {
        return showConfirm ? (
          <TemplateConfirmation
            close={() => {
              close();
              setShowConfirm(false);
              setSelectedTemplate(null);
            }}
            isManageAssignments={isManageAssignments}
            onApply={() => {
              onApplyTemplate(selectedTemplate.data, validationContext);
              successToast(`${selectedTemplate.name} template successfully applied`);
              setShowConfirm(false);
              setSelectedTemplate(null);
              close();
            }}
          />
        ) : (
          <TemplateList
            isBusiness={isBusiness}
            onTemplateSelect={(templateId) => onTemplateSelect(templateId, close)}
          />
        );
      }}
    />
  );
}
