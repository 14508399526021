import React from "react";
import { CpButton, CpIcon, CpTooltip, CpWell } from "canopy-styleguide!sofe";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { a } from "kremling";
import { FilterViewCardKabob } from "./filter-view-card-kabob.component";

export function FilterViewCard({
  filterView,
  isMarkedForDelete,
  onVisibilityChange,
  onDuplicate,
  onDelete,
  onRestore,
  renderRenameDropdown,
  renderShareListDropdown,
}: any) {
  useCssModules(css);

  let numSharedUsers = 0;
  let numSharedTeams = 0;
  filterView.sharingData?.forEach((el: any) => (el.type === "user" ? numSharedUsers++ : numSharedTeams++));

  function getSharingSubtext() {
    if (filterView.createdBy) {
      return `Shared filter from ${filterView.createdBy.profile_name || filterView.createdBy.name}`;
    } else if (numSharedUsers > 0 && numSharedTeams > 0) {
      return `Sharing with ${numSharedUsers} team member${numSharedUsers > 1 ? "s" : ""} and ${numSharedTeams} team${
        numSharedTeams > 1 ? "s" : ""
      }`;
    } else if (numSharedUsers > 0) {
      return `Sharing with ${numSharedUsers} team member${numSharedUsers > 1 ? "s" : ""}`;
    } else if (numSharedTeams) {
      return `Sharing with ${numSharedTeams} team${numSharedTeams > 1 ? "s" : ""}`;
    }
  }

  return (
    <div className={s.filterViewContainer}>
      <CpIcon className={s.handle} name="misc-drag-handle" />
      <CpWell className={s.well} level={1}>
        <div className={s.filterViewText}>
          <div className={a("cp-ellipsis cp-body cp-mr-8").m("cp-color-app-inactive-text", filterView.isHidden)}>
            {filterView.name}{" "}
            {filterView.isDefault && (
              <span className="cp-caption">
                <i>Cannot be deleted or renamed</i>
              </span>
            )}
          </div>
          {isMarkedForDelete ? (
            <div className="cp-body-caption cp-color-app-warning-text">
              <i>This filter view will be deleted upon closing this window</i>
            </div>
          ) : (
            !filterView.isDefault && (
              <div
                className={a("cp-caption").t(
                  "cp-color-app-callout-disabled-text",
                  "cp-color-app-callout-text",
                  filterView.isHidden
                )}
              >
                <i>{getSharingSubtext()}</i>
              </div>
            )
          )}
        </div>
        <div>
          {filterView.isHidden && (
            <CpTooltip text="Show filter view">
              <CpButton
                className="cp-mr-8"
                icon="misc-closed-eye"
                aria-label="Show filter view"
                onClick={() => onVisibilityChange({ filterView, visible: true })}
              />
            </CpTooltip>
          )}
          <FilterViewCardKabob
            filterView={filterView}
            onVisibilityChange={onVisibilityChange}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            onRestore={onRestore}
            renderRenameDropdown={renderRenameDropdown}
            renderShareListDropdown={renderShareListDropdown}
            isMarkedForDelete={isMarkedForDelete}
          />
        </div>
      </CpWell>
    </div>
  );
}

const { s, css } = cssModules`
  .filterViewContainer {
    display: flex;
    width: 100%;
    padding: 0 0 1.6rem 0;
    align-items: center;
  }

  .filterViewText {
    width: 85%;
  }

  .handle {
    flex: 0 0 3.2rem;
  }

  .well {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2.4rem;
    flex: 1 0 0;
    min-width: 0;
  }
`;
