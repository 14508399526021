import React, { ForwardedRef, ReactNode, forwardRef } from "react";
import { m } from "kremling";
import styles from "./field-section.styles.css";

interface FieldSectionProps {
  className?: string;
  label: string;
  children: ReactNode;
  error?: string;
  required?: boolean;
  labelTail?: ReactNode;
}

export default forwardRef(
  (
    { className, label, children, error, required, labelTail }: FieldSectionProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div>
        <div
          ref={ref}
          className={m(styles.errorSection, !!error).a(
            `${styles.fieldSection} ${className || ""} cp-flex flex-col cp-gap-16`
          )}
        >
          <div className="flex cp-gap-8 items-center">
            <div className="cp-body-sm cp-wt-semibold">
              {label}
              {required && <span className="cp-color-app-primary">{" *"}</span>}
            </div>
            {labelTail}
          </div>
          {children}
        </div>
        {error && <div className="cp-mt-4 cp-color-app-error-text">{error}</div>}
      </div>
    );
  }
);
