import React from "react";
import { useController } from "react-hook-form";
import { CpSelectSingleField, CpSelectSingle } from "canopy-styleguide!sofe";
import { useSourcesQuery } from "src/common/queries/use-sources-query.hook";
import { FieldLabel } from "src/common/form/field-label.component";
import { useClientNamesQuery } from "src/common/queries/use-client-names-query.hook";
import { useDebouncedSearch } from "src/common/use-debounced-search.hook";

export function SourceDropdown({ control }: { control: any }) {
  const { search, debouncedSearch, updateSearch } = useDebouncedSearch();
  const { sources } = useSourcesQuery();
  const { clientNames } = useClientNamesQuery({ search: debouncedSearch });

  const {
    field: { value, onChange },
  } = useController({ name: "source", control });

  return (
    <div className="flex cp-gap-8 items-center">
      <div className="grow flex flex-col">
        <FieldLabel>Source</FieldLabel>
        <CpSelectSingle clearable data={sources || []} triggerIsBlock value={value} onChange={onChange} />
      </div>
      {value?.name === "Referral" && (
        <div className="grow flex flex-col">
          <FieldLabel>Referred by</FieldLabel>
          <CpSelectSingleField
            fieldName="source_referred_by"
            control={control}
            controllerOptions={{ shouldUnregister: true }}
            data={clientNames || []}
            searchValue={search}
            searchOnChange={updateSearch}
            triggerIsBlock
            transformData={(client: any) => ({ name: client.display_name || client.name, id: client.id })}
          />
        </div>
      )}
    </div>
  );
}
