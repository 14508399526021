import React, { ReactNode } from "react";
import { CpLabel } from "canopy-styleguide!sofe";

interface FieldLabelProps {
  children: ReactNode;
  className?: string;
}

export function FieldLabel({ children, className }: FieldLabelProps) {
  return <CpLabel className={className || ""}>{children}</CpLabel>;
}
