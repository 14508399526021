import { clientTemplateQueries } from "src/queries";
import { useQuery } from "src/react-query";

export function useTemplatesQuery({ search, type, page }) {
  const query = useQuery(clientTemplateQueries.templates({ search, type, page }));

  return {
    templatesQuery: query,
    templates: query.data?.templates,
    templatesTotalPages: query.data?.pagination?.total_pages || 1,
  };
}
