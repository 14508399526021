import { useMemo } from "react";
import { useQuery } from "src/react-query";
import { teamMemberQueries } from "src/queries";

export function useTeamMembersQuery(onlyActive = true) {
  const teamMembersQuery = useQuery({
    ...teamMemberQueries.teamMembers({ onlyActive }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes in ms
  });

  const teamsQuery = useQuery({
    ...teamMemberQueries.allTeams(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes in ms
  });

  const { teamMembers, teams } = useMemo(
    () => ({
      teamMembers: teamMembersQuery.data || [],
      teams: teamsQuery.data || [],
    }),
    [teamMembersQuery.data, teamsQuery.data]
  );

  return {
    teamMembersQuery,
    teamsQuery,
    teamMembers,
    teams,
  };
}
export default useTeamMembersQuery;
