import React, { Fragment } from "react";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";

type SendEmailButtonProps = {
  email: string;
  clientName: string;
  primaryContactName?: string;
  isBusiness: boolean;
  onClick: () => void;
};

export function SendEmailButton({ email, clientName, primaryContactName, isBusiness, onClick }: SendEmailButtonProps) {
  const disabled = !email;
  const Tooltip = disabled ? CpTooltip : Fragment;
  const tooltipProps = disabled
    ? {
        position: "bottom",
        text: isBusiness
          ? `${clientName} is missing a business email address. Add an email address to their client record before drafting an email.`
          : `${clientName}'s primary contact, ${primaryContactName}, is missing an email address. Add an email address to their contact record before drafting an email.`,
      }
    : {};
  return (
    <Tooltip {...tooltipProps}>
      <div /** CpTooltip isn't working when it's just the button as the child for some reason*/>
        <button onClick={onClick} disabled={disabled}>
          <CpIcon
            className="cp-select-list__icon-left"
            name="communication-envelope"
            fill={disabled ? "var(--cp-color-app-disabled-text)" : "var(--cp-color-app-icon)"}
          />
          <span className={disabled ? "cp-color-app-disabled-text" : ""}>Send email</span>
        </button>
      </div>
    </Tooltip>
  );
}
