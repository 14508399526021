import React, { useState } from "react";
import { CpRadio, CpModal, CpButton, CpSelectMultiPills } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { handleError } from "src/error";
import { patchClients, createTag } from "src/client-list/client-list.resource";
import { clientQueries, tagQueries } from "src/queries";
import { useTagsQuery } from "src/common/queries/use-tags-query.hook";

export default function BulkTagModal({ onClose, filters, selection, search: clientListSearch }) {
  const [show, setShow] = useState(true);
  const [action, setAction] = useState("add");
  const [search, setSearch] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const { tagsQuery } = useTagsQuery();

  function onApply() {
    close();
    patchClients({
      search: clientListSearch,
      filters,
      action: action === "add" ? "add_tags" : "remove_tags",
      tag_ids: selectedTags.map((t) => t.id),
      [selection.type]: selection.toArray(),
    }).subscribe(() => {
      const count = selection.totalSelected;
      successToast(
        `${selectedTags.length > 1 ? "Tags" : "Tag"} will be ${
          action === "add" ? "added to" : "removed from"
        } the selected client${count > 1 ? "s" : ""}.`
      );
      // Small delay before invalidating in case bulk job hasn't finished
      setTimeout(() => {
        clientQueries.invalidate();
      }, 1000);
    }, handleError);
  }

  function close() {
    setShow(false);
  }

  return (
    <CpModal show={show} onClose={close} onAfterClose={onClose}>
      <CpModal.Header title="Edit Tags" />
      <CpModal.Body>
        <CpRadio onChange={setAction} value={action} name="addOrRemove" inline>
          <CpRadio.Item id="add">Add tags</CpRadio.Item>
          <CpRadio.Item id="remove">Remove tags</CpRadio.Item>
        </CpRadio>
        <CpSelectMultiPills
          className="cp-mt-12 cp-mb-8"
          data={tagsQuery.data || []}
          placeholder="Search tags"
          onChange={setSelectedTags}
          renderPill={(item) => item.name}
          searchOnChange={(val) => {
            if (val.length <= 25) {
              setSearch(val);
            }
          }}
          searchValue={search}
          searchFilter
          value={selectedTags}
          renderEmptyState={
            action === "remove"
              ? null
              : ({ renderSingleItem }) => {
                  return renderSingleItem(`Press enter to add “${search.trim()}”`, "25 character limit");
                }
          }
          emptyStateAction={({ onChange }) => {
            createTag(search.trim()).subscribe((res) => {
              onChange((val) => [...val, res.tags]);
              setSearch("");
              tagQueries.invalidate();
            }, handleError);
          }}
        />
        <div>
          <i>
            These tags will be{" "}
            {action === "add" ? (
              <>
                <b>added</b> to
              </>
            ) : (
              <>
                <b>removed</b> from
              </>
            )}{" "}
            the selected clients.
          </i>
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={onApply} className="cp-mr-8" disabled={selectedTags.length === 0}>
          Apply
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
