import React, { useMemo, useState } from "react";
import { CustomizeFilterViewsModal } from "src/common/customize-filter-views-modal/customize-filter-views-modal.component";
import type { Column, FilterView } from "src/common/customize-filter-views-modal/types";
import { getDefaultVisibleColumns } from "./contact-list-schema";
import { deleteFilterViews, patchFilterView } from "src/client-list/client-list.resource";
import RenameFilterView from "src/common/customize-filter-views-modal/edit-filter-views/rename-filter-view.component";
import ShareFilterView from "src/common/customize-filter-views-modal/edit-filter-views/share-filter-view.component";
import { createContactFilterView, patchContactFilterViewOrder } from "src/resources/contacts.resource";
import { handleError } from "src/error";
import { contactFilterViewQueries } from "src/queries";

const sections = [
  {
    id: "contact_attributes",
    name: "Contact attributes",
  },
  {
    id: "client_portal_attributes",
    name: "Client Portal attributes",
  },
];

type CustomizeContactFilterViewsModalProps = {
  initialFilterView: any;
  filterViews: any[];
  filters: any;
  sortData: any[];
  schema: any;
  onClose: (args: { filterViewIdsMarkedForDelete: string[] }) => void;
  onAfterClose: () => void;
  filterViewOverrides: Record<string, { visibleColumns: string[] }>;
  setFilterViewOverrides: (filterViewOverrides: any) => void;
};

export function CustomizeContactFilterViewsModal({
  initialFilterView,
  filterViews,
  filters,
  sortData,
  schema,
  onClose,
  onAfterClose,
  filterViewOverrides,
  setFilterViewOverrides,
}: CustomizeContactFilterViewsModalProps) {
  const [show, setShow] = useState(true);

  const formFilterViews: FilterView[] = useMemo<any[]>(() => {
    return (
      filterViews?.map((fv) => ({
        id: fv.id,
        name: fv.name,
        visibleColumns:
          filterViewOverrides[fv.id]?.visibleColumns || fv.filter_data?.visible_columns || getDefaultVisibleColumns(),
        isDefault: fv.created_by === "__DEFAULT",
        isHidden: fv.hidden,
        sharingData: fv.sharing_data,
      })) || []
    );
  }, [filterViews, filterViewOverrides]);

  const columns: Column[] = useMemo<Column[]>(() => {
    const columns: Column[] = [];
    Object.entries(schema).forEach(([columnId, columnSchema]: [string, any]) => {
      if (columnSchema.section) {
        columns.push({
          id: columnId,
          name: columnSchema.label,
          section: columnSchema.section,
          filterFieldId: columnSchema.filter.fieldId,
          sortFieldId: columnSchema.sort.fieldId,
        });
      }
    });
    return columns;
  }, [schema]);

  function invalidateFilterViews() {
    contactFilterViewQueries.invalidate();
  }

  function updateFilterView(filterView: FilterView, body: any) {
    patchFilterView(filterView.id, body).subscribe(() => {
      invalidateFilterViews();
    }, handleError);
  }

  function onColumnVisibilityChange(filterView: FilterView, newVisibleColumns: string[]) {
    const visibleColumns = ["contactName", ...newVisibleColumns];
    if (filterView.isDefault) {
      setFilterViewOverrides((filterViewOverrides: any) => ({
        ...filterViewOverrides,
        [filterView.id]: {
          ...filterViewOverrides[filterView.id],
          visibleColumns,
        },
      }));
    } else {
      const serverFilterView = filterViews.find((fv) => fv.id === filterView.id);
      updateFilterView(filterView, {
        filter_data: {
          ...serverFilterView.filter_data,
          visible_columns: visibleColumns,
        },
      });
    }
  }

  function onFilterViewRename(filterView: FilterView, name: string) {
    updateFilterView(filterView, { name });
  }

  function onFilterViewShare(filterView: FilterView, sharingData: { id: string; type: string }[]) {
    updateFilterView(filterView, { sharing_data: sharingData });
  }

  function onFilterViewOrderChange(viewIds: string[]) {
    patchContactFilterViewOrder(viewIds).subscribe(() => {
      invalidateFilterViews();
    }, handleError);
  }

  function onFilterViewVisibilityChange({ filterView, visible }: { filterView: FilterView; visible: boolean }) {
    updateFilterView(filterView, { hidden: !visible });
  }

  function onFilterViewDuplicate(filterView: FilterView) {
    const serverFilterView = filterViews.find((fv) => fv.id === filterView.id);
    createContactFilterView({
      ...serverFilterView,
      id: null,
      name: `${serverFilterView.name} (copy)`,
    }).subscribe(() => {
      invalidateFilterViews();
    }, handleError);
  }

  function onFilterViewDelete(filterViewIds: string[]) {
    deleteFilterViews(filterViewIds).subscribe(() => {
      invalidateFilterViews();
    }, handleError);
  }

  return (
    <CustomizeFilterViewsModal
      show={show}
      onClose={({ filterViewIdsMarkedForDelete }) => {
        setShow(false);
        if (filterViewIdsMarkedForDelete.length > 0) {
          onFilterViewDelete(filterViewIdsMarkedForDelete);
        }
        onClose({ filterViewIdsMarkedForDelete });
      }}
      onAfterClose={onAfterClose}
      title="Customize Contact List"
      initialFilterView={initialFilterView}
      filterViews={formFilterViews}
      debounceOnColumnVisibilityChange={onColumnVisibilityChange}
      columns={columns}
      sections={sections}
      getIsFilterApplied={(filterView, column) => {
        return (
          filters.hasOwnProperty(column.filterFieldId) || sortData.some((sort) => sort.field === column.sortFieldId)
        );
      }}
      renderRenameDropdown={({ filterView, closeSubView }) => (
        <RenameFilterView
          filterView={filterView}
          onClose={closeSubView}
          onRename={(name) => onFilterViewRename(filterView, name)}
        />
      )}
      renderShareListDropdown={({ filterView, closeSubView }) => (
        <ShareFilterView
          filterView={filterView}
          onClose={closeSubView}
          onShare={(sharingData) => onFilterViewShare(filterView, sharingData)}
        />
      )}
      onFilterViewOrderChange={onFilterViewOrderChange}
      onFilterViewVisibilityChange={onFilterViewVisibilityChange}
      onFilterViewDuplicate={onFilterViewDuplicate}
    />
  );
}
