import React from "react";
import PropTypes from "prop-types";
import { resolution_actions, getResolutionIndex } from "../dedupe-modal.helper.js";
import { removeInUseCanopyClients, getActiveMergeCards } from "./merge-card-group.helper.js";
import MergeCard from "../merge-card/merge-card.component.js";
import ResolvedClient from "../resolved-client/resolved-client.component.js";
import styles from "./merge-card-group.styles.css";
import { isEqual } from "lodash";

export default class MergeCardGroup extends React.Component {
  static propTypes = {
    crmColumns: PropTypes.array.isRequired,
    import_row: PropTypes.object.isRequired,
    conflicting_canopy_clients: PropTypes.array.isRequired,
    resolutions: PropTypes.array.isRequired,
    addResolution: PropTypes.func.isRequired,
    deleteResolution: PropTypes.func.isRequired,
    hasResolutionForCanopyId: PropTypes.func.isRequired,
    viewportWidth: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      conflictingClients: removeInUseCanopyClients(props.conflicting_canopy_clients, props.hasResolutionForCanopyId),
      resolvedMergeCards: {},
    };
  }

  componentDidMount() {
    this.findConflicts();
  }
  componentDidUpdate(prevProps) {
    const { conflicting_canopy_clients, resolutions, import_row } = this.props;
    if (
      !isEqual(conflicting_canopy_clients, prevProps.conflicting_canopy_clients) ||
      !isEqual(resolutions, prevProps.resolutions) ||
      !isEqual(import_row, prevProps.import_row)
    ) {
      this.findConflicts();
    }
  }

  findConflicts() {
    const { conflicting_canopy_clients, hasResolutionForCanopyId, resolutions, import_row } = this.props;
    const { resolvedMergeCards } = this.state;

    // Filter out the canopy clients that are currently in use
    const conflictingClients = removeInUseCanopyClients(conflicting_canopy_clients, hasResolutionForCanopyId);

    if (!isEqual(conflictingClients, this.state.conflictingClients)) {
      this.setState({ conflictingClients });
    }

    // Set a resolution for rows that have no remaining merge options
    const rowHasResolution = ~getResolutionIndex(resolutions, import_row.row_id);
    const remainingMergeOptions = conflictingClients.filter(
      (conflictingClient) => !resolvedMergeCards[conflictingClient.id]
    );
    if (!rowHasResolution && remainingMergeOptions.length === 0) {
      this.keepSeparate();
    }
  }

  render() {
    const { crmColumns, import_row, resolutions, addResolution, deleteResolution, viewportWidth } = this.props;
    const { conflictingClients, resolvedMergeCards } = this.state;

    const indexOfResolution = getResolutionIndex(resolutions, import_row.row_id);

    if (~indexOfResolution) {
      //Show resolved clients
      return (
        <div className={`${styles.duplicate}`}>
          <ResolvedClient
            import_row={import_row}
            crmColumns={crmColumns}
            resolutions={resolutions}
            conflicting_canopy_clients={conflictingClients}
            deleteResolution={deleteResolution}
          />
        </div>
      );
    } else {
      //Show Merge Cards
      return (
        <div className={`${conflictingClients.length > 1 ? styles.duplicateSet : styles.duplicate}`}>
          {conflictingClients.map((duplicate) =>
            !resolvedMergeCards[duplicate.id] ? (
              <MergeCard
                key={duplicate.id}
                crmColumns={crmColumns}
                duplicateSet={[import_row, duplicate]}
                matchingColumns={duplicate.matching_columns}
                conflictingColumns={duplicate.conflicting_columns}
                resolveConflicts={addResolution}
                keepSeparate={this.disableCard.bind(this, duplicate.id)}
                viewportWidth={viewportWidth}
              />
            ) : (
              <ResolvedClient
                key={duplicate.id}
                import_row={import_row}
                crmColumns={crmColumns}
                resolutions={[{ ...import_row, action: resolution_actions.new }]}
                conflicting_canopy_clients={[duplicate]}
                deleteResolution={this.disableCard.bind(this, duplicate.id, false)}
              />
            )
          )}
        </div>
      );
    }
  }

  disableCard = (duplicateId, shouldDisable = true) => {
    const { conflictingClients } = this.state;
    const resolvedMergeCards = { ...this.state.resolvedMergeCards };

    resolvedMergeCards[duplicateId] = shouldDisable;
    if (getActiveMergeCards(conflictingClients, resolvedMergeCards).length === 0) {
      //If there are no active merge cards left, we keep the clients separate
      this.keepSeparate();
    } else {
      //otherwise disable the card
      this.setState({ resolvedMergeCards });
    }
  };

  keepSeparate = () => {
    const { addResolution, import_row } = this.props;
    addResolution({ ...import_row, action: resolution_actions.new });
  };
}
