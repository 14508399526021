import React from "react";
import { CpWell, CpToggle } from "canopy-styleguide!sofe";

interface ActiveClientToggleProps {
  className?: string;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
}

export function ActiveClientToggle({ className, disabled, value, onChange }: ActiveClientToggleProps) {
  return (
    <CpWell level={2} className={`${className || ""} cp-p-16 cp-pt-24`}>
      <div className="flex justify-between">
        <div className="cp-pt-4 cp-mb-4">
          <label className="cp-wt-semibold">Active</label>
        </div>
        <CpToggle checked={value} disabled={disabled} onChange={onChange} />
      </div>
      <div>
        When turned off, client functionality is reduced to client record information, notes, and email sync. Client
        portal access will be deactivated. Only active clients count towards your client billing tier.
      </div>
    </CpWell>
  );
}
