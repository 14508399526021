import React from "react";
import { InputDecorator } from "src/forms-lib";
import { Scoped } from "kremling";
import styles from "./tags-section.krem.css";
import { TagsSelect } from "src/common/form/client/base";

class TagsSection extends React.Component {
  render() {
    return (
      <Scoped css={styles}>
        <div className="multiselectDiv">
          <TagsSelect value={this.props.value} onChange={this.props.updateValue} />
        </div>
      </Scoped>
    );
  }
}

export default InputDecorator(TagsSection);
