import * as React from "react";
import { CpButton, CpTooltip } from "canopy-styleguide!sofe";

export default function Star(props) {
  return (
    <CpTooltip text={props.html}>
      <CpButton
        type="button"
        icon={props.isPrimary ? "shape-star-filled" : "shape-star-open"}
        onClick={props.setPrimary}
        className={props.isPrimary ? "cps-color-yellow" : "cps-color-monsoon"}
        aria-label={props.html}
      />
    </CpTooltip>
  );
}
