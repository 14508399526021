import React, { useMemo } from "react";
import { featureEnabled } from "feature-toggles!sofe";
import { CpIcon, CpDropdown, CpButton, CpTooltip } from "canopy-styleguide!sofe";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";

type ActionItemListProps<T extends ActionItem> = {
  actionItems: T[];
  actionItemOrder?: string[];
  maxVisibleActions?: number;
  onActionClick: (actionItem: T) => void;
};

type ActionItem = {
  id: string;
  label: string;
  icon: string;
  permissions?: string[];
};

export function ActionItemList<T extends ActionItem>({
  actionItems,
  actionItemOrder,
  maxVisibleActions = featureEnabled("toggle_gs_bulk_invoices") ? 6 : 5,
  onActionClick,
}: ActionItemListProps<T>) {
  const [user] = useWithUserAndTenant();
  const availableActions = useMemo(() => {
    return actionItems
      .filter((actionItem) => {
        return !actionItem.permissions || hasAccess(user, true)(actionItem.permissions);
      })
      .sort((a, b) => {
        if (!actionItemOrder) return 0;
        return actionItemOrder.indexOf(a.id) - actionItemOrder.indexOf(b.id);
      });
  }, [actionItems, actionItemOrder, user]);

  const buttonActions = availableActions.slice(0, maxVisibleActions);
  const kabobActions = availableActions.slice(maxVisibleActions);

  if (!user) return null;
  return (
    <div>
      {buttonActions.map((action) => (
        <CpTooltip text={action.label} key={action.id}>
          <CpButton
            btnType="icon"
            icon={action.icon}
            aria-label={action.label}
            className="cp-mr-8"
            onClick={() => onActionClick(action)}
          />
        </CpTooltip>
      ))}
      {kabobActions.length > 0 && (
        <CpDropdown
          contentWidth={200}
          renderTrigger={({ toggle }: any) => (
            <CpTooltip text="More actions">
              <CpButton icon="misc-kabob" btnType="icon" aria-label="More actions" onClick={toggle} />
            </CpTooltip>
          )}
          renderContent={() => (
            <div className="cp-select-list">
              {kabobActions.map((action) => (
                <button key={action.id} onClick={() => onActionClick(action)}>
                  <CpIcon name={action.icon} className="cp-select-list__icon-left" />
                  {action.label}
                </button>
              ))}
            </div>
          )}
        />
      )}
    </div>
  );
}
