// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-create-edit-client-old-create-edit-client-modal-create-edit-client-modal-styles__clientLimit {
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--cps-color-monsoon);
}

.src-create-edit-client-old-create-edit-client-modal-create-edit-client-modal-styles__modalBody {
  padding: 0 !important;
}

.src-create-edit-client-old-create-edit-client-modal-create-edit-client-modal-styles__integrationToggleSection {
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.6rem;
  margin: 1.6rem 3.2rem 0rem;
}
`, "",{"version":3,"sources":["webpack://./src/create-edit-client-old/create-edit-client-modal/create-edit-client-modal.styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":[".clientLimit {\n  font-size: 1.3rem;\n  font-weight: bold;\n  color: var(--cps-color-monsoon);\n}\n\n.modalBody {\n  padding: 0 !important;\n}\n\n.integrationToggleSection {\n  padding: 1.6rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 6.6rem;\n  margin: 1.6rem 3.2rem 0rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientLimit": `src-create-edit-client-old-create-edit-client-modal-create-edit-client-modal-styles__clientLimit`,
	"modalBody": `src-create-edit-client-old-create-edit-client-modal-create-edit-client-modal-styles__modalBody`,
	"integrationToggleSection": `src-create-edit-client-old-create-edit-client-modal-create-edit-client-modal-styles__integrationToggleSection`
};
export default ___CSS_LOADER_EXPORT___;
