import { createContext, useMemo } from "react";
import { isEqual, isEmpty, differenceWith, toPairs } from "lodash";
import { track } from "src/resources/analytics.resource";

export const FilterContext = createContext({
  filters: () => {},
  setFilters: () => {},
  sortData: () => {},
  setSortData: () => {},
});

export function getNewFiltersCount({ filters, sortData, filterView, filterViewOverrides }) {
  if (!filterView) return 0;

  const appliedFilters = Object.keys(filters).filter((name) => filters[name]?.length > 0);
  const filterFieldsToCheck = new Set([
    ...appliedFilters,
    ...Object.keys(filterView.filter_data?.filters || {}),
    ...(filterView?.sort_data?.map((d) => d.field) || []),
    ...sortData.map((d) => d.field),
  ]);
  const newFilters = new Set(); // A list of filter fields that differ from the filter view

  filterFieldsToCheck.forEach((name) => {
    const filterViewFilter = filterView.filter_data?.filters?.[name] || [];
    const filter = filters[name] || [];
    if (!isEqual(filter, filterViewFilter)) {
      newFilters.add(name);
    }

    const filterViewSortData = filterView.filter_data?.sort_data?.find((d) => d.field === name && !!d.sort) || {};
    const fieldSortData = sortData.find((d) => d.field === name && !!d.sort) || {};

    if (!isEqual(fieldSortData, filterViewSortData)) {
      newFilters.add(name);
    }
  });

  const newColumnOrder = !!filterViewOverrides[filterView.id]?.column_order;
  const newVisibleColumns = !!filterViewOverrides[filterView.id]?.visible_columns;
  if (newColumnOrder) newFilters.add("column_order");
  if (newVisibleColumns) newFilters.add("visible_columns");

  return newFilters.size;
}

export function useFilterContextValues({ filters, sortData, setFilters, setSortData }) {
  return useMemo(
    () => ({
      getFilter: (filterField) => filters[filterField],
      setFilter: (filterField, filterData, columnSchema) =>
        setFilters((prev) => {
          const newFilters = { ...prev };
          if (isEmpty(filterData)) {
            delete newFilters[filterField];
          } else {
            if (!!columnSchema.customField) {
              const type = columnSchema.customField.field_type;
              filterData = filterData.map((data) => ({
                ...data,
                field_type: type === "multiselect" ? "dropdown" : type,
              }));
            }
            newFilters[filterField] = filterData;
          }
          const appliedFilter = differenceWith(toPairs(newFilters), toPairs(prev), isEqual)[0];
          if (appliedFilter) {
            track("client_list.filter-applied", {
              field: appliedFilter[0],
              value: appliedFilter[1],
            });
          }
          return newFilters;
        }),
      getSort: (sortField) => sortData.find((el) => el.field === sortField) || null,
      setSort: (sortObj) =>
        setSortData((prev) => {
          const i = prev.findIndex((el) => el.field === sortObj.field);
          const newSortData = [...prev];
          if (i < 0 && !!sortObj.sort) {
            newSortData.push(sortObj);
          } else if (i >= 0 && !!sortObj.sort) {
            newSortData[i] = sortObj;
          } else if (i >= 0 && !sortObj.sort) {
            newSortData.splice(i, 1);
          }
          const appliedSort = differenceWith(newSortData, prev, isEqual)[0];
          if (appliedSort) {
            track("client_list.sort-applied", {
              field: appliedSort.field,
              sort: appliedSort.sort,
            });
          }
          return newSortData;
        }),
    }),
    [filters, sortData, setFilters, setSortData]
  );
}
