import React, { useState } from "react";
import { CpLabel, CpSelectSingleField } from "canopy-styleguide!sofe";
import useTeamMembersQuery from "src/common/queries/use-team-members-query.hook";
import { Control } from "react-hook-form";

type TTeamMember = {
  archived_at: string;
  deleted_at: string;
  email: string;
  entity_id: string;
  first_name: string;
  has_profile_picture: boolean;
  id: string;
  initials: string;
  is_activated: boolean;
  last_name: string;
  name: string;
  profile_name: string;
  profile_picture_url?: string;
  role: string;
  tenant_id: string;
};
type ClientOwnerSelectFieldProps = {
  control: Control<any>;
  fieldName: string;
};
export function ClientOwnerSelectField({ control, fieldName }: ClientOwnerSelectFieldProps) {
  const { teamMembers } = useTeamMembersQuery();
  const [search, setSearch] = useState("");

  return (
    <CpLabel>
      Client owner
      <CpSelectSingleField
        control={control}
        fieldName={fieldName}
        clearable
        data={teamMembers}
        label="Client owner"
        searchFilter
        searchOnChange={setSearch}
        searchValue={search}
        teamMember
        triggerIsBlock
        transformData={(tm: TTeamMember) => ({
          ...tm,
          name: tm.profile_name || tm.name,
          subName: tm.email,
          namechip: {
            name: tm.name,
            imgSrc: tm.profile_picture_url,
          },
        })}
      />
    </CpLabel>
  );
}
