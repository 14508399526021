import { useEffect, useMemo, useRef } from "react";

export function useSubView({ routeId, onSubViewEnter, onSubViewExit }) {
  const subView = useMemo(() => {
    switch (routeId) {
      case "inactive":
        return {
          id: "inactive",
          name: "Inactive Clients",
          clientActionName: "Activate",
          filter_data: {
            filters: {
              is_active: [{ equal_to: false }],
              is_archived: [{ equal_to: false }],
            },
            sort_data: [],
          },
        };
      case "archived":
        return {
          id: "archived",
          name: "Archived Clients",
          clientActionName: "Unarchive",
          filter_data: {
            filters: {
              is_archived: [{ equal_to: true }],
            },
            sort_data: [],
          },
        };
      default:
        return null;
    }
  }, [routeId]);

  const subViewRef = useRef();
  const onSubViewEnterRef = useRef();
  const onSubViewExitRef = useRef();
  onSubViewEnterRef.current = onSubViewEnter;
  onSubViewExitRef.current = onSubViewExit;
  useEffect(() => {
    if (subView) {
      subViewRef.current = subView;
      onSubViewEnterRef.current?.(subView);
    } else if (subViewRef.current) {
      subViewRef.current = null;
      onSubViewExitRef.current?.();
    }
  }, [subView]);

  return subView;
}
