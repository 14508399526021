import React from "react";
import ReactPlayer from "react-player";
import { CpButton, CpIcon } from "canopy-styleguide!sofe";
import { cssModules, useCssModules } from "inline-css-modules-react";
export type MigrationGuideContentProps = {
  children?: React.ReactNode;
  title: string;
  kbLink?: string;
  videoSrc?: string;
  warning?: boolean;
};

export function MigrationGuideContent({
  children,
  title,
  kbLink,
  videoSrc,
  warning = false,
}: MigrationGuideContentProps) {
  useCssModules(css);

  return (
    <div className="flex flex-row cp-gap-16">
      <div className="flex flex-col cp-gap-8">
        <div
          className={`flex flex-row justify-between items-center cp-body cp-wt-semibold ${
            warning ? "cp-color-app-warning-text" : ""
          }`}
        >
          <span>
            {title}
            {warning && <CpIcon name="urgent-filled-small" />}
          </span>
          {kbLink && (
            <CpButton
              icon="misc-grad-cap"
              anchor
              href={kbLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`${title} Knowledge Base`}
            />
          )}
        </div>
        <div className="flex flex-col cp-gap-8">{children}</div>
      </div>
      {videoSrc && (
        <div className={s.playerContainer}>
          <ReactPlayer controls url={videoSrc} width="32.8rem" height="18.5rem" />
        </div>
      )}
    </div>
  );
}
const { s, css } = cssModules`
  .playerContainer {
    position: relative;
    min-width: 32.8rem;
    height: 18.5rem;
    border-radius: 0.8rem;
    overflow: hidden;
  }
`;
