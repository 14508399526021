import * as React from "react";
import Input from "src/form/input/input.component";
import { Scoped } from "kremling";
import styles from "./personal-information-section.krem.css";
import MaskedInput from "src/form/masked-input/masked-input.component";
import DateInput from "src/form/date-input/date-input.component";
import { FieldLabel } from "src/common/form/field-label.component";

export default class PersonalInformationSection extends React.Component {
  state = {};

  static defaultProps = {
    cancelWhenUnmounted: () => {},
  };

  render() {
    return (
      <Scoped css={styles}>
        <div className="PersonalInformationRow margin-between">
          <DateInput
            name="birth_date"
            label={
              <FieldLabel>
                <span>Birth date</span>
              </FieldLabel>
            }
          />
          <div className="fs-mask">
            <FieldLabel>SSN/ITIN</FieldLabel>
            <MaskedInput
              className="fs-exclude"
              name="ssn"
              inputProps={{
                autoComplete: "off",
                htmlFor: `SSN`,
                name: `SSN`,
              }}
              nonValidMsg="SSN should be 9 numberic characters"
              validationFn={this.validateSSN}
              filler="#"
              masks={[
                {
                  condition: (value) => value.length <= 9,
                  pattern: "111-11-1111",
                },
                {
                  condition: (value) => value.length > 9,
                  pattern: "111111111",
                },
              ]}
            />
          </div>
        </div>
        <div className="PersonalInformationRow margin-between">
          <Input type="text" placeholder="Occupation" name="occupation" label={this.getLabel("Occupation")} />
          <Input type="text" placeholder="Employer" name="employer" label={this.getLabel("Employer")} />
        </div>
      </Scoped>
    );
  }

  validateSSN = (value) => value.length === 9 || value === "";
  getLabel = (label) => (
    <FieldLabel>
      <span>{label}</span>
    </FieldLabel>
  );
}
