import * as React from "react";
import AsyncDecorator from "async-decorator/rx6";
// project
import { getFormatedStates } from "./states.resource.js";
import { SimpleTypeahead } from "src/form/typeahead-input/typeahead-input.component.js";
// sofe
import { CpLoader } from "canopy-styleguide!sofe";
// library
import { of } from "rxjs";
import { handleError } from "src/error";

@AsyncDecorator
export default class StateSelect extends React.Component {
  state = {
    states: [],
  };

  static defaultProps = {
    cancelWhenUnmounted: () => {},
  };

  componentDidMount() {
    getFormatedStates().subscribe((formattedStates) => {
      this.setState({ states: formattedStates });
    }, handleError);
  }

  Observable = (searchString) => {
    return of(
      this.state.states.filter((item) => {
        return (
          item.key.toLowerCase().includes(searchString.toLowerCase()) ||
          itemToString(item).toLowerCase().includes(searchString.toLowerCase())
        );
      })
    );
  };

  render() {
    const { selected, onChange, ...rest } = this.props;
    if (this.state.states.length > 0) {
      let found = this.state.states.find((state) => state.key === selected) || "";
      return (
        <SimpleTypeahead
          itemToString={itemToString}
          debounceTime={0}
          itemKey={(item) => item.key}
          items={this.state.states}
          value={found}
          emptySelectedItem={{ key: "" }}
          updateValue={(obj) => obj && onChange(obj.key)}
          placeholder="State"
          Observable={this.Observable}
          {...rest}
        />
      );
    } else {
      return <CpLoader />;
    }
  }
}

function itemToString(item) {
  return item ? item.value : "";
}
