import React from "react";
import { uniqueId } from "lodash";
import { isValidEmail } from "src/common/utils.helpers.js";
// project
import ListOfItems from "../list-of-items/list-of-items.component.js";
import Email from "./email/email.component.js";
import DeletedItem from "../list-of-items/deleted-item.component.js";
// sofe
import { CpButton } from "canopy-styleguide!sofe";

const emailOptions = [
  { value: "Personal email", key: "personal" },
  { value: "Work email", key: "work" },
  { value: "Other email", key: "other" },
];

const arbitraryMaxCount = 25;

class EmailSection extends React.Component {
  render() {
    return (
      <div>
        <ListOfItems
          initialValue={[this.getEmptyEmail()]}
          name="email_addresses"
          getEmptyItem={this.getEmptyEmail}
          validationFn={this.validationFn}
          maxCount={arbitraryMaxCount}
          item={({ item, addRef, removeRef, removeItemById, showInvalid, setPrimaryById, updateItem, itemsLength }) => {
            return (
              <div className="ListItem" key={item.id}>
                <Email
                  ref={(el) => addRef(el)}
                  name={item.id}
                  email={item}
                  removeRef={removeRef}
                  showActions={itemsLength >= 2}
                  removeEmail={removeItemById}
                  setPrimaryById={setPrimaryById}
                  updateEmail={updateItem}
                  options={emailOptions}
                  showInvalid={showInvalid}
                />
              </div>
            );
          }}
          deleted={({ item, deleteItem, cancelPendingDelete }) => {
            return (
              <DeletedItem
                key={item.id}
                item={item}
                deleteItem={deleteItem}
                cancelPendingDelete={cancelPendingDelete}
                itemDescription={"Email address"}
              />
            );
          }}
          button={({ allItems, addItem, disabledButton }) => {
            return (
              <CpButton type="button" btnType="flat" onClick={addItem} disabled={disabledButton}>
                + Add email
              </CpButton>
            );
          }}
        />
      </div>
    );
  }

  getEmptyEmail = () => {
    return {
      id: uniqueId("create_email"),
      address: "",
      type: emailOptions[0].key,
      is_primary: false,
    };
  };

  validationFn = (value) => {
    const isValid = value.reduce((acc, em) => {
      if (em.address && em.address.length !== 0) {
        return isValidEmail(em.address) && acc;
      }
      return true && acc;
    }, true);
    return isValid;
  };
}

export default EmailSection;
