import React, { useMemo } from "react";
import { Control, UseControllerProps, FieldValues } from "react-hook-form";
import { CpSelectSingleField, CpLabel } from "canopy-styleguide!sofe";
import { useClientNamesQuery } from "src/common/queries/use-client-names-query.hook";
import { useDebouncedSearch } from "src/common/use-debounced-search.hook";
import { ClientName } from "src/resources/clients.resource";

type ClientSelectFieldProps<T extends FieldValues> = {
  control: Control<T>;
  controllerOptions?: Omit<UseControllerProps, "name">;
  disabled?: boolean;
  fieldName: string;
  label?: string;
  required?: boolean;
  filterClients?: (clients: any[]) => any[];
  showIsBusinessSubName?: boolean;
};

export function ClientSelectField<T extends FieldValues>({
  control,
  controllerOptions,
  disabled,
  fieldName,
  label,
  required = false,
  filterClients,
  showIsBusinessSubName = false,
}: ClientSelectFieldProps<T>) {
  const { search, debouncedSearch, updateSearch } = useDebouncedSearch();
  const { clientNames } = useClientNamesQuery({ search: debouncedSearch });

  const filteredClients = useMemo(() => {
    if (filterClients && clientNames) {
      return filterClients(clientNames) || [];
    } else {
      return clientNames || [];
    }
  }, [clientNames, filterClients]);

  return (
    <div>
      <CpLabel required={required}>{label}</CpLabel>
      <CpSelectSingleField
        triggerIsBlock
        required={required}
        searchFilter
        searchValue={search}
        searchOnChange={updateSearch}
        fieldName={fieldName}
        control={control}
        controllerOptions={controllerOptions}
        data={filteredClients}
        transformData={(clientName: ClientName) => ({
          ...clientName,
          subName: showIsBusinessSubName ? (clientName.is_business ? "Business" : "Individual") : undefined,
        })}
        disabled={disabled}
      />
    </div>
  );
}
