import React from "react";
import { CpLoader } from "canopy-styleguide!sofe";

export default function ClientCardLoader() {
  return (
    <div className="cps-margin-top-32">
      <CpLoader size="lg" />
    </div>
  );
}
