import React from "react";
import { Control } from "react-hook-form";
import { MaskedInputField } from "src/common/form/masked-input-field.component";

type SsnFieldProps = {
  control: Control<any>;
  label?: string;
  required?: boolean;
};

export function SsnField({ label = "SSN", control, required }: SsnFieldProps) {
  return (
    <MaskedInputField
      label={label}
      className="fs-exclude"
      control={control}
      required={required}
      fieldName="ssn"
      nonValidMsg="SSN should be 9 numberic characters"
      validateFn={(value) => !value?.length || value?.length === 9}
      masks={[
        {
          condition: (value: string[]) => value.length <= 9,
          pattern: "111-11-1111",
        },
        {
          condition: (value: string[]) => value.length > 9,
          pattern: "111111111",
        },
      ]}
    />
  );
}
