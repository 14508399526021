import React from "react";
import { Control } from "react-hook-form";
import { CpInputField } from "canopy-styleguide!sofe";
import { MultiEmailField } from "./multi-email-field.component";
import { MultiPhoneField } from "./multi-phone-field.component";
import { MultiAddressField } from "./multi-address-field.component";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { DateInputField } from "src/common/form/date-input-field.component";
import { ContactFirstNameField } from "./contact-first-name-field.component";
import { ContactLastNameField } from "./contact-last-name-field.component";
import { SsnField } from "../client/ssn-field.component";

type ContactInfoFieldsProps = {
  control: Control<any>;
  watch: (field: string) => any;
};

export function ContactInfoFields({ control, watch }: ContactInfoFieldsProps) {
  useCssModules(css);
  return (
    <div className="flex flex-col cp-gap-24">
      <div className="flex flex-row justify-stretch cp-gap-16">
        <div className="grow">
          <ContactFirstNameField control={control} autoFocus={true} />
        </div>
        <div className="grow">
          <CpInputField
            fieldName="middle_name"
            control={control}
            label="Middle Name"
            placeholder="Middle Name"
            maxLength={150}
          />
        </div>
        <div className="grow">
          <ContactLastNameField control={control} />
        </div>
      </div>
      <MultiEmailField fieldName="emails" control={control} watch={watch} />
      <MultiPhoneField fieldName="phones" control={control} watch={watch} />
      <MultiAddressField fieldName="addresses" control={control} watch={watch} />
      <div className={s.grid}>
        <DateInputField control={control} fieldName="birthdate" label="Birthdate" />
        <SsnField control={control} />
        <CpInputField fieldName="occupation" control={control} label="Occupation" maxLength={150} />
        <CpInputField fieldName="employer" control={control} label="Employer" maxLength={150} />
      </div>
    </div>
  );
}

const { css, s } = cssModules`
  .grid {
    display: grid;
    gap: 2.4rem;
    grid-template-columns: 1fr 1fr;
  }
`;
