// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-contact-list-contact-list-styles__contactListContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--cp-color-well-l1-bg);
  padding: 0;
}

.src-contact-list-contact-list-styles__topBar {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem;
  padding-right: 2.4rem;
}

.src-contact-list-contact-list-styles__header {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/contact-list/contact-list.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,sCAAsC;EACtC,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".contactListContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  background: var(--cp-color-well-l1-bg);\n  padding: 0;\n}\n\n.topBar {\n  display: flex;\n  justify-content: space-between;\n  padding: 1.2rem;\n  padding-right: 2.4rem;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactListContainer": `src-contact-list-contact-list-styles__contactListContainer`,
	"topBar": `src-contact-list-contact-list-styles__topBar`,
	"header": `src-contact-list-contact-list-styles__header`
};
export default ___CSS_LOADER_EXPORT___;
