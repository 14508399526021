import { featureEnabled } from "feature-toggles!sofe";
import { useBetas, useWithUserAndTenant } from "cp-client-auth!sofe";

export function useShowCrmMigration() {
  const [, tenant] = useWithUserAndTenant();
  const hierarchyComplete = tenant.crm_status === "crm_hierarchy_complete";
  const hasCrmMigrationBeta = useBetas("crm_migration");
  const crmMigrationEnabled = featureEnabled("ft_crm_migration");
  const showCrmMigration = crmMigrationEnabled && hasCrmMigrationBeta && !hierarchyComplete;

  return { showCrmMigration };
}
