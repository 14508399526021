import React from "react";
import { useHasAccess } from "cp-client-auth!sofe";
// project
import { useFormInput } from "src/forms-lib";
import { notifyAnalytics } from "src/resources/analytics.resource";
import { ActiveClientToggle } from "src/common/form/client/base";

export default function ActiveClient(props) {
  const canToggle = useHasAccess("clients_toggle_active");

  const [clientActive, updateClientActive] = useFormInput({
    name: props.name,
    initialValue: props.initialValue,
    shared: props.shareValue,
  });

  const handleClientActiveChanged = () => {
    const active = !clientActive;
    notifyAnalytics(window.loggedInUser, "active_client_toggle", { active });
    props.onToggle(active);
    updateClientActive(active);
  };

  return (
    <ActiveClientToggle
      disabled={!canToggle}
      value={clientActive}
      onChange={handleClientActiveChanged}
      className="cp-mt-16"
    />
  );
}
