import React, { useState, useEffect } from "react";
import { map } from "rxjs/operators";
import { Scoped } from "kremling";
// project
import { getSources } from "src/client-page-old/client-page.resource.js";
import styles from "./source.krem.css";
import { InputDecorator } from "src/forms-lib";
import { SimpleTypeahead } from "src/form/typeahead-input/typeahead-input.component";
import { searchClients } from "./source.resource.js";
import { SimpleSelect } from "src/form/select-input/select-input.component";
import FormErrorBoundary from "src/form/form-error-boundary/form-error-boundary.component";
import { handleError } from "src/error";
import { FieldLabel } from "src/common/form/field-label.component";

function Source(props) {
  const [options, setOptions] = useState([]);
  const [optionsMap, setOptionsMap] = useState({});
  const { value, updateValue } = props;
  useEffect(() => {
    const req$ = getSources()
      .pipe(
        map((sources) => {
          return sources.map((source) => ({ key: source.id, value: source.name, ...source }));
        })
      )
      .subscribe((mappedSources) => {
        setOptions(mappedSources);
        const map = mappedSources.reduce((acc, source) => {
          acc[source.id] = source.name;
          return acc;
        }, {});
        setOptionsMap(map);
      }, handleError);
    return () => req$.unsubscribe();
  }, []);
  return (
    <Scoped css={styles}>
      <div className="Source margin-between cps-margin-bottom-16">
        <div className="flex1">
          <FormErrorBoundary message={`Unable to show source field, we're working on a fix.`}>
            <SimpleSelect
              options={options}
              value={props.value.id}
              updateValue={(id) => updateValue(!id ? {} : { ...value, id, name: optionsMap[id] })}
              labelText={"Source"}
              clearable
            />
          </FormErrorBoundary>
        </div>
        <div className="flex1 margin-between">
          <FormErrorBoundary message={`Unable to show Referral Field, we're working on a fix.`}>
            {value.name === "Referral" && (
              <SimpleTypeahead
                value={value.selected}
                updateValue={(selection) => updateValue({ ...value, selected: selection })}
                items={value.selected && value.selected.id ? [value.selected] : undefined}
                itemToString={itemToString}
                resultFormat={formatFoundClient}
                itemKey={(item) => item.id}
                emptySelectedItem={{}}
                Observable={searchClients}
                label={
                  <FieldLabel>
                    <span>Referred by</span>
                  </FieldLabel>
                }
              />
            )}
          </FormErrorBoundary>
        </div>
      </div>
    </Scoped>
  );
}

function formatFoundClient(client) {
  const hidden = client.is_hidden;
  return (
    <div className="dropdownItem">
      <div className="dropdownItemName">{itemToString(client)}</div>
    </div>
  );
}

function itemToString(item) {
  if (item && (item.name || (item.first_name && item.last_name))) {
    return item.name || `${item.first_name} ${item.last_name}`;
  } else {
    return "";
  }
}

export default InputDecorator(Source);
