import React, { useEffect, useMemo, useState } from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { FormProvider, useForm } from "react-hook-form";
import { patchClientGroup, postClientGroupObs } from "src/resources/client-groups.resource";
import { AddToClientGroupForm } from "src/client-groups-page/client-group-modals/form/add-to-client-group-form.component";
import { handleError } from "src/error";
import { clientQueries } from "src/queries";
import { useClientsQuery } from "src/common/queries/use-clients-query.hooks";

type BulkAddToClientGroupModalProps = {
  selection: {
    type: string;
    totalSelected: number;
    toArray: () => string[];
  };
  onClose: () => void;
};

export function BulkAddToClientGroupModal({ onClose, selection }: BulkAddToClientGroupModalProps) {
  const [show, setShow] = useState<boolean>(true);
  const [action, setAction] = useState<"create" | "add">("create");
  const [saving, setSaving] = useState<boolean>(false);

  const {
    clients,
  }: {
    clients: {
      id: number;
      name: string;
      client_groups: any;
    }[];
  } = useClientsQuery({
    filters: {
      id: [{ values: selection.toArray().map((id) => parseInt(id)) }],
    },
    limit: selection.totalSelected.toString(),
    visibleColumns: ["id", "name", "client_groups"],
  });

  const { defaultClients, removedCount } = useMemo(() => {
    if (!clients) return { defaultClients: [], removedCount: 0 };
    const validClients = clients.filter((client) => !client.client_groups);
    return {
      defaultClients: validClients,
      removedCount: clients.length - validClients.length,
    };
  }, [clients]);

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      clients: defaultClients,
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = formMethods;

  useEffect(() => {
    reset({ clients: defaultClients });
  }, [defaultClients, reset]);

  function close() {
    setShow(false);
  }

  function onSave(data: any) {
    setSaving(true);
    function onSuccess(toast: string) {
      successToast(toast);
      close();
      clientQueries.invalidate();
    }

    const clientIds = data.clients.map((c: any) => c.id);
    if (action === "create") {
      postClientGroupObs({ name: data.name, clients: clientIds }).subscribe(
        () => onSuccess(`${data.name} successfully created`),
        (err: unknown) => {
          setSaving(false);
          handleError(err);
        }
      );
    } else if (action === "add") {
      patchClientGroup({ id: data.clientGroup.id, clients_to_add: clientIds }).subscribe(
        () => {
          onSuccess(`${data.clientGroup.name} successfully updated`);
        },
        (err: unknown) => {
          setSaving(false);
          handleError(err);
        }
      );
    }
  }

  return (
    <CpModal show={show} onClose={close} onAfterClose={onClose} width={600}>
      <CpModal.Header title="Client group" />
      <CpModal.Body>
        {removedCount > 0 && (
          <div className="cp-mb-16">
            {removedCount} client{removedCount > 1 ? "s have" : " has"} been removed from this bulk action as they are
            already assigned to a client group.
          </div>
        )}
        <FormProvider {...formMethods}>
          <AddToClientGroupForm action={action} setAction={setAction} showClients />
        </FormProvider>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          onClick={handleSubmit(onSave)}
          className="cp-mr-8"
          showLoader={saving}
          disabled={isSubmitting}
        >
          Save client group
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
