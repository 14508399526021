import React, { useState } from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";
import { unlinkClientPortalLoginObs } from "src/resources/client-portal-access.resource";
import { clientQueries, contactQueries } from "src/queries";
import { handleError } from "src/error";
import { TClientPortalInfo } from "../../view-contact.helpers";

type UnlinkClientPortalModalProps = {
  clientPortalInfo: TClientPortalInfo;
  contactId: string;
  contactName: string;
  onClose: () => void;
};

export function UnlinkClientPortalModal({
  clientPortalInfo,
  contactId,
  contactName,
  onClose,
}: UnlinkClientPortalModalProps) {
  const [show, setShow] = useState(true);
  const { clientPortalEmail, hasClientPortalAccess } = clientPortalInfo;
  const closeModal = () => setShow(false);
  const unlinkClientPortalLogin = () => {
    unlinkClientPortalLoginObs(contactId).subscribe(() => {
      contactQueries.invalidate();
      clientQueries.invalidate();
      closeModal();
    }, handleError);
  };
  const onCancel = () => {
    closeModal();
  };

  return (
    <CpModal show={show} onClose={closeModal} onAfterClose={onClose} width={714}>
      <CpModal.Header title="Unlink Client Portal Login" />
      <CpModal.Body>
        {hasClientPortalAccess ? (
          <>
            You are unlinking <strong>{clientPortalEmail}</strong> as {contactName}'s client portal login. You will be
            removing access to all their assigned client portals. Do you want to continue?
          </>
        ) : (
          <>
            You are unlinking <strong>{clientPortalEmail}</strong> as {contactName}'s client portal login. Do you want
            to continue?
          </>
        )}
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" className="cp-mr-8" onClick={unlinkClientPortalLogin}>
          Continue
        </CpButton>
        <CpButton btnType="flat" onClick={onCancel}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
