import React from "react";
import { CpButton, CpWell } from "canopy-styleguide!sofe";
import { formatPhoneNumber } from "src/common/utils.helpers";
import { Address } from "src/common/address.component";
import { cssModules, useCssModules } from "inline-css-modules-react";

export function ContactWell({ contact, onEdit }: { contact: any; onEdit: () => void }) {
  useCssModules(css);
  const email = contact?.primary_email || contact?.emails?.find((e: any) => e.is_primary)?.value;
  const phone = contact?.primary_phone || contact?.phones?.find((e: any) => e.is_primary)?.value;
  const address = contact?.primary_address?.value || contact?.addresses?.find((e: any) => e.is_primary)?.value;

  return (
    <CpWell level={3} className="cp-p-24">
      <div className={s.wellHeader}>
        <div className="cp-wt-semibold">{contact.name || `${contact.first_name} ${contact.last_name}`}</div>
        <CpButton btnType="flat" onClick={onEdit}>
          Edit contact
        </CpButton>
      </div>
      <div className="flex flex-col cp-gap-16">
        <div>
          <div className="cp-caption cp-color-app-secondary-text cp-mb-4">Primary Email</div>
          <div>{email || "-"}</div>
        </div>
        <div>
          <div className="cp-caption cp-color-app-secondary-text cp-mb-4">Primary Phone</div>
          <div>{formatPhoneNumber(phone) || "-"}</div>
        </div>
        <div>
          <div className="cp-caption cp-color-app-secondary-text cp-mb-4">Primary Address</div>
          <div>{address ? <Address address={address} /> : "-"}</div>
        </div>
      </div>
    </CpWell>
  );
}

const { s, css } = cssModules`
  .wellHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1rem solid var(--cp-color-app-border);
    margin-bottom: 1.6rem;
  }
`;
