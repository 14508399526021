import React, { useState } from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { ViewContactWell } from "../common/view-contact-well.component";
import { SingleItem } from "../common/single-item.component";
import { UnlinkClientPortalModal } from "./unlink-client-portal-modal.component";
import { TClientPortalInfo } from "../../view-contact.helpers";

type ClientPortalSectionProps = {
  clientPortalInfo: TClientPortalInfo;
  contactId: string;
  contactName: string;
  wellLevel?: number;
};

export function ClientPortalSection({ clientPortalInfo, contactId, contactName, wellLevel }: ClientPortalSectionProps) {
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);

  return (
    <ViewContactWell title="Client Portal" level={wellLevel}>
      <div className="cp-flex-spread">
        <SingleItem label="Client portal login" value={clientPortalInfo.clientPortalEmail} />
        <CpButton btnType="secondary" onClick={() => setShowUnlinkModal(true)}>
          Unlink login
        </CpButton>
      </div>
      {showUnlinkModal && (
        <UnlinkClientPortalModal
          clientPortalInfo={clientPortalInfo}
          contactId={contactId}
          contactName={contactName}
          onClose={() => setShowUnlinkModal(false)}
        />
      )}
    </ViewContactWell>
  );
}
