import * as React from "react";
import { CpWell } from "canopy-styleguide!sofe";
import FormErrorBoundary from "../form-error-boundary/form-error-boundary.component.js";

type Props = {
  showHr: boolean;
  children: React.ReactNode;
};

export default function FormBlock(props: Props) {
  return (
    <CpWell className="cp-mh-36 cp-mv-24 cp-p-24">
      <FormErrorBoundary message={`Unable to show form section, we're working on a fix.`}>
        {props.children}
      </FormErrorBoundary>
    </CpWell>
  );
}

FormBlock.defaultProps = {
  showHr: true,
};
