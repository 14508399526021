import React from "react";
import { ReplaySubject } from "rxjs";
import FormContext from "./form.context";
import { registerRef, deregisterRef } from "./helpers.js";
import { set } from "lodash";

export default class Form extends React.Component {
  static defaultProps = {
    preservedValues: [],
    sharedValues: [],
  };

  constructor(props) {
    super(props);
    // initialization
    this.state = {
      showInvalid: false,
    };
    this.optionalFormState = {};
    this.optionalFormStateSubject$ = new ReplaySubject(1);
    this.optionalFormState$ = this.optionalFormStateSubject$.asObservable();
    this.fieldRefs = [];
    this.optionalFormState = {};
    this.initializeSharedValues();
  }

  render() {
    const { onSubmit, noValidate, className, children, initialValue, preservedValues, sharedValues, ...rest } =
      this.props;
    let mySharedValues = sharedValues;
    if (typeof sharedValues === "string") {
      mySharedValues = [sharedValues];
    }

    return (
      <FormContext.Provider
        value={{
          registerRef: this.registerRef,
          deregisterRef: this.deregisterRef,
          showInvalid: this.state.showInvalid,
          updateSharedValue: this.updateSharedValue,
          sharedStateObs: this.optionalFormState$,
          initialValue: initialValue,
          sharedValues: mySharedValues,
        }}
      >
        <form
          className={className}
          ref={(el) => (this.form = el)}
          noValidate={noValidate !== undefined ? noValidate : true}
          onSubmit={this.handleSubmit}
          {...rest}
        >
          {children}
        </form>
      </FormContext.Provider>
    );
  }

  componentWillUnmount() {
    this.fieldRefs = [];
  }

  initializeSharedValues = () => {
    let { sharedValues = [], initialValue = {} } = this.props;
    if (typeof sharedValues === "string") {
      sharedValues = [sharedValues];
    }
    sharedValues.forEach((value) => {
      const sharedState = {};
      const actualValue = initialValue[value];
      if (actualValue !== undefined) {
        sharedState[value] = initialValue[value];
        this.updateSharedValue(sharedState);
      }
    });
  };

  updateSharedValue = (sharedState, updateValuesToMatch = false) => {
    this.optionalFormState = { ...this.optionalFormState, ...sharedState };
    this.optionalFormStateSubject$.next({
      ...this.optionalFormState,
      ...(updateValuesToMatch ? { updateValuesToMatch: true } : {}),
    });
  };

  manualSubmit = () => {
    return new Promise((resolve, reject) => {
      try {
        this.setState({ showInvalid: true }, () => {
          const isFormValid = this.fieldRefs.reduce((acc, fieldRef) => {
            return this.isRefValid(fieldRef) && acc;
          }, true);
          const preserved = this.props.preservedValues.reduce((acc, valueString) => {
            if (this.props.initialValue && this.props.initialValue[valueString]) {
              acc[valueString] = this.props.initialValue[valueString];
            }
            return acc;
          }, {});
          const model = this.fieldRefs.reduce((acc, fieldRef) => {
            set(acc, fieldRef.getName(), fieldRef.getValue());
            return acc;
          }, {});
          if (!isFormValid) {
            this.focusFirstInvalid();
          }
          const results = [isFormValid, { ...preserved, ...model }];
          resolve(results);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.manualSubmit().then(([isFormValid, model]) => {
      this.props.onSubmit(isFormValid, model);
    });
  };

  registerRef = (refs) => {
    this.fieldRefs = registerRef(refs, this.fieldRefs);
  };

  deregisterRef = (refs) => {
    this.fieldRefs = deregisterRef(refs, this.fieldRefs);
  };

  focusFirstInvalid = () => {
    if (this.fieldRefs.length > 0) {
      this.focusByRegistrationOrder();
    }
  };

  focusByRegistrationOrder = () => {
    const firstInvalidRef = this.fieldRefs.find((ref) => {
      return !this.isRefValid(ref);
    });
    if (firstInvalidRef) {
      this.focusRef(firstInvalidRef);
    }
  };

  focusRef = (ref) => {
    if (ref.focus) {
      ref.focus();
    }
  };

  isRefValid = (ref) => {
    if (ref.isInvalid) {
      return !ref.isInvalid();
    } else {
      return true;
    }
  };
}
