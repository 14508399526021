import React from "react";
import { ViewContactWell } from "./common/view-contact-well.component";
import { Accordion } from "./common/accordion.component";
import { SingleItem } from "./common/single-item.component";
import { TContactInfo } from "../view-contact.helpers";

type ContactInfoSectionProps = {
  contactInfo: TContactInfo;
};

export function ContactInfoSection({ contactInfo }: ContactInfoSectionProps) {
  return (
    <ViewContactWell title="Contact Info">
      <div className="cp-flex-column cp-gap-16">
        {Object.entries(contactInfo).map(([key, item]) => {
          if (!item) return null;
          if (Array.isArray(item)) {
            return <Accordion key={key} values={item} />;
          } else {
            return <SingleItem key={key} label={item.label} value={item.value} />;
          }
        })}
      </div>
    </ViewContactWell>
  );
}
