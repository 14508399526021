import React, { ReactNode, useMemo, useState } from "react";
import { ActionItemList } from "src/common/client-contact/action-item-list";
import { ArchiveContactModal } from "./archive-contact-modal";
import { featureEnabled } from "feature-toggles!sofe";
import { handleError } from "src/error";
import { contactQueries } from "src/queries";

const bulkActions = [
  ...(featureEnabled("ft_crm_bulk_email_contacts")
    ? [
        {
          id: "email",
          label: "Email",
          icon: "communication-envelope",
          permissions: ["clients_bulk_email"],
          execute: ({ search, filters, selection }: { search: string; filters: any; selection: any }) => {
            const params = {
              crm_type: "contacts",
              v2Filters: { [selection.type]: selection.toArray(), filters, search },
            };
            SystemJS.import("communications-ui!sofe")
              .then((communicationsUI: any) => {
                communicationsUI.sendBulkEmail(params).subscribe((emailAction: any) => {
                  if (emailAction.updatedContacts) {
                    contactQueries.invalidate();
                  }
                }, handleError);
              })
              .catch(handleError);
          },
        },
      ]
    : []),
  ...(featureEnabled("ft_archive_contacts")
    ? [
        {
          id: "archive",
          label: "Archive",
          icon: "crud-archive",
          permissions: ["contacts_archive"],
          renderModal: ({
            search,
            filters,
            selection,
            closeModal,
          }: {
            search: string;
            filters: any;
            selection: any;
            closeModal: () => void;
          }) => {
            return (
              <ArchiveContactModal search={search} filters={filters} selection={selection} onAfterClose={closeModal} />
            );
          },
        },
      ]
    : []),
];

type BulkActionsProps = {
  allowedActions?: string[];
  filters: any;
  selection: any;
  search: string;
};

export function BulkActions({ allowedActions, filters, search, selection }: BulkActionsProps) {
  const filteredActions = useMemo(() => {
    if (!allowedActions) return bulkActions;
    return bulkActions.filter((actionItem) => {
      return allowedActions?.includes(actionItem.id);
    });
  }, [allowedActions]);
  const [renderModal, setRenderModal] = useState<() => ReactNode>();
  return (
    <>
      <ActionItemList
        actionItems={filteredActions}
        onActionClick={(actionItem) => {
          if (actionItem.execute) {
            actionItem.execute({ search, filters, selection });
          }

          if (actionItem.renderModal) {
            setRenderModal(
              () => () =>
                actionItem.renderModal({ search, filters, selection, closeModal: () => setRenderModal(undefined) })
            );
          }
        }}
      />
      {renderModal?.()}
    </>
  );
}
