import React, { useState } from "react";
import { CpModal, CpButton, CpSelectSingle } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";

import { patchClients } from "src/client-list/client-list.resource";
import { handleError } from "src/error";
import useTeamMembersQuery from "src/common/queries/use-team-members-query.hook";
import { clientQueries } from "src/queries";

export default function BulkAssignClientOwnerModal({ onClose, filters, selection, search: clientListSearch }) {
  const [show, setShow] = useState(true);
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [teamMembersSearch, setTeamMembersSearch] = useState("");
  const [search, setSearch] = useState("");

  const { teamMembers } = useTeamMembersQuery();

  function assignClientOwner() {
    patchClients({
      search: clientListSearch,
      filters,
      action: "assign_client_owner",
      client_owner_id: selectedTeamMember?.id || "",
      [selection.type]: selection.toArray(),
    }).subscribe(() => {
      const count = selection.totalSelected;
      if (selectedTeamMember) {
        successToast(
          `${selectedTeamMember.name} has been assigned as client owner to ${count} client${count > 1 ? "s" : ""}.`
        );
      } else {
        successToast(`Client owner has been unassigned to ${count} client${count > 1 ? "s" : ""}.`);
      }
      clientQueries.invalidate();
    }, handleError);
    close();
  }

  function close() {
    setShow(false);
  }

  return (
    <CpModal show={show} onClose={close} onAfterClose={onClose}>
      <CpModal.Header title="Assign Client Owner" />
      <CpModal.Body>
        <div>Add client owner</div>
        <CpSelectSingle
          clearable
          data={teamMembers}
          onChange={setSelectedTeamMember}
          placeholder="Search Team Members"
          searchFilter
          searchOnChange={setSearch}
          searchValue={search}
          teamMember
          triggerIsBlock
          value={selectedTeamMember}
          transformData={(tm) => ({
            ...tm,
            name: tm.profile_name || tm.name,
            subName: tm.name,
            namechip: {
              name: tm.name,
              imgSrc: tm.profile_picture_url,
            },
          })}
        />
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={assignClientOwner} className="cp-mr-8" disabled={!selectedTeamMember}>
          Apply
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
