import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { CpButton, CpModal } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";

import useUserPrefsQuery from "src/common/queries/use-user-prefs-query.hook";
import { MigrationGuideContent } from "./common/migration-guide-content.component";

export const MigrationCompleteModal = ({ onAfterClose }: { onAfterClose: () => void }) => {
  const { updateUserPrefs } = useUserPrefsQuery();
  const [show, setShow] = useState(true);
  const close = () => {
    setShow(false);
    updateUserPrefs({
      crmMigrationCompleteModalSeen: true,
    });
  };
  const isAdmin = useHasAccess("crm_migration_access");

  return (
    <CpModal show={show} onClose={close} onAfterClose={onAfterClose} width={isAdmin ? 712 : 831}>
      <CpModal.Header title={isAdmin ? "Migration Complete" : "New Client and Contact Structure"} />
      <CpModal.Body>
        {isAdmin ? (
          "Your client and contact restructure migration is complete! You now have the ability to add contacts to your clients and create client groups. We have also emailed you a list of all your previously linked clients and pending client portal invites. As this structure is new, we would love any feedback you have. Please feel free to submit feedback right here on Canopy or call 1-855-616-7305."
        ) : (
          <MigrationGuideContent
            title="What is the New Client and Contact Structure?"
            videoSrc="https://canopy.wistia.com/medias/pr7rquo2o2"
          >
            <div>
              We have heard your feedback and are excited to announce that Canopy has rolled out a new client and
              contact structure to help you better organize your clients!
            </div>
            <div>
              At Canopy, our goal is to ensure our tools enhance your firm's efficiency and client service. The new
              client and contact structure streamlines client and contact creation, allowing you to navigate records
              faster, organize families under one taxable entity, and more. This update empowers your firm to grow
              without being hindered by redundant data management.
            </div>
          </MigrationGuideContent>
        )}
      </CpModal.Body>
      <CpModal.Footer>
        <div className="flex justify-end">
          <CpButton btnType="primary" onClick={close}>
            Continue
          </CpButton>
        </div>
      </CpModal.Footer>
    </CpModal>
  );
};

export function renderMigrationCompleteModal() {
  const el = document.createElement("div");
  document.body.appendChild(el);
  const root = createRoot(el);
  root.render(
    <MigrationCompleteModal
      onAfterClose={() => {
        root.unmount();
        document.body.removeChild(el);
      }}
    />
  );
}
