import React from "react";
import PropTypes from "prop-types";
import { resolution_actions } from "../dedupe-modal.helper.js";
import ResolvedConflictTable from "./resolved-client-table.component.js";

export default class ResolvedClient extends React.Component {
  static propTypes = {
    import_row: PropTypes.object.isRequired,
    crmColumns: PropTypes.array.isRequired,
    conflicting_canopy_clients: PropTypes.array.isRequired,
    resolutions: PropTypes.array.isRequired,
    deleteResolution: PropTypes.func.isRequired,
  };

  render() {
    const { import_row, crmColumns, conflicting_canopy_clients, resolutions, deleteResolution } = this.props;
    const { resolution, merged, keptSeparate } = this.sortRows(resolutions, import_row, conflicting_canopy_clients);

    if (resolution.action === resolution_actions.merge && keptSeparate.length > 0) {
      //Show multiple tables when 1 Canopy Client Merged and 1+ Kept Separate
      return (
        <div>
          <div>
            <ResolvedConflictTable
              title={"Merged"}
              crmColumns={crmColumns}
              rowsToDisplay={[merged]}
              deleteResolution={deleteResolution.bind(this, merged.row_id)}
            />
          </div>
          <div>
            <ResolvedConflictTable
              title={"Kept separate"}
              crmColumns={crmColumns}
              rowsToDisplay={keptSeparate}
              deleteResolution={deleteResolution.bind(this, merged.row_id)}
            />
          </div>
        </div>
      );
    } else {
      //Normal case: single table of either merged or separate clients
      return (
        <div>
          <ResolvedConflictTable
            title={merged.action === resolution_actions.merge ? "Merged" : "Kept separate"}
            crmColumns={crmColumns}
            rowsToDisplay={[merged, ...keptSeparate]}
            deleteResolution={deleteResolution.bind(this, merged.row_id)}
          />
        </div>
      );
    }
  }

  sortRows(resolutions, import_row, conflicting_canopy_clients) {
    //Get the resolution object
    const indexOfResolution = resolutions.findIndex((resolution) => resolution.row_id === import_row.row_id);
    const resolution = resolutions[indexOfResolution];

    //Get the mergedCanopyClient (if it exists)
    const indexOfMergedCanopyClient = conflicting_canopy_clients.findIndex(
      (client) => resolution && client.id === resolution.id
    );
    const mergedCanopyClient = ~indexOfMergedCanopyClient ? conflicting_canopy_clients[indexOfMergedCanopyClient] : {};

    //Merge the mergedCanopyClient and resolution values (for display)
    const merged = { ...mergedCanopyClient, ...resolution };
    //Get the canopyClients that were kept separate
    const keptSeparate = conflicting_canopy_clients.filter((client) => client.id !== merged.id);

    return { resolution, merged, keptSeparate };
  }
}
