import React from "react";
import useUserPrefsQuery from "src/common/queries/use-user-prefs-query.hook";
import { renderContactModal } from "../../contact-modal/contact-modal.component";

export default function ContactNameCell({ resource, disableEdit }: { resource: any; disableEdit?: boolean }) {
  const { userPrefs } = useUserPrefsQuery();

  const name =
    userPrefs?.clientNameDisplayBy === "last" && !!resource.last_name
      ? `${[resource.last_name, resource.first_name].filter((val) => val).join(", ")}`
      : resource.name;

  return (
    <a
      onClick={() =>
        renderContactModal({ mode: "view", disableEdit, placeholderContact: resource, contactId: resource.id })
      }
    >
      {name}
    </a>
  );
}
ContactNameCell.overrideEmptyCell = true;
