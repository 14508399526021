import React from "react";
import { CpInputField } from "canopy-styleguide!sofe";

export function ContactLastNameField({
  control,
  disabled,
  fieldName = "last_name",
}: {
  control: any;
  disabled?: boolean;
  fieldName?: string;
}) {
  return (
    <CpInputField
      fieldName={fieldName}
      control={control}
      label="Last Name"
      placeholder="Last Name"
      required
      maxLength={150}
      disabled={disabled}
      rules={{
        required: {
          value: true,
          message: "Last name is required",
        },
      }}
    />
  );
}
