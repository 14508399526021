import { genQueryKey, invalidateEntity, useQuery } from "src/react-query";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { getIntegrationsObs } from "src/resources/integrations.resource";

// Returns whether qbo is connected or not
export default function useQboIntegration() {
  const [, tenant] = useWithUserAndTenant();

  const query = useQuery({
    queryKey: genQueryKey("qbo-integration"),
    queryFn: () =>
      new Promise((resolve, reject) => {
        if (tenant?.qbo_credentials_id) {
          getIntegrationsObs().subscribe((integrations) => {
            const qboIntegrationInfo = integrations.find((i) => i.type === "qbo") || {};
            resolve(qboIntegrationInfo);
          }, reject);
        } else {
          resolve({});
        }
      }),
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return {
    qboIntegrationQuery: query,
    qboIntegrationInfo: query.data,
  };
}

export const invalidateQboIntegration = () => {
  invalidateEntity("qbo-integration");
};
