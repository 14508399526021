import React from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { useCss } from "kremling";

export default function ClientHomeCard(props) {
  return (
    <div className="cps-card" {...useCss(styles)}>
      <div className="cps-card__header">
        <span className="cps-subheader">{props.title}</span>
        <CpButton btnType="flat" onClick={props.buttonAction}>
          {props.buttonText}
        </CpButton>
      </div>
      <div className="cardBody">{props.children}</div>
    </div>
  );
}

const styles = `
& .cps-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
& .cps-card { /* this enforces maximum width for text overflow  https://stackoverflow.com/a/43312314/5671022 */
  min-width: 0;
  min-height: 0;
  height: 100%;
}
& .cardBody {
  height: calc(100% - 65px);
}
& .row {
  border-bottom: 1px solid var(--cps-color-card-border);
  padding: 16px 24px;
  display: flex;
  flex: 1;
  cursor: pointer;
  transition: 100ms ease-in background-color;
}
& .row:hover {
  background-color: var(--cps-color-menu-hover-bg);
}
& .full > .row:last-of-type {
  border-bottom: none;
}
& .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

& p {
  margin: 0;
}

& .cps-ellipsis > ul, .cps-ellipsis > ol {
  padding-left: 0;
}
`;
