import React, { useMemo } from "react";
import { CpInputField } from "canopy-styleguide!sofe";
import { debounce } from "lodash";
import { getClientGroups } from "src/resources/client-groups.resource";
import { useFormContext } from "react-hook-form";
import { handleError } from "src/error";

type ClientGroupNameFieldProps = {
  fieldName: string;
  label?: string;
  initialName?: string;
};

export function ClientGroupNameField({ fieldName, label, initialName }: ClientGroupNameFieldProps) {
  const checkDuplicate = useMemo(
    () =>
      debounce((value, callback) => {
        getClientGroups({ search: value, strict: true }).subscribe((res: any) => {
          callback(res.available ? true : "Client group already exists");
        }, handleError);
      }, 500),
    []
  );

  const { control } = useFormContext();

  return (
    <CpInputField
      control={control}
      fieldName={fieldName}
      placeholder="Client group name"
      label={label || "Client group name"}
      required
      rules={{
        validate: {
          checkDuplicate: (value: string) =>
            value.toLowerCase() === initialName?.toLowerCase()
              ? true
              : new Promise((resolve) => {
                  checkDuplicate(value, resolve);
                }),
        },
      }}
    />
  );
}
