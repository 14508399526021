import React from "react";
import { ViewContactWell } from "../common/view-contact-well.component";
import { TAssignedClient, TContactType } from "src/common/types";
import { AssignedClient } from "./assigned-client.component";

type AssignedClientsSectionProps = {
  assignedClients: TAssignedClient[];
  closeModal: () => void;
};

const sortOrder: TContactType[] = ["primary", "spouse", "dependent", "other"];

export function AssignedClientsSection({ assignedClients, closeModal }: AssignedClientsSectionProps) {
  const sortedAssignedClients = assignedClients.sort(
    (a, b) => sortOrder.indexOf(a.contact_type) - sortOrder.indexOf(b.contact_type)
  );
  return (
    <ViewContactWell title="Assigned Clients">
      <div className="cp-flex-column cp-gap-16">
        {sortedAssignedClients.map((assignedClient) => (
          <AssignedClient key={assignedClient.id} assignedClient={assignedClient} closeModal={closeModal} />
        ))}
      </div>
    </ViewContactWell>
  );
}
