import React from "react";
import { CpIcon } from "canopy-styleguide!sofe";

type ClientPortalUserInvitedCellProps = {
  value: boolean;
};

export const ClientPortalUserInvitedCell: React.FC<ClientPortalUserInvitedCellProps> = ({ value }) => {
  if (value) {
    return (
      <>
        <CpIcon name="checkmark-circle-open-small" className="cp-mr-8" fill="var(--cp-color-app-success-text)" />
        <span>With users</span>
      </>
    );
  }
  return (
    <>
      <CpIcon color="var(--cp-color-app-icon)" name="remove-circle-open-small" className="cp-mr-8" />
      <span>Without users</span>
    </>
  );
};
