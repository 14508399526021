import React from "react";
import PropTypes from "prop-types";

import { getInvalidRows } from "../dedupe.resource.js";

export default class RejectedRowsDownloadModal extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    import_id: PropTypes.string.isRequired,
  };

  render() {
    const { closeModal, import_id } = this.props;

    return (
      <div className="cps-modal">
        <div className="cps-modal__screen"></div>
        <div className="cps-modal__dialog cps-card cps-card__height-3">
          <div className="cps-card__header cps-subheader-sm">
            <span>Download invalid rows</span>
            <a onClick={this.closeAndRedirect} className="cps-modal__dialog__close cps-icon cps-icon-close"></a>
          </div>
          <div className="cps-card__body">
            <p>
              Your file download should start shortly. The file contains those rows that the import tool was unable to
              process successfully.
            </p>
            <a href={`/api/import/imports/${import_id}/invalid_rows`}>Click here</a> if your download does not start
            automatically.
            <iframe width="1" height="1" frameBorder="0" src={`/api/import/imports/${import_id}/invalid_rows`}></iframe>
          </div>
          <div className="cps-modal__dialog__actions">
            <button onClick={this.closeAndRedirect} className="cps-btn +primary">
              Okay
            </button>
          </div>
        </div>
      </div>
    );
  }

  getRejectedRowsCSV = () => {
    const { import_id } = this.props;

    getInvalidRows(import_id);
  };

  closeAndRedirect = () => {
    const clientsString = "/clients";

    if (window.location.href.includes(`#${clientsString}`)) {
      // remove querystring parameters related to failed rows download
      history.replaceState({ "/clients": clientsString }, "Canopy: Delightful Client Management", `#${clientsString}`);
    }

    this.props.closeModal();
  };
}
