import { useQuery } from "src/react-query";
import { TContact } from "src/common/types";
import { contactQueries } from "src/queries";

export default function useContactQuery(
  { contactId, placeholderContact }: { contactId: string; placeholderContact?: Omit<TContact, "id"> },
  queryOptions?: object
) {
  const query = useQuery({
    ...contactQueries.contact(contactId),
    placeholderData: placeholderContact,
    ...queryOptions,
  });

  return {
    contactQuery: query,
    contact: query.data,
  };
}
