import React, { useEffect, useMemo, useState } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "src/react-query";
import { CpButton, CpWell, CpLoader, CpModal, CpTooltip, CpIcon } from "canopy-styleguide!sofe";
import { forIn, isEmpty } from "lodash";
import RolesSection from "src/common/roles-section/roles-section.component";
import UsersTeamsSection from "src/common/users-teams-section/users-teams-section.component";
import { useTeamMembersQuery } from "src/clients-ui";
import { putRoleAssignments } from "src/resources/clients.resource";
import CreateRole from "./create-role.component";
import SelectTemplateButton from "src/templates/select-template-button/select-template-button.component";
import { rolesConvertTemplateApiToForm } from "src/templates/template.helpers";
import { isUserIncluded } from "src/common/roles-section/roles.helpers";
import { useHasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";
import { handleError } from "src/error";
import useClientQuery from "src/common/queries/use-client-query.hook";
import { clientQueries, useRolesQuery } from "src/queries";

export default function AssignUsersModal({ onClose, onAssignmentsUpdated, clientId }) {
  const [show, setShow] = useState(true);
  const { roles, rolesQuery } = useRolesQuery();
  const { teamMembers: users, teams } = useTeamMembersQuery();
  const { client, clientQuery } = useClientQuery({ clientId, includes: "roles,general_assigned" });
  const {
    is_business,
    business_name,
    display_name,
    first_name,
    middle_name,
    last_name,
    role_assignments: roleAssignments,
    general_assigned: usersTeamsAssignments,
  } = client || {};

  const [rolesValues, setRolesValues] = useState({});
  const [usersTeamsValues, setUsersTeamsValues] = useState([]);

  const canApplyTemplates = useHasAccess("templates_client_records_apply");
  const hasFullClientAccess = useHasAccess("clients_not_assigned");
  const [loggedInUser] = useWithUserAndTenant();
  const showCurrentUserNotAssigned = useMemo(() => {
    if (hasFullClientAccess) return false;
    return !isUserIncluded(loggedInUser.id, { ...rolesValues, usersTeamsValues });
  }, [rolesValues, usersTeamsValues, loggedInUser.id, hasFullClientAccess]);

  useEffect(() => {
    if (roleAssignments && users && teams) {
      setRolesValues(mapRoleAssignmentsToState(roleAssignments, users, teams));
    }
  }, [roleAssignments, users, teams]);

  useEffect(() => {
    if (usersTeamsAssignments && users && teams) {
      setUsersTeamsValues(mapUsersTeamsToState(usersTeamsAssignments, users, teams));
    }
  }, [usersTeamsAssignments, users, teams]);

  const name = display_name
    ? display_name
    : is_business
    ? business_name
    : `${first_name} ${middle_name || ""} ${last_name}`;

  const onSave = () => {
    if (!client) return;
    const role_assignments = [];
    forIn(rolesValues, (val, key) => {
      const users = [];
      const teams = [];
      val.forEach((item) => {
        if (!item) return;
        item.role === "TeamMember" ? users.push(item.id) : teams.push(item.id);
      });
      role_assignments.push({
        role_id: key,
        users,
        teams,
      });
    });
    const users = [];
    const teams = [];
    usersTeamsValues.forEach((item) => {
      if (!item) return;
      item.role === "TeamMember" ? users.push(item.id) : teams.push(item.id);
    });

    const body = {
      role_assignments,
      users,
      teams,
    };
    putRoleAssignments(client.id, body).subscribe(() => {
      clientQueries.invalidate();
      window.dispatchEvent(new CustomEvent("clients-ui::client-updated"));
      onAssignmentsUpdated?.();
      close();
    }, handleError);
  };

  function close() {
    setShow(false);
  }

  const isLoading = clientQuery.isLoading || rolesQuery.isLoading;

  const applyTemplate = (templateData) => {
    const { roles: newRoles, usersTeams: newUsersTeams } = rolesConvertTemplateApiToForm(templateData, users, teams);
    setRolesValues(newRoles);
    setUsersTeamsValues(newUsersTeams);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <CpModal show={show} onClose={close} onAfterClose={onClose} width={736}>
        <CpModal.Header title="Manage Assignments" />
        <CpModal.Body>
          {isLoading ? (
            <CpLoader />
          ) : (
            <>
              <div className="cp-flex-spread cp-mb-4">
                <div className="cp-subheader">{name}</div>
                <CreateRole />
              </div>
              <div className="cp-flex-spread-center cp-mb-16">
                <div className="cp-body-sm">{roleAssignments?.length} roles assigned</div>
                {canApplyTemplates && (
                  <div>
                    <SelectTemplateButton
                      onApplyTemplate={applyTemplate}
                      isManageAssignments={true}
                      validateForm={() => [!isEmpty(rolesValues) || usersTeamsValues.length > 0]}
                    />
                    <CpTooltip text="Client record templates will only affect Roles and General User/Team assignments">
                      <CpIcon name="information-circle-open-small" />
                    </CpTooltip>
                  </div>
                )}
              </div>
              <CpWell className="cp-p-16 cp-mb-16 cp-flex-column cp-gap-16">
                {roles.length > 0 && (
                  <>
                    <div className="cp-body-sm cp-wt-semibold">Roles</div>
                    <RolesSection
                      roles={roles}
                      value={rolesValues}
                      onChange={(value, roleId) => {
                        setRolesValues((prev) => ({
                          ...prev,
                          [roleId]: value,
                        }));
                      }}
                      showCurrentUserNotAssigned={showCurrentUserNotAssigned}
                    />
                  </>
                )}
                <UsersTeamsSection
                  value={usersTeamsValues}
                  onChange={setUsersTeamsValues}
                  hasRoles={roles.length > 0}
                  showCurrentUserNotAssigned={roles.length === 0 && showCurrentUserNotAssigned}
                />
              </CpWell>
            </>
          )}
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton className="cp-mr-8" onClick={onSave} disabled={isLoading}>
            Save
          </CpButton>
          <CpButton btnType="flat" onClick={onClose}>
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </QueryClientProvider>
  );
}

function mapRoleAssignmentsToState(roleAssignments, users, teams) {
  const newState = {};
  roleAssignments?.forEach((item) => {
    const usersArr = item?.users?.map((id) => users.find((u) => u.id === id)) || [];
    const teamsArr = item?.teams?.map((id) => teams.find((t) => t.id === id)) || [];
    newState[item.role_id] = [...usersArr, ...teamsArr];
  });
  return newState;
}

function mapUsersTeamsToState(usersTeams, users, teams) {
  const usersArr =
    usersTeams.users?.map((userId) => {
      return users.find((u) => u.id === userId);
    }) || [];
  const teamsArr =
    usersTeams.teams?.map((teamId) => {
      return teams.find((t) => t.id === teamId);
    }) || [];

  return [...usersArr, ...teamsArr];
}
