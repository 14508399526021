// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-dedupe-modal-page-title-bar-page-title-bar-styles__pageTitleBar {
  width: 100%;
  height: 112px;
  background: #39f;
  text-align: center;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.18);
}

.src-dedupe-modal-page-title-bar-page-title-bar-styles__titleAndSubTitle {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 112px;
}

.src-dedupe-modal-page-title-bar-page-title-bar-styles__toolsArea {
  position: absolute;
  width: 128px;
  height: 112px;
  padding: 20px;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.src-dedupe-modal-page-title-bar-page-title-bar-styles__importButton {
  color: #39f;
}

.src-dedupe-modal-page-title-bar-page-title-bar-styles__slideRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/dedupe-modal/page-title-bar/page-title-bar.styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,MAAM;EACN,QAAQ;EACR,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,8BAA8B;AAChC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".pageTitleBar {\n  width: 100%;\n  height: 112px;\n  background: #39f;\n  text-align: center;\n  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.18);\n}\n\n.titleAndSubTitle {\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 112px;\n}\n\n.toolsArea {\n  position: absolute;\n  width: 128px;\n  height: 112px;\n  padding: 20px;\n  top: 0;\n  right: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  justify-content: space-between;\n}\n\n.importButton {\n  color: #39f;\n}\n\n.slideRight {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitleBar": `src-dedupe-modal-page-title-bar-page-title-bar-styles__pageTitleBar`,
	"titleAndSubTitle": `src-dedupe-modal-page-title-bar-page-title-bar-styles__titleAndSubTitle`,
	"toolsArea": `src-dedupe-modal-page-title-bar-page-title-bar-styles__toolsArea`,
	"importButton": `src-dedupe-modal-page-title-bar-page-title-bar-styles__importButton`,
	"slideRight": `src-dedupe-modal-page-title-bar-page-title-bar-styles__slideRight`
};
export default ___CSS_LOADER_EXPORT___;
