// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-dedupe-modal-resolved-client-resolved-client-table-styles__resolvedTable {
  border-collapse: collapse;
  width: 100%;
  border: thin solid lightgray;
  white-space: nowrap;
}

.src-dedupe-modal-resolved-client-resolved-client-table-styles__tableSpacing {
  margin: 16px 0 24px 0;
  white-space: nowrap;
}

.src-dedupe-modal-resolved-client-resolved-client-table-styles__borderBottom {
  border-bottom: thin solid lightgray;
}

.src-dedupe-modal-resolved-client-resolved-client-table-styles__tableHead {
  height: 32px;
  border-bottom: thin solid lightgray;
}
.src-dedupe-modal-resolved-client-resolved-client-table-styles__tableBody {
  height: 72px;
  border-bottom: thin solid lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/dedupe-modal/resolved-client/resolved-client-table.styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,4BAA4B;EAC5B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,YAAY;EACZ,mCAAmC;AACrC;AACA;EACE,YAAY;EACZ,mCAAmC;AACrC","sourcesContent":[".resolvedTable {\n  border-collapse: collapse;\n  width: 100%;\n  border: thin solid lightgray;\n  white-space: nowrap;\n}\n\n.tableSpacing {\n  margin: 16px 0 24px 0;\n  white-space: nowrap;\n}\n\n.borderBottom {\n  border-bottom: thin solid lightgray;\n}\n\n.tableHead {\n  height: 32px;\n  border-bottom: thin solid lightgray;\n}\n.tableBody {\n  height: 72px;\n  border-bottom: thin solid lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resolvedTable": `src-dedupe-modal-resolved-client-resolved-client-table-styles__resolvedTable`,
	"tableSpacing": `src-dedupe-modal-resolved-client-resolved-client-table-styles__tableSpacing`,
	"borderBottom": `src-dedupe-modal-resolved-client-resolved-client-table-styles__borderBottom`,
	"tableHead": `src-dedupe-modal-resolved-client-resolved-client-table-styles__tableHead`,
	"tableBody": `src-dedupe-modal-resolved-client-resolved-client-table-styles__tableBody`
};
export default ___CSS_LOADER_EXPORT___;
