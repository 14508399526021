import React from "react";
import { Control } from "react-hook-form";
import { MaskedInputField } from "src/common/form/masked-input-field.component";

type EinFieldProps = {
  control: Control<any>;
  required?: boolean;
};

export function EinField({ control, required }: EinFieldProps) {
  return (
    <MaskedInputField
      control={control}
      className="fs-exclude"
      label="EIN"
      fieldName="tin"
      validateFn={(value) => !value?.length || value?.length === 9}
      nonValidMsg="EIN should be 9 numeric characters"
      required={required}
      masks={[
        {
          condition: (value: any) => value.length <= 9,
          pattern: "11-1111111",
        },
        {
          condition: (value: any) => value.length > 9,
          pattern: "111111111",
        },
      ]}
    />
  );
}
