import React from "react";
import { Input } from "src/form/input/input.component";
import { useFormInput } from "src/forms-lib";
import { FieldLabel } from "src/common/form/field-label.component";

export default function FirstName(props) {
  const { maxLength } = props;
  const [firstName, setFirstName, more] = useFormInput({
    name: "first_name",
    required: true,
  });
  const { setFocus, showInvalid, valid } = more;
  return (
    <Input
      type="text"
      placeholder="First name"
      validationFn={(name) => name.length < maxLength}
      required={true}
      setFocus={setFocus}
      value={firstName}
      updateValue={setFirstName}
      valid={valid}
      showInvalid={showInvalid}
      invalidMessage={(value) => {
        if (!value) {
          return "First name is required";
        } else if (value.length >= maxLength) {
          return `First name must be less than ${maxLength} characters`;
        }
      }}
      label={getFirstLabel}
    />
  );
}

function getFirstLabel({ valid }) {
  return (
    <FieldLabel>
      <span>First name</span>
      <span className={valid ? "cps-color-primary" : undefined}>*</span>
    </FieldLabel>
  );
}
