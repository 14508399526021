// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-list-table-cells-list-cell-styles__moreButton {
  background: none;
  border: none;
  padding: 0;
  margin-left: 0.4rem;
  text-decoration: underline;
  cursor: pointer;
}

.src-client-list-table-cells-list-cell-styles__dropdownContent {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  padding-left: 0.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/client-list/table/cells/list-cell.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".moreButton {\n  background: none;\n  border: none;\n  padding: 0;\n  margin-left: 0.4rem;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.dropdownContent {\n  display: flex;\n  flex-direction: column;\n  padding: 0.8rem;\n  padding-left: 0.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreButton": `src-client-list-table-cells-list-cell-styles__moreButton`,
	"dropdownContent": `src-client-list-table-cells-list-cell-styles__dropdownContent`
};
export default ___CSS_LOADER_EXPORT___;
