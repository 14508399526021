import React, { useState, useMemo, Suspense, lazy } from "react";
import { CpIcon, CpModal, CpEmptyState, CpButton, CpLoader, constants } from "canopy-styleguide!sofe";
import { useWithUserAndTenant, hasAccess, isClientLimitModel } from "cp-client-auth!sofe";
import { successToast } from "toast-service!sofe";
import CreateEditClientModal from "src/create-edit-client-modal";
import OldCreateEditClientModal from "src/create-edit-client-old/create-edit-client-modal/create-edit-client-modal.component";
import AssignUsersModal from "../../assign-users-modal/assign-users-modal.component";
import AssignClientOwnerModal from "src/client-list/assign-client-owner-modal.component";
import ArchiveModal from "src/common/archive-modal.component";
import { deleteClient, getClientsUsage, putClient } from "src/resources/clients.resource";
import { handleError } from "src/error";
import { featureEnabled } from "feature-toggles!sofe";
import { clientQueries } from "src/queries";
import { SendEmailButton } from "./send-email-button";

const ComposeEmail = lazy(() =>
  SystemJS.import("communications-ui!sofe").then((commsUI) => commsUI.getComposeEmailOverlay())
);

const contextMenuActions = [
  {
    id: "edit",
    permissions: ["clients_create_edit"],
  },
  {
    id: "assign_team_member",
    permissions: ["clients_assign_team_members"],
  },
  {
    id: "assign_client_owner",
    permissions: ["clients_assign_team_members"],
  },
  {
    id: "archive",
    permissions: ["clients_archive"],
  },
  {
    id: "unarchive",
    permissions: ["clients_archive"],
  },
  {
    id: "delete",
    permissions: ["clients_delete"],
  },
  {
    id: "email",
    permissions: [],
  },
];

const ContextMenu = React.forwardRef((props, ref) => {
  const {
    actions, // Array of available actions, pass falsey if you want all
    client,
    selection,
  } = props;

  const [user, tenant] = useWithUserAndTenant();
  const [showEditClientModal, setShowEditClientModal] = useState(false);
  const [showClientOwnerModal, setShowClientOwnerModal] = useState(false);
  const [showAssignmentsModal, setShowAssignmentsModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [reachedClientLimit, setReachedClientLimit] = useState(false);
  const ft_crm = featureEnabled("ft_crm") && tenant?.crm_status === "crm_hierarchy_complete";

  const allowedActions = useMemo(() => {
    const actionIds = contextMenuActions
      .filter((action) => {
        return (
          hasAccess(user, true)(action.permissions) &&
          (actions ? actions.includes(action.id) : !["delete", "unarchive"].some((i) => action.id === i))
        );
      })
      .map((action) => action.id);
    return new Set(actionIds);
  }, [actions, user]);

  const archiveClient = () => {
    putClient(client.id, { is_archived: true }).subscribe((client) => {
      successToast(`${client.name} has been archived.`);
      clientQueries.invalidate();
    }, handleError);
    setShowArchiveModal(false);
  };

  const attemptUnArchiveClient = () => {
    if (!isClientLimitModel(tenant?.client_limit)) {
      unArchiveClient();
    } else {
      getClientsUsage().subscribe((res) => {
        if (res.reached_limit) {
          setReachedClientLimit(res);
        } else {
          unArchiveClient();
        }
      }, handleError);
    }
  };

  const unArchiveClient = () => {
    putClient(client.id, { is_archived: false }).subscribe((clients) => {
      clientQueries.invalidate();
    }, handleError);
  };

  const attemptDeleteClient = () => {
    deleteClient(client.id).subscribe((resp) => {
      clientQueries.invalidate();
      if (selection.isSelected(client.id)) {
        selection.toggleSelection(client.id);
      }
      successToast(`Client successfully deleted.`);
    }, handleError);
  };

  return (
    <>
      <div className="cp-select-list">
        {allowedActions.has("edit") && (
          <button onClick={() => setShowEditClientModal(true)}>
            <CpIcon className="cp-select-list__icon-left" name="crud-pencil" />
            Edit client
          </button>
        )}
        {allowedActions.has("unarchive") && (
          <button onClick={attemptUnArchiveClient}>
            <CpIcon className="cp-select-list__icon-left" name="af-undo" />
            Unarchive client
          </button>
        )}
        {allowedActions.has("delete") && (
          <button onClick={attemptDeleteClient}>
            <CpIcon className="cp-select-list__icon-left" name="crud-trash-large" />
            Delete client
          </button>
        )}
        {allowedActions.has("assign_team_member") && (
          <button
            onClick={() => {
              setShowAssignmentsModal(true);
            }}
          >
            <CpIcon className="cp-select-list__icon-left" name="person-people" />
            Manage assignments
          </button>
        )}
        {allowedActions.has("assign_client_owner") && (
          <button
            onClick={() => {
              setShowClientOwnerModal(true);
            }}
          >
            <CpIcon className="cp-select-list__icon-left" name="person" />
            Assign client owner
          </button>
        )}
        {allowedActions.has("archive") && (
          <button onClick={() => setShowArchiveModal(true)}>
            <CpIcon className="cp-select-list__icon-left" name="crud-archive" />
            Archive client
          </button>
        )}
        {ft_crm && allowedActions.has("email") && (
          <SendEmailButton
            clientName={client?.name}
            primaryContactName={client?.contacts?.find((contact) => contact.is_primary)?.name}
            email={client?.email}
            isBusiness={client?.is_business}
            onClick={() => setShowEmailModal(true)}
          />
        )}
      </div>
      {showEditClientModal &&
        (ft_crm ? (
          <CreateEditClientModal
            clientId={client.id}
            placeholderClient={client}
            onModalHide={() => {
              setShowEditClientModal(false);
            }}
          />
        ) : (
          <OldCreateEditClientModal
            clientId={client.id}
            redirectAsPrimary={false}
            isCreate={false}
            onModalHide={() => {
              clientQueries.invalidate();
              setShowEditClientModal(false);
            }}
          />
        ))}
      {showAssignmentsModal && <AssignUsersModal onClose={() => setShowAssignmentsModal(false)} clientId={client.id} />}
      {showClientOwnerModal && (
        <AssignClientOwnerModal onClose={() => setShowClientOwnerModal(false)} clientId={client.id} />
      )}
      {reachedClientLimit && (
        <CpModal
          show={reachedClientLimit}
          onClose={() => {
            setReachedClientLimit(false);
          }}
          className="cp-p-20"
        >
          <div className="cp-p-8" style={{ position: "absolute", right: 0, top: 0 }}>
            <CpButton
              btnType="icon"
              aria-label="Close"
              icon="close-small"
              onClick={() => {
                setReachedClientLimit(false);
              }}
            />
          </div>
          <CpEmptyState
            img="es_clients"
            text="Time to upgrade"
            subText={`You've hit your maximum of ${reachedClientLimit.allowed} clients for your pricing tier. Call ${constants.canopyPhone} to upgrade.`}
            cta={
              <CpButton btnType="flat" anchor href="#/global-settings/account-management">
                View my subscription
              </CpButton>
            }
          />
        </CpModal>
      )}
      {showArchiveModal && (
        <ArchiveModal onArchive={archiveClient} onClose={() => setShowArchiveModal(false)} totalToArchive={1} />
      )}
      {showEmailModal && (
        <Suspense fallback={<CpLoader />}>
          <ComposeEmail to={client?.email} close={() => setShowEmailModal(false)} />
        </Suspense>
      )}
    </>
  );
});

export default ContextMenu;
