import React from "react";
import { CpArea, CpIcon } from "canopy-styleguide!sofe";
import { a } from "kremling";
import { cssModules, useCssModules } from "inline-css-modules-react";

type ModalTabBodyProps = {
  children: React.ReactNode;
  tabs: { id: string; name: string; icon?: string }[];
  activeTabId: string;
  onTabChange: (tabId: string) => void;
};

export const ModalTabBody = ({ children, tabs, activeTabId, onTabChange }: ModalTabBodyProps) => {
  useCssModules(css);
  return (
    <div className={s.modalTabBody}>
      {!!tabs?.length && (
        <div className={s.tabsContainer}>
          {tabs.map((tab) => (
            <CpArea key={tab.id} className={s.tab} onClick={() => onTabChange(tab.id)}>
              <div className={a("flex cp-gap-12 items-center").m("cp-color-app-primary", activeTabId === tab.id)}>
                {tab.icon && <CpIcon name={tab.icon} />}
                {tab.name}
              </div>
            </CpArea>
          ))}
        </div>
      )}
      <div className={a("cp-p-16 flex-1", s.children)}>{children}</div>
    </div>
  );
};

const { s, css } = cssModules`
  .modalTabBody {
    display: flex;
    overflow: hidden;
  }

  .tab {
    font-size: 1.4rem;
    min-width: 20rem;
    padding: 1.6rem;
    border-bottom: solid 0.1rem var(--cp-color-card-border);
  }

  .tabsContainer {
    border-right: solid 0.1rem var(--cp-color-card-border);
  }

  .children {
    overflow: auto;
  }
`;
