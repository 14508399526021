import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { CpButton, CpModal } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import useUserPrefsQuery from "src/common/queries/use-user-prefs-query.hook";

export const MigrationErrorModal = ({
  migrationId,
  onAfterClose,
}: {
  migrationId: string;
  onAfterClose: () => void;
}) => {
  const { updateUserPrefs } = useUserPrefsQuery();
  const [show, setShow] = useState(true);
  const close = () => {
    setShow(false);
    updateUserPrefs({
      lastErroredCrmMigrationId: migrationId,
    });
  };
  const isAdmin = useHasAccess("crm_migration_access");

  return (
    <CpModal show={show} onClose={close} onAfterClose={onAfterClose} width={712}>
      <CpModal.Header title="Migration Unsuccessful" />
      <CpModal.Body>
        {isAdmin
          ? "Your client and contact restructure migration was unable to complete. We apologize for any trouble this may have caused! We are aware of this issue and are working to fix it. You will be notified when you can try again."
          : "Your client and contact restructure migration was unable to complete. We apologize for any trouble this may have caused! Please contact your admin to review next steps."}
      </CpModal.Body>
      <CpModal.Footer>
        <div className="flex justify-end">
          <CpButton btnType="primary" onClick={close}>
            Continue
          </CpButton>
        </div>
      </CpModal.Footer>
    </CpModal>
  );
};

export function renderMigrationErrorModal({ migrationId }: { migrationId: string }) {
  const el = document.createElement("div");
  document.body.appendChild(el);
  const root = createRoot(el);
  root.render(
    <MigrationErrorModal
      migrationId={migrationId}
      onAfterClose={() => {
        root.unmount();
        document.body.removeChild(el);
      }}
    />
  );
}
