import { ClientNamesResponse } from "src/resources/clients.resource";

export function sortClientNames(clientNames: ClientNamesResponse, whitelistedNames?: string[]) {
  return [...clientNames].sort((a, b) => {
    const aIsWhiteListed = whitelistedNames?.includes(a.name);
    const aIsDisabled = !aIsWhiteListed && !!a.client_groups;
    const bIsWhiteListed = whitelistedNames?.includes(b.name);
    const bIsDisabled = !bIsWhiteListed && !!b.client_groups;

    // Prioritize non-disabled clients over disabled ones
    if (aIsDisabled && !bIsDisabled) {
      return 1;
    } else if (!aIsDisabled && bIsDisabled) {
      return -1;
    } else {
      // If both are either disabled or enabled, sort them alphabetically by name
      return a.name.localeCompare(b.name);
    }
  });
}
