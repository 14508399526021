import { useState, useEffect, useMemo } from "react";
import { useInfiniteQuery } from "src/react-query";
import { getClients } from "../client-list.resource";
import useQboIntegration, { invalidateQboIntegration } from "./use-qbo-integration-query.hook";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { clientQueries } from "src/queries";

export default function useClientListQuery({
  page,
  limit,
  infiniteScroll,
  filters,
  sortData,
  search,
  visibleColumns,
  disabled,
  ft_crm,
}) {
  const [pageCount, setPageCount] = useState();
  const [totalClientsCount, setTotalClientsCount] = useState();
  const { qboIntegrationInfo, qboIntegrationQuery } = useQboIntegration();
  const [, tenant] = useWithUserAndTenant();

  // Order is not important for visible columns, so we can sort and flatten as a string to use as the cache key
  const visibleColumnsKey = useMemo(() => [...visibleColumns].sort().join(), [visibleColumns]);

  const queryKey = [
    clientQueries.baseKey[0],
    page,
    limit,
    infiniteScroll,
    filters,
    sortData,
    search,
    visibleColumnsKey,
    qboIntegrationInfo,
  ];
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam, signal }) =>
      new Promise((resolve, reject) => {
        const pageToFetch = (infiniteScroll ? pageParam : page) || 1;
        const pageLimit = infiniteScroll ? 50 : limit;
        const sub = getClients({
          page: pageToFetch,
          limit: pageLimit,
          sort_data: sortData,
          filters: filters,
          search,
          // client_groups data always needed for bulk action
          // email and contacts needed for context menu
          visibleColumns: [...visibleColumns, ...(ft_crm ? ["client_groups", "email", "contacts"] : [])],
        }).subscribe(resolve, reject);
        signal?.addEventListener("abort", () => sub.unsubscribe());
      }),
    initialPageParam: 1,
    enabled: !disabled && !!limit && !!filters && qboIntegrationQuery.isFetched,
    // Cache for 5 minutes (ms). Do not cache infinite queries to prevent refetching all pages
    gcTime: infiniteScroll ? 0 : 300000,
    // Stale time for 15 secs to prevent excessive re-fetching when quickly switching between tabs
    staleTime: 15000,
    getNextPageParam: (lastPage) => {
      const pagination = lastPage.pagination;
      if (pagination.current_page < pagination.total_pages) {
        return pagination.current_page + 1;
      } else {
        return undefined;
      }
    },
  });

  useEffect(() => {
    if (query.data?.pages?.length > 0) {
      const latestPage = query.data.pages[query.data.pages.length - 1];
      setPageCount(latestPage.pagination.total_pages);
      setTotalClientsCount(latestPage.pagination.total_count);
    }
  }, [query.data]);

  useEffect(() => {
    if (tenant) {
      invalidateQboIntegration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!tenant?.qbo_credentials_id]);

  const clients = useMemo(() => query.data?.pages?.flatMap((page) => page.clients) || [], [query.data]);

  return {
    clientsQuery: query,
    clients,
    pageCount,
    totalClientsCount,
  };
}
