import React from "react";
import styles from "./client-type-picker.styles.css";
import { m } from "kremling";
import { FieldLabel } from "src/common/form/field-label.component";
import { featureEnabled } from "feature-toggles!sofe";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

const options = [
  {
    label: "Client",
    value: "client",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "Prospect",
    value: "prospect",
  },
];

interface ClientTypePickerProps {
  className?: string;
  onChange: (value: string) => void;
  value: string;
}

export function ClientTypePicker({ className, onChange, value }: ClientTypePickerProps) {
  const [, tenant] = useWithUserAndTenant();
  const ft_crm = featureEnabled("ft_crm") && tenant?.crm_status === "crm_hierarchy_complete";
  return (
    <div className={className || ""}>
      <FieldLabel>{ft_crm ? "Client Status" : "Type"}</FieldLabel>
      <div className="flex cp-gap-8 cp-pt-4">
        {options.map((option) => (
          <button
            key={option.value}
            type="button"
            className={m(styles.selectedTypeOption, value === option.value).a(styles.typeOption)}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
}
