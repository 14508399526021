import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { CpInputField, CpSelectSingle } from "canopy-styleguide!sofe";

export type EmailFieldProps = {
  control: Control<any>;
  fieldName: string;
  emailTypes?: EmailType[];
  rules?: FieldValues;
  required?: boolean;
};

export type EmailType = {
  id: string;
  name: string;
};

export const defaultEmailTypes: EmailType[] = [
  { id: "personal", name: "Personal email" },
  { id: "work", name: "Work email" },
  { id: "other", name: "Other email" },
];

export function EmailField({ control, fieldName, emailTypes: overrideEmailTypes, rules, required }: EmailFieldProps) {
  useCssModules(css);
  const emailTypes = overrideEmailTypes || defaultEmailTypes;
  return (
    <>
      <div className={s.grid}>
        <Controller
          name={fieldName + ".type"}
          control={control}
          defaultValue={emailTypes[0].id}
          render={({ field: { value, onChange } }) => (
            <CpSelectSingle
              triggerIsBlock
              data={emailTypes}
              value={emailTypes.find((type) => type.id === value)}
              onChange={(val: any) => onChange(val.id)}
            />
          )}
        />
        <div className="grow">
          <CpInputField.Email
            control={control}
            fieldName={fieldName + ".email"}
            placeholder="Email address"
            maxLength={150}
            rules={rules}
            required={required}
          />
        </div>
      </div>
    </>
  );
}

const { css, s } = cssModules`
  .grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2.4rem;
  }
`;
