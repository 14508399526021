// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-list-client-list-styles__clientListContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 80px; /* 80px is the hardcoded width of the primary-navbar */
  background: var(--cp-color-well-l1-bg);
}

.src-client-list-client-list-styles__topBar {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem;
  padding-right: 2.4rem;
}

.src-client-list-client-list-styles__header {
  display: flex;
  align-items: center;
}

.src-client-list-client-list-styles__filterSection {
  border-top: 0.1rem solid var(--cp-color-app-border);
  border-bottom: 0.1rem solid var(--cp-color-app-border);
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  display: flex;
  justify-content: space-between;
}

.src-client-list-client-list-styles__clientListTable {
  overflow: auto;
  flex-grow: 1;
}

.src-client-list-client-list-styles__searchInput {
  width: 28.8rem;
}

.src-client-list-client-list-styles__limitedBulkWarning {
  font-style: italic;
  margin-left: 1.6rem;
}
`, "",{"version":3,"sources":["webpack://./src/client-list/client-list.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,iBAAiB,EAAE,sDAAsD;EACzE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mDAAmD;EACnD,sDAAsD;EACtD,iBAAiB;EACjB,oBAAoB;EACpB,oBAAoB;EACpB,qBAAqB;EACrB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".clientListContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  margin-left: 80px; /* 80px is the hardcoded width of the primary-navbar */\n  background: var(--cp-color-well-l1-bg);\n}\n\n.topBar {\n  display: flex;\n  justify-content: space-between;\n  padding: 1.2rem;\n  padding-right: 2.4rem;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n}\n\n.filterSection {\n  border-top: 0.1rem solid var(--cp-color-app-border);\n  border-bottom: 0.1rem solid var(--cp-color-app-border);\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  padding-left: 3.2rem;\n  padding-right: 3.2rem;\n  display: flex;\n  justify-content: space-between;\n}\n\n.clientListTable {\n  overflow: auto;\n  flex-grow: 1;\n}\n\n.searchInput {\n  width: 28.8rem;\n}\n\n.limitedBulkWarning {\n  font-style: italic;\n  margin-left: 1.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientListContainer": `src-client-list-client-list-styles__clientListContainer`,
	"topBar": `src-client-list-client-list-styles__topBar`,
	"header": `src-client-list-client-list-styles__header`,
	"filterSection": `src-client-list-client-list-styles__filterSection`,
	"clientListTable": `src-client-list-client-list-styles__clientListTable`,
	"searchInput": `src-client-list-client-list-styles__searchInput`,
	"limitedBulkWarning": `src-client-list-client-list-styles__limitedBulkWarning`
};
export default ___CSS_LOADER_EXPORT___;
