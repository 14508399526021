import React from "react";
import { BusinessTypeSelect } from "src/common/form/client/base";
import InputDecorator from "src/forms-lib/input-decorator.component";

const DecoratedBusinessTypeSelect = InputDecorator(function ({ value, updateValue }) {
  return <BusinessTypeSelect value={value} onChange={updateValue} />;
});

export default function BusinessType(props) {
  return (
    <div>
      <DecoratedBusinessTypeSelect name="business_type" />
    </div>
  );
}
