/**
 * Returns the plural form of a string based on the count.
 * If the count is 1, the singular form is returned.
 * If the count is greater than 1, the plural form is returned (by default it just appends an 's').
 * If a pluralStr is provided, it is used instead of the default plural form.
 *
 * @param count - The count to determine which form to return.
 * @param singularStr - The singular form of the string.
 * @param pluralStr - The optional plural form of the string.
 * @returns The plural or singular form of the string.
 */
export function plural(count: number, singularStr: string, pluralStr?: string): string {
  const pluralForm = pluralStr || `${singularStr}s`;
  return count === 1 ? singularStr : pluralForm;
}

/**
 * Returns a string representation of the count with the appropriate singular or plural form.
 *
 * @param count - The count to be displayed.
 * @param singularStr - The string to be used when the count is 1.
 * @param pluralStr - The string to be used when the count is not 1. Optional, defaults to adding 's' to the singularStr.
 * @returns The formatted string representation of the count.
 */
export function pluralCount(count: number, singularStr: string, pluralStr?: string): string {
  return `${count} ${plural(count, singularStr, pluralStr)}`;
}
