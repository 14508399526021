import { clientQueries } from "src/queries";
import { useQuery } from "src/react-query";
import { UseQueryResult } from "@tanstack/react-query";
import { ClientNamesResponse } from "src/resources/clients.resource";

type UseClientNamesQueryParams = {
  search: string;
  archivedOnly?: boolean;
  includeInactive?: boolean;
  includeClientGroups?: boolean;
};
type UseClientNamesQueryResult = {
  clientNamesQuery: UseQueryResult<ClientNamesResponse, Error>;
  clientNames: ClientNamesResponse | undefined;
};
export function useClientNamesQuery({
  search,
  archivedOnly,
  includeInactive,
  includeClientGroups,
}: UseClientNamesQueryParams): UseClientNamesQueryResult {
  const query = useQuery<ClientNamesResponse>({
    ...clientQueries.clientNames({ search, archivedOnly, includeInactive, includeClientGroups }),
    staleTime: 10000,
  });

  return {
    clientNamesQuery: query,
    clientNames: query.data,
  };
}
