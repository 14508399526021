// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-dedupe-modal-dedupe-modal-styles__modalContent {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 500px;
  width: 100%;
  height: 100%;
  background-color: #f4f5f8;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  overflow: hidden;
}

.src-dedupe-modal-dedupe-modal-styles__mainContent {
  padding: 32px 24px;
  height: calc(100% - 112px);
  overflow: auto;
}

.src-dedupe-modal-dedupe-modal-styles__fullHeight {
  display: flex;
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/dedupe-modal/dedupe-modal.styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;AACb","sourcesContent":[".modalContent {\n  position: fixed;\n  top: 0;\n  left: 0;\n  min-width: 500px;\n  width: 100%;\n  height: 100%;\n  background-color: #f4f5f8;\n  display: flex;\n  flex-direction: column;\n  z-index: 10000;\n  overflow: hidden;\n}\n\n.mainContent {\n  padding: 32px 24px;\n  height: calc(100% - 112px);\n  overflow: auto;\n}\n\n.fullHeight {\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `src-dedupe-modal-dedupe-modal-styles__modalContent`,
	"mainContent": `src-dedupe-modal-dedupe-modal-styles__mainContent`,
	"fullHeight": `src-dedupe-modal-dedupe-modal-styles__fullHeight`
};
export default ___CSS_LOADER_EXPORT___;
