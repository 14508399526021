import { useState, useEffect } from "react";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

import { getIntegrationsObs } from "src/resources/integrations.resource.js";
import type { IntegrationInfoProps } from "src/common/integration.d.ts";
import { handleError } from "src/error";
import { TClient } from "src/common/types";

export const useIntegrations = ({
  type = "qbo",
  client,
  isEdit = false,
}: {
  type?: string;
  client: TClient | Omit<TClient, "id"> | undefined;
  isEdit: boolean;
}) => {
  const [integration, setIntegration] = useState<IntegrationInfoProps | undefined>(undefined);
  const [overrideIntegrationSync, setOverrideIntegrationSync] = useState(false);
  const [syncClientToIntegration, setSyncClientToIntegration] = useState(false);
  const [qboRefreshTick, setQboRefreshTick] = useState(0);
  const [, tenant] = useWithUserAndTenant();

  const qboRefresh = () => {
    setQboRefreshTick(qboRefreshTick + 1);
  };

  useEffect(() => {
    if (!!tenant?.qbo_credentials_id) {
      const subscription = getIntegrationsObs().subscribe((integrations: Array<IntegrationInfoProps>) => {
        const qboIntegrationInfo = integrations.find((i: { type: string }) => i.type === type);
        setIntegration(qboIntegrationInfo);
        if (qboIntegrationInfo?.connected) {
          if (!!qboIntegrationInfo?.clients?.sync_status.synced_at) {
            setOverrideIntegrationSync(true);
          }

          if (
            (!isEdit &&
              !!integration?.clients?.settings?.actions_from_canopy?.canopy_clients_create_in_third_party_default) ||
            (isEdit && !!client?.third_party_id)
          ) {
            // default state for toggle
            setSyncClientToIntegration(true);
          }
        }
      }, handleError);
      return () => subscription.unsubscribe?.();
    } else if (!tenant?.qbo_credentials_id) {
      setIntegration(undefined);
      setOverrideIntegrationSync(false);
    }
  }, [
    client?.third_party_id,
    integration?.clients?.settings?.actions_from_canopy?.canopy_clients_create_in_third_party_default,
    isEdit,
    tenant?.qbo_credentials_id,
    type,
  ]);

  return {
    integration,
    overrideIntegrationSync,
    syncClientToIntegration,
    setSyncClientToIntegration,
    qboRefresh,
    qboRefreshTick,
  };
};
