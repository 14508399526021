import { mapIdsToItems } from "src/common/utils.helpers";

// Converts custom field data from a template into form data for the create/edit client form
export function templateCustomFieldsToFormData(dbCustomFields, templateCustomFields) {
  const customFieldsFormData = {};
  dbCustomFields.forEach((dbCustomField) => {
    const templateCustomField = templateCustomFields?.find((cf) => dbCustomField.field_id === cf.field_id);
    if (templateCustomField) {
      let dropdownValues = [];
      if (!!templateCustomField.dropdown_values) {
        templateCustomField.dropdown_values.forEach((valueId) => {
          const valueData = dbCustomField.dropdown_values.find((dv) => valueId === dv.id);
          dropdownValues.push({
            key: valueData.id,
            value: valueData.value,
          });
          if (dbCustomField.field_type === "dropdown") {
            dropdownValues = dropdownValues[0];
          }
        });
      }
      customFieldsFormData[templateCustomField.field_id] = {
        type: dbCustomField.field_type,
        value: templateCustomField.dropdown_values ? dropdownValues : templateCustomField.value,
      };
    }
  });
  return customFieldsFormData;
}

// Converts assignment role data from a template into form data for the create/edit client form
export function templateRoleAssignmentsToFormData(dbTeamMembers, dbTeams, templateRoles) {
  const formData = {};
  templateRoles?.forEach((templateRole) => {
    const users = mapIdsToItems(templateRole.users, dbTeamMembers);
    const teams = mapIdsToItems(templateRole.teams, dbTeams);
    formData[templateRole.role_id] = [...users, ...teams];
  });
  return formData;
}

export function templateUserTeamsToFormData(dbTeamMembers, dbTeams, userTeams) {
  if (!userTeams) return [];
  const users = mapIdsToItems(userTeams.users, dbTeamMembers);
  const teams = mapIdsToItems(userTeams.teams, dbTeams);
  return [...users, ...teams];
}
