// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-form-client-base-client-business-toggle-client-business-toggle-styles__container {
  background: var(--cp-color-well-l3-bg);
  border-radius: 0.8rem;
  width: 100%;
  display: flex;
  padding: 0.2rem;
}

.src-common-form-client-base-client-business-toggle-client-business-toggle-styles__button {
  height: 4.4rem;
  width: 27.3rem;
}

.src-common-form-client-base-client-business-toggle-client-business-toggle-styles__button.src-common-form-client-base-client-business-toggle-client-business-toggle-styles__active {
  background: var(--cp-color-input-bg);
}
`, "",{"version":3,"sources":["webpack://./src/common/form/client/base/client-business-toggle/client-business-toggle.styles.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,qBAAqB;EACrB,WAAW;EACX,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".container {\n  background: var(--cp-color-well-l3-bg);\n  border-radius: 0.8rem;\n  width: 100%;\n  display: flex;\n  padding: 0.2rem;\n}\n\n.button {\n  height: 4.4rem;\n  width: 27.3rem;\n}\n\n.button.active {\n  background: var(--cp-color-input-bg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-common-form-client-base-client-business-toggle-client-business-toggle-styles__container`,
	"button": `src-common-form-client-base-client-business-toggle-client-business-toggle-styles__button`,
	"active": `src-common-form-client-base-client-business-toggle-client-business-toggle-styles__active`
};
export default ___CSS_LOADER_EXPORT___;
