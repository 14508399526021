import React, { useState, ReactNode } from "react";
import { CpButton, CpModal, CpSwitch, CpCase } from "canopy-styleguide!sofe";
import { ModalTabBody } from "./modal-tab-body.component";
import { CustomizeColumnsBody } from "./customize-columns/customize-columns-body.component";
import { EditFilterViewsBody } from "./edit-filter-views/edit-filter-views-body.component";
import { Column, Section, FilterView } from "./types";

interface CustomizeFilterViewsModalProps {
  columns: Column[];
  debounceOnColumnVisibilityChange: (filterView: FilterView, visibleColumns: string[]) => void;
  filterViews: FilterView[];
  getIsFilterApplied: (filterView: FilterView, column: Column) => boolean;
  initialFilterView: FilterView;
  onAfterClose?: () => void;
  onClose: (args: { filterViewIdsMarkedForDelete: string[] }) => void;
  onFilterViewDuplicate: (filterView: FilterView) => void;
  onFilterViewOrderChange: (orderedViewIds: string[]) => void;
  onFilterViewVisibilityChange: (params: { filterView: FilterView; visible: boolean }) => void;
  renderRenameDropdown: (params: { filterView: FilterView; closeSubView: () => void }) => ReactNode;
  renderShareListDropdown: (params: { filterView: FilterView; closeSubView: () => void }) => ReactNode;
  sections: Section[];
  show: boolean;
  title: string;
}

export function CustomizeFilterViewsModal({
  columns,
  debounceOnColumnVisibilityChange,
  filterViews,
  getIsFilterApplied,
  initialFilterView,
  onAfterClose,
  onClose,
  onFilterViewDuplicate,
  onFilterViewOrderChange,
  onFilterViewVisibilityChange,
  renderRenameDropdown,
  renderShareListDropdown,
  sections,
  show,
  title,
}: CustomizeFilterViewsModalProps) {
  const [activeTabId, setActiveTabId] = useState("customize-columns");
  const tabs = [
    { id: "customize-columns", name: "Customize columns", icon: "misc-table" },
    { id: "edit-filter-views", name: "Edit filter views", icon: "af-reorder" },
  ];

  const [markedForDelete, setMarkedForDelete] = useState<string[]>([]);

  function onDelete(filterView: any) {
    setMarkedForDelete((prev) => [...prev, filterView.id]);
  }

  function onRestore(filterView: any) {
    setMarkedForDelete((prev) => prev.filter((id) => id !== filterView.id));
  }

  function close() {
    onClose({ filterViewIdsMarkedForDelete: markedForDelete });
  }

  return (
    <CpModal show={show} onClose={close} onAfterClose={onAfterClose} width="832">
      <CpModal.Header title={title} />
      <ModalTabBody tabs={tabs} activeTabId={activeTabId} onTabChange={setActiveTabId}>
        <CpSwitch expression={activeTabId}>
          <CpCase value="customize-columns">
            <CustomizeColumnsBody
              initialFilterView={initialFilterView}
              filterViews={filterViews}
              columns={columns}
              sections={sections}
              debounceOnColumnVisibilityChange={debounceOnColumnVisibilityChange}
              getIsFilterApplied={getIsFilterApplied}
            />
          </CpCase>
          <CpCase value="edit-filter-views">
            <EditFilterViewsBody
              filterViews={filterViews}
              markedForDelete={markedForDelete}
              onOrderChange={onFilterViewOrderChange}
              onVisibilityChange={onFilterViewVisibilityChange}
              onDuplicate={onFilterViewDuplicate}
              onDelete={onDelete}
              onRestore={onRestore}
              renderRenameDropdown={renderRenameDropdown}
              renderShareListDropdown={renderShareListDropdown}
            />
          </CpCase>
        </CpSwitch>
      </ModalTabBody>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={close}>
          Done
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
