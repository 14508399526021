import React, { useMemo } from "react";
import { CpCheckbox, CpCheckboxGroup, CpWell } from "canopy-styleguide!sofe";
import { cssModules, useCssModules } from "inline-css-modules-react";

type ColumnsSectionProps = {
  name: string;
  readOnly: boolean;
  columns: { id: string; name: string }[];
  checkedColumns: string[];
  onColumnsChange: (columnIds: string[]) => void;
  selectedFilterView: any;
  getIsFilterApplied: (filterView: any, column: { id: string }) => boolean;
};

export function ColumnsSection({
  name,
  readOnly,
  columns,
  checkedColumns,
  onColumnsChange,
  selectedFilterView,
  getIsFilterApplied,
}: ColumnsSectionProps) {
  useCssModules(css);

  const onCheckboxChange = (columnIds: string[]) => {
    onColumnsChange(columnIds);
  };

  const onSelectAllChange = (checked: boolean) => {
    let columnIds: string[] = [];
    if (checked) {
      columnIds = columns?.map((column) => column.id);
    }
    onCheckboxChange(columnIds);
  };

  const selectAll = useMemo(() => {
    if (checkedColumns?.length === columns?.length) {
      return true;
    } else if (checkedColumns?.length === 0) {
      return false;
    } else {
      return "indeterminate";
    }
  }, [checkedColumns?.length, columns?.length]);

  return (
    <CpWell className="cp-p-24" level={1}>
      <div className="cp-mb-16 cp-body cp-wt-semibold">{name}</div>
      {!!columns?.length && (
        <div>
          <div>
            <CpCheckbox className={s.checkbox} checked={selectAll} onChange={onSelectAllChange} disabled={readOnly}>
              Select All
            </CpCheckbox>
          </div>
          <CpCheckboxGroup className={s.checkboxGroup} value={checkedColumns} onChange={onCheckboxChange}>
            {columns.map((column) => {
              return (
                <CpCheckboxGroup.Item className={s.checkbox} id={column.id} key={column.id} disabled={readOnly}>
                  <div>{column.name}</div>
                  {getIsFilterApplied?.(selectedFilterView, column) && (
                    <div className="cp-caption cp-color-app-secondary-text">
                      <i>Filters applied</i>
                    </div>
                  )}
                </CpCheckboxGroup.Item>
              );
            })}
          </CpCheckboxGroup>
        </div>
      )}
    </CpWell>
  );
}

const { s, css } = cssModules`
  .checkbox {
    margin-bottom: 0.8rem;
    flex: 0 1 16rem;
    padding-right: 1.6rem;
    min-height: 4rem;
    padding-top: 0 !important;
  }
  .checkboxGroup {
    display: flex;
    flex-flow: row wrap;
  }
`;
