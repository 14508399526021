import React from "react";
// project
import { InputDecorator } from "src/forms-lib";
import { ClientOwnerInput } from "src/common/form/client/base";

function ClientOwner(props) {
  const { value, updateValue } = props;

  return (
    <div className="cps-margin-bottom-16 width48">
      <ClientOwnerInput value={value} onChange={updateValue} />
    </div>
  );
}

export default InputDecorator(ClientOwner);
