import React, { useMemo } from "react";
import { CpSelectSingle, CpLabel } from "canopy-styleguide!sofe";
import { useDebouncedSearch } from "src/common/use-debounced-search.hook";
import useContactsQuery from "src/common/queries/use-contacts-query.hooks";
import { TContact } from "src/common/types";

type ContactSelectProps = {
  label?: string;
  className?: string;
  required?: boolean;
  value: TContact | null | undefined;
  onChange: (value: TContact) => void;
  filterContacts?: (contacts: any[]) => any[];
  error?: string;
  triggerRef?: React.RefCallback<any>;
  includeArchived?: boolean;
};

export function ContactSelect({
  onChange,
  value,
  label,
  required = false,
  filterContacts,
  className = "",
  error,
  triggerRef,
  includeArchived = false,
}: ContactSelectProps) {
  const { search, debouncedSearch, updateSearch } = useDebouncedSearch();
  const { contacts } = useContactsQuery(
    {
      page: 1,
      limit: "50",
      search: debouncedSearch,
      visibleFields: ["name", "first_name", "last_name", "primary_email", "primary_address", "primary_phone"],
      filters: includeArchived
        ? {}
        : {
            is_archived: [
              {
                equal_to: false,
              },
            ],
          },
    },
    {
      staleTime: 10000, // 10 secs
    }
  );

  const filteredContacts = useMemo(() => {
    if (filterContacts && contacts) {
      return filterContacts(contacts);
    } else {
      return contacts || [];
    }
  }, [contacts, filterContacts]);

  return (
    <div className={className}>
      <CpLabel required={required}>{label}</CpLabel>
      <CpSelectSingle
        triggerIsBlock
        required={required}
        searchFilter
        searchValue={search}
        searchOnChange={updateSearch}
        onChange={onChange}
        value={value}
        data={filteredContacts}
        transformData={(val: any) => ({
          ...val,
          id: val.id,
          name: val.name || `${val.first_name} ${val.last_name}`,
          subName: val.primary_email || "",
        })}
        error={error}
        triggerRef={triggerRef}
      />
    </div>
  );
}
