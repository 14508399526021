import React from "react";
import { TAddressValue } from "src/common/types";

type AddressProps = {
  address: TAddressValue;
};

export function Address({ address }: AddressProps) {
  const { address_1, address_2, locality, region, postal_code, country } = address;
  return (
    <>
      {address_1 && <div className="cp-ellipsis">{address_1}</div>}
      {address_2 && <div className="cp-ellipsis">{address_2}</div>}
      <div className="cp-ellipsis">
        {[locality, region].filter(Boolean).join(", ")}
        {postal_code && ` ${postal_code}`}
      </div>
      {country && <div className="cp-ellipsis">{country}</div>}
    </>
  );
}
