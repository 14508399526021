// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-list-kabob-menu-styles__divider {
  height: 0.1rem;
  background-color: var(--cp-color-app-border);
  margin: 0.4rem 0rem 0.4rem 0rem;
}

.src-client-list-kabob-menu-styles__displayAsButton {
  /* Keeping the min height to 40px to account for the "checked" icon that changes the option height */
  min-height: 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/client-list/kabob-menu.styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,4CAA4C;EAC5C,+BAA+B;AACjC;;AAEA;EACE,oGAAoG;EACpG,gBAAgB;AAClB","sourcesContent":[".divider {\n  height: 0.1rem;\n  background-color: var(--cp-color-app-border);\n  margin: 0.4rem 0rem 0.4rem 0rem;\n}\n\n.displayAsButton {\n  /* Keeping the min height to 40px to account for the \"checked\" icon that changes the option height */\n  min-height: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `src-client-list-kabob-menu-styles__divider`,
	"displayAsButton": `src-client-list-kabob-menu-styles__displayAsButton`
};
export default ___CSS_LOADER_EXPORT___;
