import React, { lazy, Suspense, useState } from "react";
import { CpModal, CpButton, modalService, cdnImage } from "canopy-styleguide!sofe";
import useQboIntegrationQuery, { invalidateQboIntegration } from "src/client-list/hooks/use-qbo-integration-query.hook";

const IntegrationSyncErrorModal = lazy(() =>
  SystemJS.import("integrations-ui!sofe").then((module) => module.getIntegrationSyncErrorModal())
);

export default function QboCell({ resource: client }) {
  const [showIntegrationError, setShowIntegrationError] = useState(false);
  const { qboIntegrationInfo } = useQboIntegrationQuery();

  const { third_party_url: url = "", third_party_error = null, third_party_id } = client;

  const renderClientErrorModal = () => {
    modalService.render(QboErrorModalComponent, { url, third_party_error });
  };

  if (third_party_id && qboIntegrationInfo.disconnect_error) {
    return (
      <div>
        <div onClick={() => setShowIntegrationError(true)} className="cp-flex-center">
          <div className="cp-color-app-error-text cp-mr-4">Error</div>
          <CpButton icon="alert-triangle-open-large" aria-label="Warning" fill="var(--cp-color-app-secondary-text)" />
        </div>
        <Suspense fallback={<></>}>
          <IntegrationSyncErrorModal
            show={showIntegrationError}
            close={() => setShowIntegrationError(false)}
            integrationInfo={qboIntegrationInfo}
            checkIntegrationAuth={() => invalidateQboIntegration()}
          />
        </Suspense>
      </div>
    );
  } else if (third_party_error) {
    return (
      <div>
        <div onClick={renderClientErrorModal} className="cp-flex-center">
          <div className="cp-color-app-error-text cp-mr-4">Error</div>
          <CpButton icon="alert-triangle-open-large" aria-label="Warning" fill="var(--cp-color-app-secondary-text)" />
        </div>
      </div>
    );
  } else if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer noopener">
        {third_party_id ? "Access" : "Not linked"}
      </a>
    );
  } else {
    return <i>{third_party_id ? "Linked" : "Not linked"}</i>;
  }
}
QboCell.overrideEmptyCell = true;

export const QboErrorModalComponent = ({ url, third_party_error }) => {
  return (
    <>
      <CpModal.Header title="Client Sync Error" />
      <CpModal.Body>
        <img src={cdnImage("qbo_logo_light_horizontal.png")} height="50rem" alt="QuickBooks Online Logo" />
        <div className="cp-p-8">
          {third_party_error?.user_message ||
            "QuickBooks Online encountered an error while syncing this client. Please check to verify that there aren't any duplicate display names. If the issue continues, please contact support."}
        </div>
      </CpModal.Body>
      {url && (
        <CpModal.Footer>
          <CpButton btnType="primary" anchor href={url} target="_blank">
            View client
          </CpButton>
        </CpModal.Footer>
      )}
    </>
  );
};
