import React, { useState } from "react";
import { CpModal, CpButton, CpEmptyState, constants } from "canopy-styleguide!sofe";

type ClientLimitModalProps = {
  clientLimit: number;
  onClose?: () => void;
};

export function ClientLimitModal({ onClose, clientLimit }: ClientLimitModalProps) {
  const [show, setShow] = useState(true);
  const close = () => setShow(false);

  return (
    <CpModal show={show} onClose={close} onAfterClose={onClose}>
      <CpModal.Header title="Client Limit Reached" />
      <CpModal.Body>
        <CpEmptyState
          img="es_clients"
          text="Time to upgrade"
          subText={`You've hit your maximum of ${clientLimit} clients for your pricing tier. Call ${constants.canopyPhone} to upgrade.`}
          cta={
            <CpButton btnType="flat" anchor href="#/global-settings/account-management">
              View my subscription
            </CpButton>
          }
        />
      </CpModal.Body>
    </CpModal>
  );
}
