import React from "react";
import { Control, Controller } from "react-hook-form";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { MultiField } from "./multi-field.component";
import { AddressInputs } from "src/common/form/base/address-inputs.component";

type MultiAddressFieldProps = {
  fieldName: string;
  control: Control;
  watch: (field: string) => any;
  maxAmount?: number;
  addressTypes?: AddressType[];
};

type AddressType = {
  id: string;
  name: string;
};

const defaultAddressTypes = [
  { id: "physical", name: "Physical address" },
  { id: "work", name: "Work address" },
  { id: "billing", name: "Billing address" },
  { id: "shipping", name: "Shipping address" },
  { id: "other", name: "Other address" },
];

export function MultiAddressField({
  fieldName,
  control,
  watch,
  maxAmount,
  addressTypes: overrideAddressTypes,
}: MultiAddressFieldProps) {
  const addressTypes = overrideAddressTypes || defaultAddressTypes;
  return (
    <div className="flex flex-col cp-gap-16">
      <MultiField
        fieldName={fieldName}
        control={control}
        watch={watch}
        resourceName="address"
        maxAmount={maxAmount}
        appendValue={{ country: "United States" }}
      >
        {({ index, renderOptions }) => (
          <div className="flex flex-col cp-gap-16 cp-mt-16">
            <div className="flex cp-gap-16 justify-between">
              <Controller
                name={`${fieldName}.${index}.type`}
                control={control}
                defaultValue={addressTypes[0].id}
                render={({ field: { value, onChange } }) => (
                  <CpSelectSingle
                    triggerIsBlock
                    data={addressTypes}
                    value={addressTypes.find((type) => type.id === value)}
                    onChange={(val: any) => onChange(val.id)}
                  />
                )}
              />
              {renderOptions()}
            </div>
            <div>
              <Controller
                control={control}
                name={`${fieldName}.${index}`}
                render={({ field: { onChange, value } }) => <AddressInputs value={value} onChange={onChange} />}
              />
            </div>
          </div>
        )}
      </MultiField>
    </div>
  );
}
