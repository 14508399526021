import { useQuery } from "src/react-query";
import { getSources } from "src/resources/source.resource";

export function useSourcesQuery() {
  const query = useQuery<any>({
    queryKey: ["sources"],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        getSources().subscribe(resolve, reject);
      });
    },
    staleTime: 60000,
  });

  return {
    sourcesQuery: query,
    sources: query.data,
  };
}
