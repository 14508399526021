// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-users-teams-section-users-teams-section-styles__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;
  margin-right: 1.6rem;
}
`, "",{"version":3,"sources":["webpack://./src/common/users-teams-section/users-teams-section.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,oBAAoB;AACtB","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 1.6rem;\n  margin-right: 1.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-common-users-teams-section-users-teams-section-styles__container`
};
export default ___CSS_LOADER_EXPORT___;
