import React, { ForwardedRef, forwardRef } from "react";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { Controller, UseFormWatch } from "react-hook-form";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import FieldSection from "src/common/field-section.component";
import { DateInputField } from "src/common/form/date-input-field.component";
import { BusinessTypeSelect, IndustrySelect } from "src/common/form/client/base";
import { MultiAddressField } from "src/common/form/contact/multi-address-field.component";
import { MultiEmailField } from "src/common/form/contact/multi-email-field.component";
import { MultiPhoneField } from "src/common/form/contact/multi-phone-field.component";
import { EinField } from "src/common/form/client/ein-field.component";
import { BusinessNameField } from "src/common/form/client/business-name-field.component";

interface BusinessInfoSectionProps {
  control: any;
  watch: UseFormWatch<any>;
}

export const BusinessInfoSection = forwardRef(
  ({ control, watch }: BusinessInfoSectionProps, ref: ForwardedRef<any>) => {
    useCssModules(css);

    return (
      <FieldSection
        label="Business Info"
        ref={ref}
        labelTail={
          <CpTooltip text="The business contact information will be used for bulk email. Canopy will default to the primary contact if no business information is provided.">
            <CpIcon name="information-circle-open-small" />
          </CpTooltip>
        }
      >
        <BusinessNameField control={control} fieldName="business_name" />
        <div className={s.fieldGrid}>
          <DateInputField control={control} fieldName="date_established" label="Date est./inc." />
          <EinField control={control} />
          <Controller
            name="business_type"
            control={control}
            render={({ field: { value, onChange } }) => <BusinessTypeSelect value={value} onChange={onChange} />}
          />
          <Controller
            name="industry"
            control={control}
            render={({ field: { value, onChange } }) => <IndustrySelect value={value} onChange={onChange} />}
          />
        </div>
        <MultiEmailField
          fieldName="emails"
          control={control}
          watch={watch}
          emailTypes={[
            { id: "business", name: "Business email" },
            { id: "personal", name: "Personal email" },
            { id: "other", name: "Other email" },
          ]}
        />
        <MultiPhoneField
          fieldName="phones"
          control={control}
          watch={watch}
          phoneTypes={[
            { id: "business", name: "Business phone", showExtension: true },
            { id: "mobile", name: "Mobile phone", showExtension: false },
            { id: "fax", name: "Fax phone", showExtension: true },
            { id: "other", name: "Other phone", showExtension: true },
          ]}
        />
        <MultiAddressField
          fieldName="addresses"
          control={control}
          watch={watch}
          addressTypes={[
            { id: "physical", name: "Physical address" },
            { id: "billing", name: "Billing address" },
            { id: "shipping", name: "Shipping address" },
            { id: "other", name: "Other address" },
          ]}
        />
      </FieldSection>
    );
  }
);

const { css, s } = cssModules`
  .fieldGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem;
  }
`;
