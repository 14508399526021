import React, { useState } from "react";
import { CpIcon } from "canopy-styleguide!sofe";
import { SingleItem } from "./single-item.component";
import { TAddressValue } from "src/common/types";
import { Address } from "src/common/address.component";
import { TAddressFieldData, TFieldData } from "../../view-contact.helpers";

type AccordionProps = {
  values: TFieldData[] | TAddressFieldData[];
};
const isString = (value: string | undefined | TAddressValue): value is string => typeof value === "string";

export function Accordion({ values }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  if (!values || values.length === 0) {
    return null;
  }

  const showAccordion = values.length > 1;

  const mappedValues = values.map((v) => ({
    label: v.label,
    value: isString(v.value) ? v.value : <Address address={v.value} />,
  }));

  return (
    <div>
      <div className="cp-flex-spread-center" onClick={() => setIsOpen(!isOpen)}>
        <SingleItem label={mappedValues[0].label} value={mappedValues[0].value} />
        {showAccordion && <CpIcon name={`caret-large-${isOpen ? "up" : "down"}`} />}
      </div>
      <div>
        {showAccordion && isOpen && (
          <div className="cp-mt-16 cp-pl-16 cp-flex-column cp-gap-16">
            {mappedValues.slice(1).map((item, index) => (
              <SingleItem key={index} label={item.label} value={item.value} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
