import React, { ForwardedRef, forwardRef } from "react";
import { CpRadioField, CpRadio, CpIcon } from "canopy-styleguide!sofe";
import FieldSection from "src/common/field-section.component";

interface ClientTypeSectionProps {
  control: any;
}

export const ClientTypeSection = forwardRef(({ control }: ClientTypeSectionProps, ref: ForwardedRef<any>) => {
  return (
    <FieldSection label="Client Type" ref={ref}>
      <CpRadioField fieldName="is_business" control={control} name="isBusiness" inline>
        <CpRadio.Item id={false}>
          <Label icon="misc-individual-client" text="Individual" />
        </CpRadio.Item>
        <CpRadio.Item id={true}>
          <Label icon="misc-buildings" text="Business" />
        </CpRadio.Item>
      </CpRadioField>
    </FieldSection>
  );
});

function Label({ icon, text }: { icon: string; text: string }) {
  return (
    <div className="flex items-center cp-gap-4">
      <CpIcon name={icon} />
      {text}
    </div>
  );
}
