import React from "react";
import { useHasAccess } from "cp-client-auth!sofe";
import { CpIcon } from "canopy-styleguide!sofe";
import RoleAssignmentSelect from "src/form/role-assignment-select/role-assignment-select.component";
import styles from "./roles-section.styles.css";

export default function RolesSection({
  value,
  onChange,
  roles,
  requiresAccess = true,
  showCurrentUserNotAssigned = false,
}) {
  const hasAssignAccess = useHasAccess("clients_assign_team_members");
  const disabled = !(requiresAccess ? hasAssignAccess : true);

  return (
    <div>
      {showCurrentUserNotAssigned && (
        <div className="cp-mb-4 cp-caption">
          <CpIcon name="information-circle-open-small" />
          <i>Note: You are currently not assigned to this client and will be unable to access their client record.</i>
        </div>
      )}
      <div className={styles.container}>
        {roles?.map((role) => (
          <RoleAssignmentSelect
            key={role.id}
            label={role.label}
            value={(typeof value === "object" ? value?.[role.id] : value(role.id))?.filter((item) => !!item) || []}
            onChange={(val) => onChange(val, role.id)}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
}
