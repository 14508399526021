// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-dedupe-modal-merge-card-table-container-table-container-styles__tableContainer {
  border: 1px solid var(--cp-color-table-border);
  border-radius: 5px;
  max-width: 1024px;
}

.src-dedupe-modal-merge-card-table-container-table-container-styles__tableRow {
  border-radius: initial;
  border-bottom: 1px solid var(--cp-color-table-border);
  vertical-align: middle;
  background-color: var(--cp-color-table-bg);
}

.src-dedupe-modal-merge-card-table-container-table-container-styles__fixedColumn {
  width: 150px;
  background-color: var(--cp-color-table-bg);
  font-weight: bold;
  color: var(--cp-color-app-secondary-text);
  border-right: 1px solid var(--cp-color-table-border);
}

.src-dedupe-modal-merge-card-table-container-table-container-styles__tableRow td {
  padding: 0 16px;
  white-space: nowrap;
}

.src-dedupe-modal-merge-card-table-container-table-container-styles__tableRow.src-dedupe-modal-merge-card-table-container-table-container-styles__heading td {
  height: 32px;
  color: var(--cp-color-app-secondary-text);
}

.src-dedupe-modal-merge-card-table-container-table-container-styles__tableRow.src-dedupe-modal-merge-card-table-container-table-container-styles__duplicate td {
  height: 56px;
  color: var(--cp-color-app-secondary-text);
}

.src-dedupe-modal-merge-card-table-container-table-container-styles__tableRow:last-of-type {
  border-bottom: initial !important;
}

.src-dedupe-modal-merge-card-table-container-table-container-styles__rowBadge {
  margin-left: 8px;
  background: lightgray;
  padding: 0px 4px 0px 4px;
  font-size: smaller;
  font-weight: normal;
  cursor: default;
  border-radius: 5px;
}

.src-dedupe-modal-merge-card-table-container-table-container-styles__scrollingCell {
  vertical-align: top;
  background-color: var(--cp-color-table-bg);
}

.src-dedupe-modal-merge-card-table-container-table-container-styles__matchingColumn {
  font-weight: bold;
  color: var(--cp-color-app-secondary-text);
}
`, "",{"version":3,"sources":["webpack://./src/dedupe-modal/merge-card/table-container/table-container.styles.css"],"names":[],"mappings":"AAAA;EACE,8CAA8C;EAC9C,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,qDAAqD;EACrD,sBAAsB;EACtB,0CAA0C;AAC5C;;AAEA;EACE,YAAY;EACZ,0CAA0C;EAC1C,iBAAiB;EACjB,yCAAyC;EACzC,oDAAoD;AACtD;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,yCAAyC;AAC3C;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,wBAAwB;EACxB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,0CAA0C;AAC5C;;AAEA;EACE,iBAAiB;EACjB,yCAAyC;AAC3C","sourcesContent":[".tableContainer {\n  border: 1px solid var(--cp-color-table-border);\n  border-radius: 5px;\n  max-width: 1024px;\n}\n\n.tableRow {\n  border-radius: initial;\n  border-bottom: 1px solid var(--cp-color-table-border);\n  vertical-align: middle;\n  background-color: var(--cp-color-table-bg);\n}\n\n.fixedColumn {\n  width: 150px;\n  background-color: var(--cp-color-table-bg);\n  font-weight: bold;\n  color: var(--cp-color-app-secondary-text);\n  border-right: 1px solid var(--cp-color-table-border);\n}\n\n.tableRow td {\n  padding: 0 16px;\n  white-space: nowrap;\n}\n\n.tableRow.heading td {\n  height: 32px;\n  color: var(--cp-color-app-secondary-text);\n}\n\n.tableRow.duplicate td {\n  height: 56px;\n  color: var(--cp-color-app-secondary-text);\n}\n\n.tableRow:last-of-type {\n  border-bottom: initial !important;\n}\n\n.rowBadge {\n  margin-left: 8px;\n  background: lightgray;\n  padding: 0px 4px 0px 4px;\n  font-size: smaller;\n  font-weight: normal;\n  cursor: default;\n  border-radius: 5px;\n}\n\n.scrollingCell {\n  vertical-align: top;\n  background-color: var(--cp-color-table-bg);\n}\n\n.matchingColumn {\n  font-weight: bold;\n  color: var(--cp-color-app-secondary-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `src-dedupe-modal-merge-card-table-container-table-container-styles__tableContainer`,
	"tableRow": `src-dedupe-modal-merge-card-table-container-table-container-styles__tableRow`,
	"fixedColumn": `src-dedupe-modal-merge-card-table-container-table-container-styles__fixedColumn`,
	"heading": `src-dedupe-modal-merge-card-table-container-table-container-styles__heading`,
	"duplicate": `src-dedupe-modal-merge-card-table-container-table-container-styles__duplicate`,
	"rowBadge": `src-dedupe-modal-merge-card-table-container-table-container-styles__rowBadge`,
	"scrollingCell": `src-dedupe-modal-merge-card-table-container-table-container-styles__scrollingCell`,
	"matchingColumn": `src-dedupe-modal-merge-card-table-container-table-container-styles__matchingColumn`
};
export default ___CSS_LOADER_EXPORT___;
