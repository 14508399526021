import React, { useEffect, useMemo, useState, useRef } from "react";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { CpToggle, cdnImage, CpWell, CpRadio, CpSelectSingle, CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import { useWatch } from "react-hook-form";
import { useController, useFormContext } from "react-hook-form";
import { genQueryKey, useQuery } from "src/react-query";
import { always } from "kremling";

import { TContact } from "src/common/types";
import type { IntegrationInfoProps } from "src/common/integration.d.ts";
import { getAvailableContacts, getAvailableContactsForClient } from "src/resources/integrations.resource.js";
import { forkJoin, of } from "rxjs";

type IntegrationsSectionProps = {
  clientId?: string;
  integrationInfo: IntegrationInfoProps | undefined;
  isBusiness: boolean;
  isEdit: boolean;
  originalQboContactId: string | undefined;
  setOriginalQboContactId: (id: string | undefined) => void;
  setSyncClientToIntegration: (checked: boolean) => void;
  setSyncMethod: (syncMethod: "create-new" | "sync-existing") => void;
  syncClientToIntegration: boolean;
  syncMethod: "create-new" | "sync-existing";
  thirdPartyUrl?: string;
};

type TAvailableContact = {
  id: string;
  is_available: boolean;
  name: string;
  selected_contact: boolean;
};

export function IntegrationsSection({
  clientId,
  integrationInfo,
  isBusiness,
  isEdit,
  originalQboContactId,
  setOriginalQboContactId,
  setSyncClientToIntegration,
  setSyncMethod,
  syncClientToIntegration,
  syncMethod,
  thirdPartyUrl,
}: IntegrationsSectionProps) {
  useCssModules(css);
  const defaultContactSet = useRef(false);
  const [search, updateSearch] = useState("");
  const [contactsList, setContactsList] = useState<TContact[]>([]);
  const formContacts = useWatch({ name: "contacts" });
  const thirdPartyContact = useWatch({ name: "third_party_contact" });
  const primaryContact =
    formContacts?.find((contact: TContact) => contact.contact_type === "primary") ||
    formContacts?.find((contact: TContact) => contact.contact_type === undefined);
  const formattedFormContacts = useMemo(
    () => formContacts?.map((fc: { contact: TContact }) => fc.contact)?.filter((mc: TContact) => !!mc?.id) || [],
    [formContacts]
  );
  const showQboWell = integrationInfo?.connected && !!integrationInfo?.clients?.sync_status.synced_at;

  const { control, setValue, clearErrors } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    name: "third_party_contact",
    control,
    rules: {
      required: !syncClientToIntegration || isBusiness || !showQboWell ? false : "Contact selection required",
    },
  });

  const availableContactsQueryEnabled = !!(
    integrationInfo?.id &&
    !isBusiness &&
    (formattedFormContacts?.length || clientId)
  );
  const query = useQuery({
    queryKey: genQueryKey("accountIntegrationAvailableContacts", {
      clientId,
      isEdit,
      id: integrationInfo?.id,
      type: integrationInfo?.type,
      contacts: formattedFormContacts.map((fc: TContact) => ({ id: fc.id, name: fc.name })),
    }),
    enabled: availableContactsQueryEnabled,
    queryFn: () => {
      return new Promise<TAvailableContact[]>((resolve, reject) => {
        const addedContacts = formattedFormContacts.filter((fc: TContact) => !fc.phones);
        const obs1 = isEdit
          ? getAvailableContactsForClient(integrationInfo!.id, clientId, integrationInfo!.type)
          : of([]);
        const obs2 = addedContacts?.length
          ? getAvailableContacts(
              integrationInfo!.id,
              integrationInfo!.type,
              addedContacts.map((fc: TContact) => ({ id: fc.id, name: fc.name }))
            )
          : of([]);
        forkJoin([obs1, obs2]).subscribe(([existingContacts, newContacts]: any) => {
          //This is not an any type, but I couldn't get it to work with TAvailableContact[][]
          return resolve([...existingContacts, ...newContacts]);
        }, reject);
      });
    },
    staleTime: 30000, // 30 secs
  });
  const availableContacts = useMemo(() => query.data || [], [query.data]);

  useEffect(() => {
    if (contactsList?.length && !contactsList.find((c: TContact) => c.id === thirdPartyContact?.id)) {
      if (availableContacts.find((c) => c.id === primaryContact?.contact?.id)?.is_available) {
        // The contactList contacts don't always have the is_available property, so we need to find the contact in availableContacts which does have it
        setValue("third_party_contact", primaryContact?.contact, { shouldDirty: true });
        clearErrors("third_party_contact");
      } else {
        setValue("third_party_contact", undefined);
      }
    }
  }, [
    primaryContact?.contact,
    contactsList,
    primaryContact?.is_available,
    clearErrors,
    setValue,
    thirdPartyContact?.id,
    availableContacts,
  ]);

  useEffect(() => {
    if (!availableContactsQueryEnabled) {
      setContactsList([]);
      setValue("third_party_contact", undefined);
      return;
    }
    if (!originalQboContactId && availableContacts?.length) {
      setOriginalQboContactId(availableContacts.find((c) => c.selected_contact)?.id);
    }
    if (availableContacts?.length) {
      const mergeContactList = formattedFormContacts?.map((contact: TContact) => {
        const availableContact = availableContacts.find((ac) => ac.id === contact.id);
        return {
          ...contact,
          selected_contact: availableContact?.selected_contact || false,
          is_available: availableContact?.is_available || false,
        };
      });
      // prevents the default from overriding selections, only set this on mount
      if (!defaultContactSet.current && mergeContactList?.length) {
        setValue(
          "third_party_contact",
          mergeContactList.find((c: TAvailableContact) => c.selected_contact),
          { shouldDirty: true }
        );
        clearErrors("third_party_contact");
        defaultContactSet.current = true;
      }
      if (!mergeContactList?.length) {
        setValue("third_party_contact", undefined);
      }
      setContactsList(mergeContactList);
    }
  }, [
    availableContacts,
    clearErrors,
    formattedFormContacts,
    originalQboContactId,
    setOriginalQboContactId,
    setValue,
    availableContactsQueryEnabled,
  ]);

  const getTooltipText = () => {
    if (!syncClientToIntegration && isEdit) {
      return (
        <>
          <div>
            <strong>The QBO sync for this client is OFF</strong>
          </div>
          <div>
            To resume, navigate to the client record, and locate the integrations card on the left panel. Use the “edit
            sync” button to select a QBO match and resume the sync.
          </div>
        </>
      );
    } else if (syncClientToIntegration && isEdit) {
      return (
        <>
          <div>The selected contacts info is currently synced with the client record in QBO.</div>
          <br />
          <div>
            <strong>Synced info:</strong> name, phone number, email, and address
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            The selected contacts info will be synced with the client record into QBO. Only one contact can be
            associated with the QBO client sync.
          </div>
          <br />
          <div>
            <strong>Synced info:</strong> name, phone number, email, and address
          </div>
        </>
      );
    }
  };

  return showQboWell ? (
    <CpWell level={2} className={s.container}>
      <div
        className={always("cp-flex-spread-center cp-ph-4").m("cp-pb-8", !isBusiness || (isBusiness && !isEdit))}
        style={{ minWidth: "22rem" }}
      >
        <div className="cp-flex-center">
          <img src={cdnImage("qbo_logo_small_circle.svg")} height={24} className="cp-mr-8" />
          <strong>{isEdit ? "Client Sync" : "Sync client"}</strong>
        </div>
        {isEdit && isBusiness && (
          <div>
            {thirdPartyUrl ? (
              <a href={thirdPartyUrl} target="_blank" rel="noreferrer noopener">
                Access
              </a>
            ) : (
              <div className="cp-color-app-secondary-text cp-caption">No access</div>
            )}
          </div>
        )}
        {!isEdit && <CpToggle checked={syncClientToIntegration} onChange={setSyncClientToIntegration} />}
      </div>
      {(syncClientToIntegration || isEdit) && (
        <>
          {!isBusiness && (
            <>
              <hr />
              <div className="cp-pv-8 cp-ph-4" style={{ width: "100%" }}>
                <div>
                  Sync contact info{" "}
                  <CpTooltip text={getTooltipText()} position="right-start">
                    <CpIcon name="information-circle-open-small" fill="var(--cp-color-app-icon)" />
                  </CpTooltip>
                </div>
                <CpSelectSingle
                  data={contactsList || []}
                  disabled={!contactsList?.length || !syncClientToIntegration}
                  onChange={(contact: TContact) => {
                    clearErrors("third_party_contact");
                    setValue("third_party_contact", contact);
                  }}
                  fieldName="third_party_contact"
                  control={control}
                  placeholder={!syncClientToIntegration && isEdit ? "Client is not synced" : "Select contact"}
                  required
                  searchFilter
                  searchOnChange={updateSearch}
                  searchValue={search}
                  triggerIsBlock
                  value={!syncClientToIntegration && isEdit ? "" : thirdPartyContact}
                  error={error?.message}
                  transformData={(val: any) => {
                    return {
                      id: val.id,
                      name: val.name || `${val.first_name} ${val.last_name}`,
                      subName: val.primary_email || "",
                      disabled: !val.is_available,
                    };
                  }}
                />
                {syncClientToIntegration && !contactsList?.length && (
                  <div className="cp-color-app-secondary-text cp-caption" style={{ width: "18.6rem" }}>
                    <em>Contact selections will appear once added to the client record</em>
                  </div>
                )}
              </div>
            </>
          )}
          {!isEdit && (
            <>
              <hr />
              <div className="cp-pt-8 cp-ph-4" style={{ width: "100%" }}>
                <CpRadio onChange={setSyncMethod} value={syncMethod} name="sync-method">
                  <CpRadio.Item id="create-new">Create as new in QBO</CpRadio.Item>
                  <CpRadio.Item id="sync-existing" className="cp-mt-8">
                    Sync with existing QBO account
                  </CpRadio.Item>
                </CpRadio>
                {syncMethod === "sync-existing" && (
                  <div className="cp-color-app-secondary-text cp-caption cp-mt-4 cp-ml-24" style={{ width: "18.6rem" }}>
                    <em>After saving your client, you may select a QBO match</em>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </CpWell>
  ) : null;
}

const { s, css } = cssModules`
  .container {
    min-width: 28rem;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;
