import { CpTable } from "canopy-styleguide!sofe";
import { AssignedClientsCell } from "./cells/assigned-clients-cell.component";
import { MaskedCell } from "src/common/client-contact/masked-cell.component";
import { fetchSelectionOptions } from "src/resources/contacts.resource.ts";
import ContactNameCell from "./cells/contact-name-cell.component";
import { ClientPortalUserInvitedCell } from "./cells/client-portal-user-invited-cell.component";
import { PhoneNumberCell } from "src/common/client-contact/phone-number-cell.component";

export function getDefaultVisibleColumns() {
  return [
    "contactName",
    "firstName",
    "middleName",
    "lastName",
    "assignedClients",
    "ssn",
    "email",
    "phone",
    "occupation",
  ];
}

export const genContactListSchema = (subViewId) => {
  return {
    contactName: {
      fieldId: "name",
      label: "Contact Name",
      dataType: "string",
      sticky: "left",
      cell: {
        component: ContactNameCell,
        props: {
          disableEdit: subViewId === "archived" ? true : false,
        },
      },
      width: 250,
      filter: {
        props: {
          hideConditionOptions: ["include_empty"],
        },
      },
      sort: false,
    },
    firstName: {
      fieldId: "first_name",
      label: "First Name",
      section: "contact_attributes",
      dataType: "string",
      filter: {
        props: {
          hideConditionOptions: ["include_empty"],
        },
      },
      sort: true,
    },
    middleName: {
      fieldId: "middle_name",
      label: "Middle Name",
      section: "contact_attributes",
      dataType: "string",
      filter: true,
      sort: true,
    },
    lastName: {
      fieldId: "last_name",
      label: "Last Name",
      section: "contact_attributes",
      dataType: "string",
      filter: {
        props: {
          hideConditionOptions: ["include_empty"],
        },
      },
      sort: true,
    },
    email: {
      fieldId: "primary_email",
      label: "Email",
      section: "contact_attributes",
      dataType: "string",
      filter: true,
      sort: true,
    },
    assignedClients: {
      fieldId: "clients",
      label: "Assigned Clients",
      section: "contact_attributes",
      dataType: "array",
      cell: {
        component: AssignedClientsCell,
      },
      filter: {
        component: CpTable.SelectFilter,
        props: {
          getSelectionOptions: async () => {
            const results = await fetchSelectionOptions({ field: "clients" });
            return results;
          },
        },
      },
    },
    cpUserInvited: {
      fieldId: "cp_user_invited",
      label: "Client Portal User",
      section: "contact_attributes",
      dataType: "bool",
      filter: {
        component: CpTable.BoolFilter,
        props: {
          trueLabel: "Invite sent",
          falseLabel: "No invite sent",
        },
      },
      sort: true,
      cell: {
        component: ClientPortalUserInvitedCell,
      },
    },
    birthday: {
      fieldId: "birthdate",
      label: "Birthday",
      section: "contact_attributes",
      dataType: "date_iso",
      filter: {
        component: CpTable.DateFilter,
        props: {
          dateFormat: "MM-dd",
        },
      },
      sort: true,
    },
    country: {
      fieldId: "primary_country",
      label: "Country",
      dataType: "string",
      width: "md",
      section: "contact_attributes",
      filter: {
        component: CpTable.SelectFilter,
        props: {
          getSelectionOptions: async () => {
            const results = await fetchSelectionOptions({ field: "primary_country" });
            return results.map((r) => ({ id: r.name, name: r.name }));
          },
        },
      },
      sort: true,
    },
    locality: {
      fieldId: "primary_city",
      label: "City",
      dataType: "string",
      width: "md",
      section: "contact_attributes",
      filter: true,
      sort: true,
    },
    invite_sent: {
      fieldId: "invite_timestamp",
      label: "Invite Sent",
      dataType: "date_iso",
      width: "md",
      section: "client_portal_attributes",
      cell: {
        // TODO: Remove this when BE returns valid ISO strings
        formatValue: (value) => {
          return value?.split(" ")?.[0];
        },
      },
      filter: {
        component: CpTable.DateFilter,
      },
      sort: true,
    },
    last_login: {
      fieldId: "last_login",
      label: "Last Login",
      dataType: "date_iso",
      width: "md",
      section: "client_portal_attributes",
      cell: {
        // TODO: Remove this when BE returns valid ISO strings
        formatValue: (value) => {
          return value?.split(" ")?.[0];
        },
      },
      filter: {
        component: CpTable.DateFilter,
      },
      sort: true,
    },
    occupation: {
      fieldId: "occupation",
      label: "Occupation",
      section: "contact_attributes",
      dataType: "string",
      filter: true,
      sort: true,
    },
    phone: {
      fieldId: "primary_phone",
      label: "Phone",
      dataType: "string",
      width: "md",
      section: "contact_attributes",
      cell: {
        component: PhoneNumberCell,
      },
      filter: true,
      sort: true,
    },
    postal_code: {
      fieldId: "primary_zip",
      label: "Zip",
      dataType: "string",
      width: "sm",
      section: "contact_attributes",
      filter: true,
      sort: true,
    },
    region: {
      fieldId: "primary_state",
      label: "State",
      dataType: "string",
      width: "sm",
      section: "contact_attributes",
      filter: true,
      sort: true,
    },
    street_address: {
      fieldId: "primary_address",
      label: "Street Address",
      dataType: "string",
      width: "md",
      section: "contact_attributes",
      cell: {
        formatValue: (address) => address?.value?.address_1,
      },
      filter: true,
      sort: true,
    },
    ssn: {
      fieldId: "tin",
      label: "SSN",
      section: "contact_attributes",
      dataType: "string",
      cell: {
        component: MaskedCell,
      },
    },
    employer: {
      fieldId: "employer",
      label: "Employer",
      section: "contact_attributes",
      dataType: "string",
      sort: true,
      filter: true,
    },
  };
};
