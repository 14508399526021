import React from "react";
import { CpDropdown, CpIcon, CpArea } from "canopy-styleguide!sofe";
import { k, useCss } from "kremling";

export function AssignedClientsCell({ value: list }: { value: any[] }) {
  const scope = useCss(css);
  const numItemsHidden = list?.length - 1;

  if (list?.length === 0) {
    return "—";
  }
  return (
    <div className="cp-flex-center cp-ellipsis" {...scope} onClick={(e) => e.stopPropagation()}>
      <div className="flex-1 cp-ellipsis">
        {list?.length <= 1 ? (
          <a href={`#/client/${list[0].id}`} target="_blank" rel="noreferrer">
            {list[0].display_name || list[0].name || ""}
          </a>
        ) : (
          <div className="flex-1 cp-ellipsis">{list[0].display_name || list[0].name || ""}</div>
        )}
      </div>
      {list?.length > 1 && (
        <CpDropdown
          contentWidth={304}
          contentHeight={300}
          allowContentClicks
          appendTo="parent"
          renderWhenClosed={false}
          renderTrigger={({ toggle }: any) => (
            <button className="flex-none more-button cp-body-sm" onClick={toggle}>
              {`+${numItemsHidden} more`}
            </button>
          )}
          renderContent={({ close }: any) => (
            <div className="dropdown-content">
              <div className="cp-wt-semibold cp-body cp-p-16">Assigned Client Records</div>
              {list.map((item, i) => {
                const name = item.display_name || item.name || "";
                const hasAccess = item.has_access ?? true;
                return (
                  <CpArea
                    key={i}
                    onClick={() => hasAccess && window.open(`#/client/${item.id}`, "_blank", "noopener,noreferrer")}
                  >
                    <div className="cp-p-8 cp-ml-16 cp-flex cp-flex-spread-center">
                      <div className="cp-ellipsis cp-flex-column">
                        {hasAccess ? <a className="cp-ellipsis">{name}</a> : <div className="cp-ellipsis">{name}</div>}
                        <div className="cp-ellipsis cp-color-app-secondary-text cp-caption">
                          {item.is_primary ? `Primary Contact ${item.label ? " - " : ""}` : ""}
                          {item.label}
                        </div>
                      </div>
                      <CpIcon
                        name={item.user_invited_at ? "checkmark-circle-open-small" : "remove-circle-open-small"}
                        className="cp-mh-8 shrink-0"
                        fill={item.user_invited_at ? "var(--cp-color-app-success-text)" : "var(--cp-color-app-icon)"}
                      />
                    </div>
                  </CpArea>
                );
              })}
            </div>
          )}
        />
      )}
    </div>
  );
}

const css = k`
.more-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 0.4rem;
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

`;
