import React, { ForwardedRef, forwardRef, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useCssModules, cssModules } from "inline-css-modules-react";
import { CpTextarea, CpInputField } from "canopy-styleguide!sofe";
import FieldSection from "src/common/field-section.component";
import useCustomFieldsQuery from "src/common/queries/use-custom-fields-query.hook";
import { CustomField } from "src/common/form/client/base";

interface CustomFieldsSectionProps {
  control: any;
  isBusiness: boolean;
}

export const CustomFieldsSection = forwardRef(
  ({ control, isBusiness }: CustomFieldsSectionProps, ref: ForwardedRef<any>) => {
    useCssModules(css);
    const { customFields } = useCustomFieldsQuery();
    const filteredCustomFields = useMemo(
      () =>
        customFields?.filter(
          (field: any) =>
            field.client_type === (isBusiness ? "businesses" : "individuals") || field.client_type === "both"
        ) || [],
      [customFields, isBusiness]
    );

    return (
      <FieldSection label="Custom Fields" ref={ref}>
        {filteredCustomFields.length > 0 && (
          <div className={s.fieldGrid}>
            {filteredCustomFields.map((field: any) => (
              <Controller
                shouldUnregister
                key={field.field_id}
                name={`custom_fields.${field.field_id}`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CustomField field={field} value={value} onChange={onChange} />
                )}
              />
            ))}
          </div>
        )}
        <Controller
          name="notes"
          control={control}
          render={({ field: { value, onChange } }) => (
            <CpTextarea value={value} onChange={onChange} label="Additional information" rows={2} resize="vertical" />
          )}
        />
        {isBusiness && <CpInputField fieldName="contact_person" label="Contact person" maxLength={150} />}
      </FieldSection>
    );
  }
);

const { s, css } = cssModules`
  .fieldGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.6rem;
    column-gap: 3.2rem;
  }
`;
