import React from "react";
import { successToast } from "toast-service!sofe";
import { patchClients } from "src/client-list/client-list.resource";
import { handleError } from "src/error";
import ArchiveModal from "src/common/archive-modal.component";
import { clientQueries } from "src/queries";

export default function BulkArchiveModal({ onClose, filters, selection, search }) {
  function onArchive() {
    close();
    patchClients({
      search,
      filters,
      action: "archive",
      [selection.type]: selection.toArray(),
    }).subscribe(() => {
      const count = selection.totalSelected;
      successToast(`${count} client${count > 1 ? "s" : ""} will be archived. This may take some time.`);
      selection.deselectAll();
      // Wait a bit after the request to invalidate just in case it finished in time
      setTimeout(() => {
        clientQueries.invalidate();
      }, 1000);
    }, handleError);
  }

  return <ArchiveModal onArchive={onArchive} onClose={onClose} totalToArchive={selection.totalSelected} />;
}
