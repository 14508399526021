// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-field-section-styles__fieldSection {
  border: 0.1rem solid var(--cp-color-well-border);
  border-radius: var(--cp-form-border-radius);
  padding: 2.4rem;
}

.src-common-field-section-styles__errorSection {
  border-color: var(--cp-color-app-error-text);
}
`, "",{"version":3,"sources":["webpack://./src/common/field-section.styles.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,2CAA2C;EAC3C,eAAe;AACjB;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".fieldSection {\n  border: 0.1rem solid var(--cp-color-well-border);\n  border-radius: var(--cp-form-border-radius);\n  padding: 2.4rem;\n}\n\n.errorSection {\n  border-color: var(--cp-color-app-error-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldSection": `src-common-field-section-styles__fieldSection`,
	"errorSection": `src-common-field-section-styles__errorSection`
};
export default ___CSS_LOADER_EXPORT___;
