import React from "react";
import { Control } from "react-hook-form";
import { EmailField, EmailType } from "./email-field.component";
import { MultiField } from "./multi-field.component";

type MultiEmailFieldProps = {
  fieldName: string;
  control: Control;
  watch: (field: string) => any;
  emailTypes?: EmailType[];
};

export function MultiEmailField({ fieldName, control, watch, emailTypes }: MultiEmailFieldProps) {
  return (
    <div className="flex flex-col cp-gap-16">
      <MultiField fieldName={fieldName} control={control} watch={watch} resourceName="email">
        {({ index, renderOptions }) => (
          <div className="flex cp-gap-16 justify-between">
            <div className="grow">
              <EmailField fieldName={`${fieldName}.${index}`} control={control} emailTypes={emailTypes} />
            </div>
            {renderOptions()}
          </div>
        )}
      </MultiField>
    </div>
  );
}
