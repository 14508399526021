// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-modal-upgrade-clients-modal-upgrade-clients-card-styles__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.src-modal-upgrade-clients-modal-upgrade-clients-card-styles__icon {
  width: 9.6rem;
  height: 9.6rem;
  margin-bottom: 2.4rem;
}

.src-modal-upgrade-clients-modal-upgrade-clients-card-styles__text {
  margin-top: 0.6rem;
}
`, "",{"version":3,"sources":["webpack://./src/modal/upgrade-clients-modal/upgrade-clients-card.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".card {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.icon {\n  width: 9.6rem;\n  height: 9.6rem;\n  margin-bottom: 2.4rem;\n}\n\n.text {\n  margin-top: 0.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `src-modal-upgrade-clients-modal-upgrade-clients-card-styles__card`,
	"icon": `src-modal-upgrade-clients-modal-upgrade-clients-card-styles__icon`,
	"text": `src-modal-upgrade-clients-modal-upgrade-clients-card-styles__text`
};
export default ___CSS_LOADER_EXPORT___;
