import { useState, MutableRefObject, useRef, useCallback } from "react";
import { useDebounce } from "canopy-styleguide!sofe";
import type { NavSectionType } from "./types";

export function useNavScroll({
  sections,
  sectionRefs,
}: {
  sections: NavSectionType[];
  sectionRefs: MutableRefObject<Record<string, HTMLElement>>;
}) {
  // The last section id selected manually by the user
  const [selectedSectionId, setSelectedSectionId] = useState<string>(sections[0].id);

  // Section id that is most visible
  const [visibleSectionId, setVisibleSectionId] = useState<string>(sections[0].id);

  // Section id that is currently being auto scrolled to
  const [scrollingTo, setScrollingTo] = useState<string | null>(null);

  const showSelectedId = useRef<boolean>(false);
  const clearScrollingTo = useDebounce(
    () => {
      setScrollingTo(null);

      // Make sure we show the selected ID for a short time after the scroll
      showSelectedId.current = true;
      setTimeout(() => {
        // Resume showing the most visible section after the timeout.
        showSelectedId.current = false;
      }, 200);
    },
    100,
    {},
    []
  );

  const scrollToSection = useCallback(
    (sectionId: string) => {
      const sectionRef = sectionRefs.current[sectionId];
      setSelectedSectionId(sectionId);
      if (sectionRef) {
        setScrollingTo(sectionId);
        setTimeout(() => {
          sectionRef.scrollIntoView({ behavior: "smooth" });
        });
      }
    },
    [sectionRefs]
  );

  return {
    activeSectionId: scrollingTo || showSelectedId.current ? selectedSectionId : visibleSectionId,

    scrollToSection,

    onSectionScroll: (e: UIEvent) => {
      if (scrollingTo) {
        clearScrollingTo();
      }
      const element: HTMLElement | null = e.target as HTMLElement;
      if (!element) return;

      for (const section of sections) {
        const sectionRef = sectionRefs.current[section.id];
        if (sectionRef) {
          const totalScrollPercentage = element.scrollTop / (element.scrollHeight - element.clientHeight);
          const sectionPercentage = (sectionRef.offsetTop + sectionRef.clientHeight) / element.scrollHeight;

          if (totalScrollPercentage < sectionPercentage) {
            setVisibleSectionId(section.id);
            break;
          }
        }
      }
    },
  };
}
