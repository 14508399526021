// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-dedupe-modal-merge-card-merge-card-styles__mergeCard {
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: initial;
  min-width: initial;
}

.src-dedupe-modal-merge-card-merge-card-styles__cardBody {
  padding: 24px 20px;
}

.src-dedupe-modal-merge-card-merge-card-styles__actionStrip {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 26px;
  display: inline-block;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/dedupe-modal/merge-card/merge-card.styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;EAChB,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".mergeCard {\n  margin-top: 12px;\n  margin-bottom: 12px;\n  max-width: initial;\n  min-width: initial;\n}\n\n.cardBody {\n  padding: 24px 20px;\n}\n\n.actionStrip {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top: 26px;\n  display: inline-block;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mergeCard": `src-dedupe-modal-merge-card-merge-card-styles__mergeCard`,
	"cardBody": `src-dedupe-modal-merge-card-merge-card-styles__cardBody`,
	"actionStrip": `src-dedupe-modal-merge-card-merge-card-styles__actionStrip`
};
export default ___CSS_LOADER_EXPORT___;
