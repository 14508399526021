import { of } from "rxjs";
import { pluck, map, concatMap } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";
import { get } from "lodash";

export function patchClientRelationships(client, relationships) {
  return fetchAsObservable(`api/clients/${client.id}?include=users,clients,tags,client_for,client_sources`, {
    method: "PATCH",
    body: {
      clients: {
        relationships,
      },
    },
  }).pipe(pluck("clients"));
}

export function updateSource(client, prevClientResults) {
  const { source } = client;
  let updateClientSources$;
  if (source && (source.name || source.name === null)) {
    updateClientSources$ = fetchAsObservable(`api/client_sources`, {
      method: "POST",
      body: {
        client_sources: {
          name: source.name,
          client_id: prevClientResults.id,
        },
      },
    });
  } else {
    updateClientSources$ = of("");
  }

  const prevReferrals = get(prevClientResults, "relationships.clients", []).filter(
    (relationship) => relationship.relationship_type === "referral"
  );

  const deletedPrevReferrals = prevReferrals.map((referral) => ({
    id: referral.id,
    relationship_type: "referral",
    delete: true,
  }));
  const relationships = {};
  let clients = [];

  if (source && source.name === "Referral" && source.selected && source.selected.id) {
    if (deletedPrevReferrals.find((prevRef) => prevRef.id === source.selected.id) === undefined) {
      clients = [...deletedPrevReferrals, { id: source.selected.id, relationship_type: "referral" }];
    } else {
      clients = prevReferrals.map((referral) => {
        const newReferral = { id: referral.id, relationship_type: "referral" };
        if (newReferral.id !== source.selected.id) {
          newReferral.delete = true;
        }
        return newReferral;
      });
    }
  } else {
    clients = [...deletedPrevReferrals];
  }

  if (clients.length > 0) {
    relationships.clients = clients;
    return updateClientSources$.pipe(
      concatMap((results) => {
        return patchClientRelationships(prevClientResults, relationships);
      })
    );
  } else {
    return updateClientSources$.pipe(map((value) => prevClientResults));
  }
}
