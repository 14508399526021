import React, { useState } from "react";
import { CpButton, CpDropdown, CpIcon } from "canopy-styleguide!sofe";

export function FilterViewCardKabob(props: any) {
  const [currentSubView, setCurrentSubView] = useState(null);

  function closeSubView(closeMenu?: () => void) {
    closeMenu?.();
    setCurrentSubView(null);
  }

  return (
    <CpDropdown
      allowContentClicks
      appendTo="parent"
      contentWidth={currentSubView ? 330 : "sm"}
      position="bottom-end"
      onClose={() => closeSubView()}
      renderWhenClosed={false}
      renderTrigger={({ toggle }: any) => <CpButton aria-label="filter view menu" icon="misc-kabob" onClick={toggle} />}
      renderContent={({ close }: any) => (
        <DropdownContent
          {...props}
          closeDropdown={close}
          closeSubView={closeSubView}
          currentSubView={currentSubView}
          setCurrentSubView={setCurrentSubView}
        />
      )}
    />
  );
}

function DropdownContent({
  filterView,
  onVisibilityChange,
  onDuplicate,
  onDelete,
  onRestore,
  renderRenameDropdown,
  renderShareListDropdown,
  isMarkedForDelete,
  closeDropdown,
  closeSubView,
  currentSubView,
  setCurrentSubView,
}: any) {
  function executeAction(action: any, closeMenu: () => void) {
    if (action.execute) {
      action.execute({ filterView });
      closeMenu();
    } else if (action.renderSubView) {
      setCurrentSubView({ actionId: action.id, subViewProps: { filterView } });
    }
  }

  const allActions = [
    {
      id: "rename",
      label: "Rename",
      icon: "crud-pencil",
      renderSubView: renderRenameDropdown,
      visible: !filterView.isDefault && !filterView.readOnly,
    },
    {
      id: "share",
      label: "Share list",
      icon: "misc-share",
      renderSubView: renderShareListDropdown,
      visible: !filterView.isDefault && !filterView.readOnly,
    },
    {
      id: "hide",
      label: "Hide filter view",
      icon: "misc-closed-eye",
      execute: ({ filterView }: any) => onVisibilityChange({ filterView, visible: false }),
      visible: !filterView.isHidden && !filterView.isDefault,
    },
    {
      id: "show",
      label: "Show filter view",
      icon: "misc-open-eye",
      execute: ({ filterView }: any) => onVisibilityChange({ filterView, visible: true }),
      visible: filterView.isHidden && !filterView.isDefault,
    },
    {
      id: "duplicate",
      label: "Duplicate",
      icon: "crud-duplicate",
      execute: ({ filterView }: any) => onDuplicate(filterView),
      visible: true,
    },
    {
      id: "delete",
      label: "Delete",
      icon: "crud-trash-large",
      execute: ({ filterView }: any) => onDelete(filterView),
      visible: !filterView.isDefault && !filterView.readOnly && !isMarkedForDelete,
    },
    {
      id: "undoDelete",
      label: "Undo delete",
      icon: "af-undo",
      execute: ({ filterView }: any) => onRestore(filterView),
      visible: !filterView.isDefault && !filterView.readOnly && isMarkedForDelete,
    },
  ];
  const actions = allActions.filter((action) => action.visible);

  return (
    <>
      {currentSubView ? (
        actions
          .find((a) => a.id === currentSubView.actionId)
          ?.renderSubView({ closeSubView: () => closeSubView(closeDropdown), filterView })
      ) : (
        <div className="cp-select-list">
          {actions.map((action) => (
            <button key={action.id} onClick={() => executeAction(action, closeDropdown)}>
              <CpIcon
                className="cp-mr-8 cp-select-list__icon_left"
                name={action.icon}
                fill="var(--cp-color-app-icon)"
              />
              {action.label}
            </button>
          ))}
        </div>
      )}
    </>
  );
}
