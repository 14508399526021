import React, { useMemo, useState } from "react";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { useStatesQuery } from "../../queries/use-states-query.hook";

interface StateSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export function StateSelect({ value, onChange }: StateSelectProps) {
  const [search, setSearch] = useState("");
  const { states } = useStatesQuery();

  const options = useMemo(() => {
    if (!states) return [];
    return Object.entries(states).map(([key, value]) => ({
      id: key,
      name: value,
    }));
  }, [states]);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.id === value);
  }, [options, value]);

  return (
    <CpSelectSingle
      autoSelectOnSearch
      clearable
      searchFilter
      searchOnChange={setSearch}
      searchValue={search}
      data={options}
      placeholder="State"
      value={selectedOption}
      onChange={(val: any) => onChange(val?.id)}
    />
  );
}
