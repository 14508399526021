import { UseQueryOptions } from "@tanstack/react-query";
import { clientQueries } from "src/queries";
import { useQuery } from "src/react-query";
import { ClientUsageResponse } from "src/resources/clients.resource";

export default function useClientUsageQuery(queryOptions?: Omit<UseQueryOptions<ClientUsageResponse>, "queryKey">) {
  const query = useQuery({
    ...clientQueries.clientUsage(),
    gcTime: 0,
    staleTime: 0,
    ...queryOptions,
  });

  return {
    clientUsageQuery: query,
    clientUsage: query.data,
  };
}
