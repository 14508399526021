import React, { useState } from "react";
import PropTypes from "prop-types";
import { CpButton, CpDropdown, CpIcon } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import ExportClientListModal from "./export-client-list-modal.component";
import styles from "./kabob-menu.styles.css";
import useUserPrefsQuery from "src/common/queries/use-user-prefs-query.hook";
import { useHistory, useLocation } from "react-router-dom";
import { featureEnabled } from "feature-toggles!sofe";

export default function KabobMenu({
  currentView,
  showActiveClients,
  showInactiveClients,
  showArchivedClients,
  filterViews,
  filterViewOverrides,
  selection,
  currentFilterViewId,
  currentFilters,
  currentSortData,
  globalSearch,
  totalClientsCount,
  ft_crm,
}) {
  const [showExportModal, setShowExportModal] = useState(false);
  const hasBulkEmailAccess = useHasAccess("clients_bulk_email");
  const hasExportAccess = useHasAccess("clients_export");
  const hasImportAccess = useHasAccess("clients_import");
  const { userPrefs, updateUserPrefs } = useUserPrefsQuery();
  const history = useHistory();
  const location = useLocation();

  function importClients() {
    ft_crm
      ? history.push(`/crm/import?referrer=${encodeURIComponent(location.pathname)}`)
      : window.location.assign("#/clients/import");
  }

  function renderDisplayAs(clientNameDisplayBy, label) {
    const displayPref = userPrefs?.clientNameDisplayBy;
    return (
      <button
        className={styles.displayAsButton}
        onClick={() => {
          updateUserPrefs({ clientNameDisplayBy });
        }}
      >
        <div className="cp-flex-center cp-gap-16">
          {label}
          {(displayPref === clientNameDisplayBy || (!displayPref && clientNameDisplayBy === "first")) && (
            <CpIcon name="checkmark-large" fill="var(--cp-color-button-icon-active-text)" />
          )}
        </div>
      </button>
    );
  }

  return (
    <>
      <CpDropdown
        allowContentClicks
        contentWidth={!ft_crm ? "auto" : "sm"}
        renderTrigger={({ toggle }) => (
          <CpButton className="cp-ml-24" icon="misc-kabob" aria-label="More options" onClick={toggle} />
        )}
        renderContent={({ close }) => (
          <div className="cp-select-list">
            <div onClick={close}>
              {hasExportAccess && <button onClick={() => setShowExportModal(true)}>Export filtered list</button>}
              {currentView !== "Active Clients" && <button onClick={showActiveClients}>Active clients</button>}
              {currentView !== "Inactive Clients" && <button onClick={showInactiveClients}>Inactive clients</button>}
              {currentView !== "Archived Clients" && <button onClick={showArchivedClients}>Archived clients</button>}
              {hasImportAccess &&
                /** Import button should stay visible when ft_crm is off. If ft_crm is on, then it should only show when ft_crm_import is on. */
                (!ft_crm || featureEnabled("ft_crm_import")) && <button onClick={importClients}>Import clients</button>}
              {hasBulkEmailAccess && (
                <button onClick={() => window.location.assign("#/communications/global-settings/bulk-email-history")}>
                  View bulk emails
                </button>
              )}
            </div>
            {!ft_crm && (
              <>
                <div className={styles.divider}></div>
                {renderDisplayAs("first", "First Name, Last Name")}
                {renderDisplayAs("last", "Last Name, First Name")}
              </>
            )}
          </div>
        )}
      />
      <ExportClientListModal
        show={showExportModal}
        onClose={() => setShowExportModal(false)}
        filterViews={filterViews}
        filterViewOverrides={filterViewOverrides}
        selection={selection}
        currentFilterViewId={currentFilterViewId}
        currentFilters={currentFilters}
        currentSortData={currentSortData}
        globalSearch={globalSearch}
        totalClientsCount={totalClientsCount}
        ft_crm={ft_crm}
      />
    </>
  );
}

KabobMenu.propTypes = {
  ft_crm: PropTypes.bool,
  currentView: PropTypes.oneOf(["Inactive Clients", "Archived Clients", "Active Clients"]).isRequired,
  showActiveClients: PropTypes.func.isRequired,
  showInactiveClients: PropTypes.func.isRequired,
  showArchivedClients: PropTypes.func.isRequired,
};
