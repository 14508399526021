import { useMemo } from "react";
import { contactFilterViewQueries } from "src/queries";
import { useQuery } from "src/react-query";

export default function useContactFilterViewsQuery() {
  const query = useQuery(contactFilterViewQueries.filterViews());

  const filterViews = useMemo(
    () => query?.data?.filter_views?.sort((a: any, b: any) => a.filter_position - b.filter_position) || [],
    [query.data]
  );

  return {
    filterViewsQuery: query,
    filterViews,
  };
}
