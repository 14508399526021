import React from "react";
import { useSharedState } from "src/forms-lib";
import { InputDecorator } from "src/forms-lib";
import { FilingStatusSelect } from "src/common/form/client/base";

function ClientFilingStatus(props) {
  const sharedState = useSharedState();
  const { value, updateValue } = props;

  return (
    <>
      {!sharedState.is_business ? (
        <div className="cps-margin-bottom-16 width48">
          <FilingStatusSelect value={value} onChange={(val) => updateValue(val)} />
        </div>
      ) : null}
    </>
  );
}

export default InputDecorator(ClientFilingStatus);
