import React, { useMemo } from "react";
import { CpSelectMultiPillsField, CpLabel } from "canopy-styleguide!sofe";
import { useClientNamesQuery } from "src/common/queries/use-client-names-query.hook";
import { useDebouncedSearch } from "src/common/use-debounced-search.hook";
import { useFormContext } from "react-hook-form";
import { sortClientNames } from "./client-select-multi-pill-field.helpers";

type ClientSelectMultiPillFieldProps = {
  fieldName: string;
  label?: string;
  required?: boolean;
  // a list of client names that should always be enabled
  enabledClientNames?: string[];
};

export function ClientSelectMultiPillField({
  fieldName,
  label,
  required = false,
  enabledClientNames = [],
}: ClientSelectMultiPillFieldProps) {
  const { search, debouncedSearch, updateSearch } = useDebouncedSearch();
  const { clientNames } = useClientNamesQuery({
    search: debouncedSearch,
    includeClientGroups: true,
  });
  const sortedClientNames = useMemo(() => {
    return !clientNames ? [] : sortClientNames(clientNames, enabledClientNames);
  }, [clientNames, enabledClientNames]);
  const { control } = useFormContext();

  return (
    <div>
      <CpLabel required={required}>{label}</CpLabel>
      <CpSelectMultiPillsField
        control={control}
        data={sortedClientNames}
        fieldName={fieldName}
        placeholder="- Select clients -"
        required={required}
        searchFilter
        searchValue={search}
        searchOnChange={updateSearch}
        transformData={(client: any) => {
          const { name, display_name, client_groups, ...rest } = client;
          const isWhiteListed = enabledClientNames?.includes(name);
          const disabled = !isWhiteListed && !!client_groups;
          return {
            ...rest,
            name: (display_name || name) + (disabled ? ` (${client_groups})` : ""),
            disabled,
          };
        }}
        triggerIsBlock
        clearOnSelect
      />
    </div>
  );
}
