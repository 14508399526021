import { useEffect } from "react";
import { decode } from "querystring";
import showDedupeModal from "src/dedupe-modal/dedupe-modal.component";

export function useDedupeModal(searchParam) {
  useEffect(() => {
    const theQueryString = searchParam || "";
    const parsedQueryString = decode(theQueryString.toString().replace("?", ""));
    if (parsedQueryString?.import_id) {
      showDedupeModal({
        context: {},
        id: parsedQueryString.import_id,
        invalid_rows: parsedQueryString.invalid_rows,
      });
    }
  }, [searchParam]);
}
