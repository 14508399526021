import React, { useMemo, useState } from "react";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { selectCountries } from "src/common/utils.helpers.js";

interface CountrySelectProps {
  value: string;
  onChange: (value: string) => void;
}

export function CountrySelect({ value, onChange }: CountrySelectProps) {
  const [search, setSearch] = useState("");

  const options = useMemo(() => {
    return selectCountries.map(({ key, value }) => ({
      id: key,
      name: value,
    }));
  }, []);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.id === value);
  }, [options, value]);

  return (
    <CpSelectSingle
      autoSelectOnSearch
      triggerIsBlock
      clearable
      searchFilter
      searchOnChange={setSearch}
      searchValue={search}
      data={options}
      placeholder="Country"
      value={selectedOption}
      onChange={(val: any) => onChange(val?.id)}
    />
  );
}
