import React from "react";
import { SimpleSelect } from "src/form/select-input/select-input.component";
import { businessTypes } from "src/common/utils.helpers.js";

const options = businessTypes.map((type) => ({ key: type, value: type }));

export function BusinessTypeSelect({ value, onChange }) {
  return (
    <SimpleSelect
      name="business_type"
      options={options}
      labelText="Business type"
      clearable
      value={value}
      updateValue={onChange}
    />
  );
}
