import React from "react";
import useUserPrefsQuery from "src/common/queries/use-user-prefs-query.hook";

export default function ClientNameCell({ resource }) {
  const { userPrefs } = useUserPrefsQuery();

  return (
    <a href={`#/client/${resource.id}`} onContextMenu={(e) => e.stopPropagation()}>
      {userPrefs?.clientNameDisplayBy === "last" && !!resource.last_name
        ? `${[resource.last_name, resource.first_name].filter((val) => val).join(", ")}`
        : resource.name}
    </a>
  );
}
ClientNameCell.overrideEmptyCell = true;
