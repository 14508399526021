import React, { ForwardedRef, forwardRef, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useHasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";
import FieldSection from "src/common/field-section.component";
import RolesFormData from "src/common/roles-section/roles-section.component";
import UsersTeamsSection from "src/common/users-teams-section/users-teams-section.component";
import { isUserIncluded } from "src/common/roles-section/roles.helpers";
import { useRolesQuery } from "src/queries";

interface RolesSectionProps {
  control: any;
  watch: (fieldName: string) => void;
}

export const RolesSection = forwardRef(({ control, watch }: RolesSectionProps, ref: ForwardedRef<any>) => {
  const { roles } = useRolesQuery();
  const [loggedInUser] = useWithUserAndTenant();
  const hasFullClientAccess = useHasAccess("clients_not_assigned");
  const hasRolesAccess = useHasAccess("company_settings_roles");

  const roleValues: any = watch("roles");
  const userTeamsValue: any = watch("userTeams");

  const showCurrentUserNotAssigned = useMemo(() => {
    if (hasFullClientAccess) return false;
    return !isUserIncluded(loggedInUser.id, { ...roleValues, userTeamsValue });
  }, [roleValues, userTeamsValue, loggedInUser.id, hasFullClientAccess]);

  return (
    <FieldSection label={hasRolesAccess ? "Roles" : "User/Team Assignments"} ref={ref}>
      {hasRolesAccess && roles.length > 0 && (
        <Controller
          name="roles"
          control={control}
          render={({ field: { value, onChange } }) => (
            <RolesFormData
              roles={roles}
              value={(roleId: string) => value?.[roleId] || []}
              onChange={(newValue: any, roleId: string) => {
                onChange({ ...roleValues, [roleId]: newValue });
              }}
              showCurrentUserNotAssigned={showCurrentUserNotAssigned}
            />
          )}
        />
      )}
      <Controller
        name="userTeams"
        control={control}
        render={({ field: { value, onChange } }) => (
          <UsersTeamsSection
            value={value}
            onChange={onChange}
            hasRoles={roles.length > 0}
            showLabel={hasRolesAccess}
            showCurrentUserNotAssigned={roles.length === 0 && showCurrentUserNotAssigned}
          />
        )}
      />
    </FieldSection>
  );
});
