import React from "react";
import { CpInput } from "canopy-styleguide!sofe";
import FieldSection from "src/common/field-section.component";
import { ContactInfoFields } from "src/common/form/contact/contact-info-fields.component";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { TContact } from "src/common/types";
import { useFormContext } from "react-hook-form";

type ContactInfoSectionProps = {
  contact?: TContact | Omit<TContact, "id">;
};

export function ContactInfoSection({ contact }: ContactInfoSectionProps) {
  useCssModules(css);
  const { control, watch } = useFormContext();

  return (
    <FieldSection label="Contact Info">
      {contact?.client_portal_email && (
        <CpInput
          className={s.clientPortalEmail}
          label="Client portal login"
          disabled
          value={contact.client_portal_email}
        />
      )}
      <ContactInfoFields control={control} watch={watch} />
    </FieldSection>
  );
}

const { css, s } = cssModules`
  .clientPortalEmail {
    max-width: fit-content;
  }
`;
