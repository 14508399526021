import React, { useEffect } from "react";
import { Control, useController } from "react-hook-form";

export function useModeValidation({
  fieldName,
  control,
  mode,
  elToScrollToRef,
}: {
  fieldName: string;
  control: Control<any>;
  mode: string;
  elToScrollToRef: React.RefObject<HTMLElement>;
}) {
  const {
    fieldState: { error: modeError },
  } = useController({
    name: `${fieldName}.mode_validation`,
    control,
    rules: {
      validate: {
        isPendingChanges: (value: any) => {
          if (mode === "edit" || mode === "create") {
            return "Contact has unsaved changes";
          }
          return true;
        },
      },
    },
  });

  useEffect(() => {
    if (elToScrollToRef.current && modeError) {
      elToScrollToRef.current.scrollIntoView(false);
    }
  }, [elToScrollToRef, modeError]);

  return modeError;
}
