import React from "react";
import { CpEmptyState, CpInput, CpLoader } from "canopy-styleguide!sofe";
import styles from "./template-list.styles.css";
import { useTemplatesQuery } from "../use-templates-query.hook";
import { isEmpty } from "lodash";
import { useDebouncedSearch } from "src/common/use-debounced-search.hook";

export default function TemplateList({ onTemplateSelect, isBusiness }) {
  const { search, debouncedSearch, updateSearch } = useDebouncedSearch();

  const type = isBusiness === true ? "business" : isBusiness === false ? "individual" : "both";
  const { templates } = useTemplatesQuery({
    search: debouncedSearch,
    type,
  });

  return (
    <div className={styles.container}>
      <div className={`${styles.section} cp-p-8`}>
        <CpInput isSearch placeholder="Search client record templates" value={search} onChange={updateSearch} />
      </div>
      <div className={`${styles.section} cp-select-list`}>
        {!templates ? (
          <CpLoader />
        ) : isEmpty(templates) ? (
          <CpEmptyState
            className="cp-pb-8"
            img="es_magnifying_glass"
            text="No search results"
            subText="Please refine your search criteria"
          />
        ) : (
          templates.map((template) => (
            <button key={template.id} type="button" onClick={() => onTemplateSelect(template.id)}>
              <span className="cp-ellipsis">{template.name}</span>
            </button>
          ))
        )}
      </div>
    </div>
  );
}
