import { UseQueryOptions } from "@tanstack/react-query";
import { clientQueries } from "src/queries";
import { useQuery } from "src/react-query";

export function useClientGroupsQuery(
  params: { search?: string; strict?: boolean; ids?: string[]; page?: number; limit?: string },
  queryOptions?: Omit<UseQueryOptions, "queryKey">
) {
  const query = useQuery<any>({
    ...clientQueries.clientGroups(params),
    ...queryOptions,
  });

  return {
    clientGroupsQuery: query,
    clientGroups: query.data?.groups,
    currentPage: query.data?.pagination_data?.current_page,
    totalPages: query.data?.pagination_data?.total_pages,
  };
}
