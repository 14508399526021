import React from "react";
import { Scoped } from "kremling";
// project
import styles from "./type-and-longevity.krem.css";
import ClientSince from "./client-since/client-since.component.js";
import TypeSection from "./type-section/type-section.component.js";

export default class TypeAndLongevity extends React.Component {
  render() {
    return (
      <Scoped css={styles}>
        <div className="TypeAndLongevitySection">
          <div className="type-buttons-section">
            <TypeSection shareValue={true} initialValue="client" name="client_type" />
          </div>
          <div className="client-since-section">
            <ClientSince sharedKey={"client_type"} />
          </div>
        </div>
      </Scoped>
    );
  }
}
