import React from "react";
import { CpLabel, CpSelectSingle, CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import { Control, Controller } from "react-hook-form";
import { cssModules, useCssModules } from "inline-css-modules-react";

interface ContactTypeProps {
  control: Control;
  fieldName: string;
  disabled?: boolean;
  hasPrimaryContact?: boolean;
  isBusiness?: boolean;
  required?: boolean;
  showLabelTooltip?: boolean; // Whether to show the icon tooltip on the label
}

export const contactTypes = [
  {
    id: "primary",
    name: "Primary contact",
  },
  {
    id: "spouse",
    name: "Spouse",
    individualOnly: true,
  },
  {
    id: "dependent",
    name: "Dependent",
    individualOnly: true,
  },
  {
    id: "other",
    name: "Other",
  },
];

export function ContactType({
  control,
  fieldName,
  disabled,
  required,
  hasPrimaryContact,
  isBusiness,
  showLabelTooltip,
}: ContactTypeProps) {
  useCssModules(css);
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={hasPrimaryContact ? "other" : "primary"}
      rules={required === false ? {} : { required: "Contact type is required" }}
      render={({ field: { value, onChange } }) => (
        <div>
          <CpLabel className={s.fixedLabelHeight}>
            <div className={`flex cp-gap-8 items-center ${s.fixedLabelHeight}`}>
              <div>Contact type</div>
              {!isBusiness && showLabelTooltip && (
                <CpTooltip text="The primary contact's info will be used as the taxpayer in other areas of the app. The email on the primary contact will be used in bulk email actions. Only one primary contact and one spouse are allowed.">
                  <CpIcon name="information-circle-open-small" />
                </CpTooltip>
              )}
            </div>
          </CpLabel>
          <CpSelectSingle
            triggerIsBlock
            onChange={(value: any) => onChange(value?.id)}
            value={contactTypes.find((type) => type.id === value)}
            placeholder="Select"
            data={isBusiness ? contactTypes.filter((t) => !t.individualOnly) : contactTypes}
            disabled={disabled}
          />
        </div>
      )}
    />
  );
}

const { css, s } = cssModules`
  .fixedLabelHeight {
    height: 2.05rem;
  }
`;
