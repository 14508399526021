export const contactsSortOrder: { [key: string]: number } = {
  primary: 1,
  spouse: 2,
  dependent: 3,
};

type SortableContact = {
  contact_type?: string;
  first_name?: string;
  last_name?: string;
};

export const contactsCompareFn = (a: SortableContact, b: SortableContact) => {
  const rankA = contactsSortOrder[a.contact_type || ""] || Infinity;
  const rankB = contactsSortOrder[b.contact_type || ""] || Infinity;

  if (rankA !== rankB) {
    return rankA - rankB;
  }

  const aLastName = a.last_name || "";
  const bLastName = b.last_name || "";
  const lastNameComparison = aLastName.localeCompare(bLastName);
  if (lastNameComparison !== 0) {
    return lastNameComparison;
  }

  const aFirstName = a.first_name || "";
  const bFirstName = b.first_name || "";
  return aFirstName.localeCompare(bFirstName);
};
