import React, { lazy, Suspense } from "react";
import { HashRouter, Route, Redirect, useParams, useLocation } from "react-router-dom";
import { ErrorBoundary } from "error-logging!sofe";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "src/react-query";
// project
import ClientList from "./client-list/client-list.component";
import ClientHomePage from "./client-home-page/client-home-page.component.js";
import ContactList from "./contact-list/contact-list.component";
const PortalRoutes = lazy(() => import("./portal/portal-routes.component"));
const ClientPageOld = lazy(() => import("./client-page-old/client-page.component.js"));
const ClientPage = lazy(() => import("./client-page/client-page.component.tsx"));
const ClientGroupsPage = lazy(() => import("./client-groups-page/client-groups-page.component.tsx"));
const CustomFieldsSettings = lazy(() =>
  import("./global-settings/custom-fields/settings-page/custom-field-settings.component.js")
);
const ImportClientsPage = lazy(() => import("./import/old/import-clients-page.component.js"));
const ImportRoutes = lazy(() => import("./import/import-routes.component.tsx"));
const TemplatesPage = lazy(() => import("./templates/templates-page.component"));
const MigrationPreviewPage = lazy(() => import("./migration/preview-page/preview-page.component.tsx"));
// sofe
import { hasAccess, UserTenantProps, useHasAccess } from "cp-client-auth!sofe";
import { CpLoader } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { useShowCrmMigration } from "./migration/hooks/use-show-crm-migration.hook";

@ErrorBoundary({ featureName: "clients", noStrictMode: false })
@UserTenantProps({ waitForData: true })
export default class Root extends React.Component {
  constructor(props) {
    super(props);
    this.isClient = props.loggedInUser.role === "Client";
  }
  render() {
    if (this.isClient) {
      return null;
    }

    const userHasAccess = hasAccess(this.props.loggedInUser);
    const crmHierarchyEnabled = featureEnabled("ft_crm") && this.props.tenant?.crm_status === "crm_hierarchy_complete";

    return (
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <Suspense
            fallback={
              <div className="cp-flex-center" style={{ height: "100vh" }}>
                <CpLoader size={"lg"} center />
              </div>
            }
          >
            <Route path="/clients" exact>
              <RedirectWithQuery to="/clients/list/active" />
            </Route>
            <Route path="/clients/list" exact>
              <RedirectWithQuery to="/clients/list/active" />
            </Route>
            <Route path="/clients/list/:id">
              <Private permitted={userHasAccess("clients")}>
                <ClientList />
              </Private>
            </Route>
            <Route path="/client-groups">
              <Private permitted={crmHierarchyEnabled && userHasAccess("clients")}>
                <ClientGroupsPage />
              </Private>
            </Route>
            <Route
              exact={crmHierarchyEnabled}
              path={crmHierarchyEnabled ? "*/client/:id([0-9]+)" : "*/client(s)?/:id([0-9]+)"}
            >
              {crmHierarchyEnabled ? <ClientPage /> : <ClientPageOld />}
            </Route>
            {this.props.loggedInUser.role !== "" && (
              <Route path="/client/:id" exact>
                <ClientHomePage user={this.props.loggedInUser} />
              </Route>
            )}
            <Route path="/crm-migration-preview">
              <PrivateMigrationRoute>
                <MigrationPreviewPage />
              </PrivateMigrationRoute>
            </Route>
            <Route path="/contacts" exact>
              <Redirect to="/contacts/list/active" />
            </Route>
            <Route path="/contacts/list" exact>
              <Redirect to="/contacts/list/active" />
            </Route>
            <Route path="/contacts/list/:id">
              <Private permitted={crmHierarchyEnabled && userHasAccess("clients")}>
                <ContactList />
              </Private>
            </Route>
            <Route path="/contacts/portal">
              <Redirect to="/portals" />
            </Route>
            <Route path="/contacts/portal/:listType">
              <RedirectToPortals />
            </Route>
            <Route path="/portals">
              <Private permitted={!crmHierarchyEnabled && userHasAccess("clients_manage_client_portals")}>
                <PortalRoutes />
              </Private>
            </Route>
            <Route path="/contacts/global-settings/custom-fields">
              <Redirect to="/global-settings/custom-fields" />
            </Route>
            <Route exact path="/global-settings/custom-fields">
              <Redirect to="/global-settings/custom-fields/individuals" />
            </Route>
            <Route path="/global-settings/custom-fields/:clientType">
              <Private permitted={userHasAccess("company_settings_custom_fields")}>
                <CustomFieldsSettings />
              </Private>
            </Route>
            {crmHierarchyEnabled ? (
              <Route path="/crm/import">
                <Private permitted={userHasAccess("clients_import")}>
                  <ImportRoutes />
                </Private>
              </Route>
            ) : (
              <>
                <Route path="/contacts/import">
                  <Redirect to="/clients/import" />
                </Route>
                <Route path="/clients/import">
                  <Private permitted={userHasAccess("clients_import")}>
                    <ImportClientsPage />
                  </Private>
                </Route>
              </>
            )}
            <Route path="/templates/client-records">
              <Private permitted={userHasAccess("templates_client_records")}>
                <TemplatesPage />
              </Private>
            </Route>
          </Suspense>
        </HashRouter>
      </QueryClientProvider>
    );
  }
}

const Private = ({ children, permitted }) => {
  return permitted ? children : <Redirect to="/403" />;
};

const RedirectToPortals = () => {
  const { listType } = useParams();
  return <Redirect to={`/portals${listType ? `/${listType}` : ""}`} />;
};

const RedirectWithQuery = ({ to }) => {
  // TODO: ft_crm: this is my hacky way to preserve query params in the redirect. We only need this to continue supporting triggering the old import dedupe modal via query params and can be removed ft_crm is released with the updated import experience.
  const location = useLocation();
  return <Redirect to={to + location.search} />;
};

const PrivateMigrationRoute = ({ children }) => {
  const { showCrmMigration } = useShowCrmMigration();
  const hasMigrationAccess = useHasAccess("crm_migration_access");
  return showCrmMigration === undefined ? null : (
    <Private permitted={showCrmMigration && hasMigrationAccess}>{children}</Private>
  );
};
