import { useMemo } from "react";
import { useQuery } from "src/react-query";
import { roleQueries } from "global-settings/externals!sofe";

export function useRolesQuery() {
  const query = useQuery(roleQueries.roles());
  const roles = useMemo(() => query.data || [], [query.data]);
  return {
    rolesQuery: query,
    roles,
  };
}
