import React from "react";
import { Control } from "react-hook-form";
import { MultiField } from "./multi-field.component";
import { PhoneField, PhoneType } from "./phone-field.component";

type MultiPhoneFieldProps = {
  fieldName: string;
  control: Control;
  watch: (field: string) => any;
  phoneTypes?: PhoneType[];
};

export function MultiPhoneField({ fieldName, control, watch, phoneTypes }: MultiPhoneFieldProps) {
  return (
    <div className="flex flex-col cp-gap-16">
      <MultiField fieldName={fieldName} control={control} watch={watch} resourceName="phone">
        {({ index, renderOptions }) => (
          <div className="flex cp-gap-16 justify-between">
            <div className="grow">
              <PhoneField fieldName={`${fieldName}.${index}`} control={control} watch={watch} phoneTypes={phoneTypes} />
            </div>
            {renderOptions()}
          </div>
        )}
      </MultiField>
    </div>
  );
}
