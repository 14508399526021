import { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "src/react-query";
import { clientQueries } from "src/queries";

export function useClientsQuery(
  params: {
    search?: string;
    sort_data?: any[];
    filters?: any;
    page?: number;
    limit?: string;
    visibleColumns?: string[];
    includeActive?: boolean;
  } = {},
  queryOptions?: Omit<UseQueryOptions, "queryKey">
) {
  const query = useQuery<any>({
    ...clientQueries.clients({
      ...params,
      filters: {
        ...(params.includeActive
          ? {
              is_active: [{ equal_to: true }],
              is_archived: [{ equal_to: false }],
            }
          : {}),
        ...params.filters,
      },
    }),
    ...queryOptions,
  });

  return {
    clientsQuery: query,
    clients: query.data?.clients,
  };
}
