import React from "react";
// project
import useFormInput from "src/forms-lib/use-form-input.hook.js";
import { ClientBusinessToggle } from "src/common/form/client/base";

export default function BusinessSection() {
  const [value, updateValue] = useFormInput({
    name: "is_business",
    shareValue: true,
    initialValue: false,
  });

  // also delete business-section.krem.css and it's import here when removing the feature toggle below
  return (
    <div className="cp-mh-32 cp-mt-24">
      <ClientBusinessToggle value={value} onChange={(val) => updateValue(val)} />
    </div>
  );
}
