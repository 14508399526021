import React, { useRef, useCallback } from "react";
import { CpTooltip, CpIcon } from "canopy-styleguide!sofe";
import { debounce } from "lodash";
// project
import Input from "src/form/input/input.component";
import { useFormInput } from "src/forms-lib";
import { isDisplayNameAvailable } from "./display-name.resource";
import { FieldLabel } from "src/common/form/field-label.component";
import { handleError } from "src/error";

export default function DisplayNameSection(props) {
  const [displayName, setDisplayName, more] = useFormInput({
    name: "display_name",
    asyncValidation: true,
  });

  const { showInvalid, valid, setValid, setValidating } = more;
  const initialValue = useRef(displayName);

  const debouncedValidateName = useCallback(debounce(validateName, 500), []); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  function validateName(value) {
    isDisplayNameAvailable(value).subscribe((result) => {
      setValid(result.available);
      setValidating(false);
    }, handleError);
  }

  function onInput(evt) {
    const value = evt.target.value.trim();
    if (!value || value.toLowerCase() === initialValue.current.toLowerCase()) return;
    debouncedValidateName(value);
    setValidating(true);
  }

  function createLabel() {
    return (
      <FieldLabel className="cp-mt-16">
        <span>Display name</span>
        <CpTooltip text="Display names must be unique.">
          <CpIcon name="information-circle-open-small" fill="var(--cp-color-app-icon)" />
        </CpTooltip>
      </FieldLabel>
    );
  }

  return (
    <Input
      maxLength={150}
      name="display_name"
      placeholder="Display name"
      type="text"
      required={false}
      label={createLabel}
      onInput={onInput}
      value={displayName}
      updateValue={setDisplayName}
      valid={valid}
      showInvalid={showInvalid}
      invalidMessage={"This display name is already being used for another client."}
    />
  );
}
