import { DateTime } from "luxon";

export function formDataToContact(form: any) {
  return {
    first_name: form.first_name,
    last_name: form.last_name,
    middle_name: form.middle_name,
    emails: form.emails
      .filter((e: any) => !!e.email)
      .map((e: any) => ({
        is_primary: e.isPrimary,
        key: e.type,
        value: e.email,
      })),

    phones: form.phones
      .filter((p: any) => !!p.phone)
      .map((p: any) => ({
        is_primary: p.isPrimary,
        key: p.type,
        number: p.phone,
        extension: p.extension,
        value: p.phone,
      })),

    addresses: form.addresses
      .filter(
        (a: any) => !!a.address_1 || !!a.address_2 || !!a.country || !!a.region || !!a.locality || !!a.postal_code
      )
      .map((a: any) => ({
        is_primary: a.isPrimary,
        key: a.type,
        value: {
          address_1: a.address_1,
          address_2: a.address_2,
          country: a.country,
          region: a.region,
          locality: a.locality,
          postal_code: a.postal_code,
        },
      })),

    birthdate: DateTime.fromJSDate(form.birthdate).toISODate(),
    tin: form.ssn,
    employer: form.employer,
    occupation: form.occupation,

    clients: form.assigned_clients
      ?.map((ac: any) => {
        if (!ac.client) return null;
        return {
          id: ac.client.id,
          contact_description: ac.contact_description,
          contact_type: ac.contact_type,
        };
      })
      .filter((ac: any) => !!ac),
  };
}

export function contactToFormData(contact: any) {
  return {
    ...contact,
    emails: contact.emails?.map((e: any) => ({
      isPrimary: e.is_primary,
      type: e.key,
      email: e.value,
    })) || [{ isPrimary: true }],
    phones: contact.phones?.map((e: any) => ({
      isPrimary: e.is_primary,
      type: e.key,
      phone: e.number,
      extension: e.extension,
    })) || [{ isPrimary: true }],
    addresses: contact.addresses?.map((e: any) => ({
      ...e.value,
      isPrimary: e.is_primary,
      type: e.key,
    })) || [{ isPrimary: true, country: "United States" }],
    ssn: contact.tin,
    assigned_clients: contact.clients
      ?.sort((a: any, b: any) => {
        if (a.is_primary) return -1;
        return a.name.localeCompare(b.name);
      })
      ?.map((c: any) => ({
        client: c,
        contact_type: c.contact_type,
        contact_description: c.contact_description,
      })),
  };
}
