import React from "react";
import { useHasAccess } from "cp-client-auth!sofe";
import SelectTemplateButton from "src/templates/select-template-button/select-template-button.component";
import { mapIdsToItems } from "src/common/utils.helpers";
import { useTeamMembersQuery } from "src/common/queries/use-team-members-query.hook";
import useCustomFieldsQuery from "src/common/queries/use-custom-fields-query.hook";
import { useTagsQuery } from "src/common/queries/use-tags-query.hook";
import { UseFormReset } from "react-hook-form";

type TemplateButtonProps = {
  isBusiness: boolean;
  isFormDirty: boolean;
  isEdit: boolean;
  reset: UseFormReset<any>;
  getValues: () => any;
};

// Converts custom field data from a template into form data for the create/edit client form
export function templateCustomFieldsToFormData(dbCustomFields: any, templateCustomFields: any) {
  const customFieldsFormData: { [key: string]: any } = {};
  dbCustomFields.forEach((dbCustomField: any) => {
    const templateCustomField = templateCustomFields?.find((cf: any) => dbCustomField.field_id === cf.field_id);
    if (templateCustomField) {
      let dropdownValues: any[] = [];
      if (!!templateCustomField.dropdown_values) {
        templateCustomField.dropdown_values.forEach((valueId: string) => {
          const valueData = dbCustomField.dropdown_values.find((dv: any) => valueId === dv.id);
          dropdownValues.push({
            key: valueData.id,
            value: valueData.value,
          });
          if (dbCustomField.field_type === "dropdown") {
            dropdownValues = dropdownValues[0];
          }
        });
      }
      customFieldsFormData[templateCustomField.field_id] = templateCustomField.dropdown_values
        ? dropdownValues
        : templateCustomField.value;
    }
  });
  return customFieldsFormData;
}

// Converts assignment role data from a template into form data for the create/edit client form
export function templateRoleAssignmentsToFormData(dbTeamMembers: any, dbTeams: any, templateRoles: any) {
  const formData: { [key: string]: any } = {};
  templateRoles?.forEach((templateRole: any) => {
    const users = mapIdsToItems(templateRole.users, dbTeamMembers);
    const teams = mapIdsToItems(templateRole.teams, dbTeams);
    formData[templateRole.role_id] = [...users, ...teams];
  });
  return formData;
}

export function templateUserTeamsToFormData(dbTeamMembers: any, dbTeams: any, userTeams: any) {
  if (!userTeams) return [];
  const users = mapIdsToItems(userTeams.users, dbTeamMembers);
  const teams = mapIdsToItems(userTeams.teams, dbTeams);
  return [...users, ...teams];
}

export function TemplateButton({ isBusiness, isFormDirty, isEdit, reset, getValues }: TemplateButtonProps) {
  const { teamMembers, teams } = useTeamMembersQuery(false);
  const { customFields } = useCustomFieldsQuery();
  const { tags } = useTagsQuery();
  const canSetActive = useHasAccess("clients_toggle_active");

  function onApplyTemplate(template: any) {
    const clientOwner = teamMembers.find((tm: any) => tm.id === template.client_owner_id);
    const customFieldsFormData = templateCustomFieldsToFormData(customFields, template.custom_fields);
    const roleAssignmentsFormData = templateRoleAssignmentsToFormData(
      teamMembers,
      teams,
      template.roles?.role_assignments
    );

    const newValues: any = {
      ...getValues(),
      client_type: template.type,
      business_type: template.business_type,
      filing_status: template.filing_status,
      industry: template.industry,
      custom_fields: customFieldsFormData,
      roles: roleAssignmentsFormData,
      userTeams: templateUserTeamsToFormData(teamMembers, teams, template.roles),
      tags: mapIdsToItems(template.tags, tags),
    };

    if (canSetActive) newValues.is_active = template.is_active;
    if (!isEdit) newValues.is_business = template.is_business;
    if (clientOwner) newValues.client_owner = clientOwner;

    reset(newValues, { keepDefaultValues: true });
  }

  return (
    <SelectTemplateButton
      onApplyTemplate={onApplyTemplate}
      isManageAssignments={false}
      isBusiness={isBusiness}
      validateForm={() => [isFormDirty]}
    />
  );
}
