import { of, zip } from "rxjs";
import { pluck, concatMap, map, tap } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";
import { hasAccess } from "cp-client-auth!sofe";
import { updateSource } from "../resources/client-sources.resource.js";

export function updateClient(loggedInUser, client, originalTags, roles) {
  const rolesUsersTeams = client.rolesUsersTeams;
  // We don't want to send this up for api/clients, only for the assignments endpoint
  delete client.rolesUsersTeams;

  const updateClientStream = fetchAsObservable(
    `api/clients/${client.id}?include=users,clients,tags,client_for,client_sources`,
    {
      method: "PUT",
      body: {
        clients: client,
      },
    }
  ).pipe(pluck("clients"));

  const updateTags = updateAllTags(originalTags, client.tags, client.id);

  let updateRoles;
  if (hasAccess(loggedInUser)("clients_assign_team_members")) {
    const roleIdsToUpdate = rolesUsersTeams.role_assignments.map((r) => r.role_id);
    const emptyRoles = [];
    // Populate empty roles and concat them to the existing roles so that BE knows to clear them
    roles.forEach((r) => {
      if (!roleIdsToUpdate.includes(r.id)) {
        emptyRoles.push({
          role_id: r.id,
          teams: [],
          users: [],
        });
      }
    });
    updateRoles = putRoleAssignments(client.id, {
      ...rolesUsersTeams,
      role_assignments: rolesUsersTeams.role_assignments.concat(emptyRoles),
    });
  } else {
    updateRoles = of([]);
  }

  return updateTags.pipe(
    concatMap((tagsResponse) => updateSource(client, client)),
    concatMap(() => updateRoles),
    concatMap((res) => updateClientStream),
    tap((client) => {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent(`cp:clients:${client.id}-edited`));
      }, 0);
    })
  );
}

function updateAllTags(oTags, tags = [], clientId) {
  const tagsToRemove = oTags.reduce((acc, oTag) => {
    if (tags.find((newTag) => (newTag.name || newTag) === oTag.name)) {
      return acc;
    } else {
      return acc.concat(oTag.id);
    }
  }, []);
  const tagsToAdd = tags.filter((tag) => !oTags.some((oldTag) => oldTag.name === (tag.name || tag)));
  return zip(
    tagsToRemove.length > 0 ? removeTags(tagsToRemove, clientId) : of(tagsToRemove),
    tagsToAdd.length > 0 ? addTagsToClient(tagsToAdd, clientId) : of(tagsToAdd)
  );
}

function removeTags(tagIds = [], clientId) {
  const all = tagIds.map((tagId) =>
    fetchAsObservable(`api/clients/${clientId}/tags/${tagId}`, {
      method: "DELETE",
    })
  );
  return zip(...all);
}

export function createClient(loggedInUser, client, withEvent = true, syncOverrideObj = {}) {
  const rolesUsersTeams = client.rolesUsersTeams;
  // We don't want to send this up for api/clients, only for the assignments endpoint
  delete client.rolesUsersTeams;

  const tags = client.tags;
  // We don't want to send this up for api/clients, only for the tags endpoint
  delete client.tags;

  const createClientStream = fetchAsObservable(`api/clients`, {
    method: "POST",
    // TODO: remove second syncOverrideObj after on prod and BE on prod, this is here for backwards compatibility 
    // because we are moving these props into the clients object instead. 
    body: { clients: {...client, ...syncOverrideObj}, ...syncOverrideObj },
  }).pipe(pluck("clients"));

  return createClientStream.pipe(
    concatMap((clientResults) => {
      let tags$;
      if (tags != undefined && tags.length > 0) {
        tags$ = addTagsToClient(tags, clientResults.id).pipe(map((value) => clientResults));
      } else {
        tags$ = of(clientResults);
      }
      let source$;
      if (client.source && client.source.name) {
        source$ = updateSource(client, clientResults);
      } else {
        source$ = of(clientResults);
      }

      let roles$;
      if (rolesUsersTeams && hasAccess(loggedInUser)("clients_assign_team_members")) {
        roles$ = putRoleAssignments(clientResults.id, rolesUsersTeams);
      } else {
        roles$ = of(clientResults);
      }

      return zip(tags$, source$, roles$).pipe(
        map(([tags, source, roles]) => {
          return clientResults;
        })
      );
    }),
    tap((client) => {
      setTimeout(() => {
        if (withEvent) {
          window.dispatchEvent(new CustomEvent(`cp:clients:global-client-created`));
        }
      }, 0);
    })
  );
}

function addTagsToClient(tags = [], clientId) {
  if (clientId == undefined) {
    throw new Error("cannot add Tags to an undefined client");
  }
  return fetchAsObservable(`api/tags`, {
    method: "POST",
    body: {
      tags: createTags(tags, clientId),
    },
  });
}

function createTags(tags, clientId) {
  return tags.map((tag) => {
    return {
      name: tag.name || tag,
      relationship_type: "clients",
      relationship_id: clientId,
    };
  });
}

export function putRoleAssignments(clientId, body) {
  return fetchAsObservable(`api/clients/${clientId}/assignments`, {
    method: "PUT",
    body,
  });
}
