import { fetchAsObservable } from "fetcher!sofe";

export function createRole(label) {
  return fetchAsObservable("/api/roles", {
    method: "POST",
    body: {
      label,
    },
  });
}

export function getRoles() {
  return new Promise((resolve, reject) => {
    fetchAsObservable("/api/roles").subscribe(resolve, reject);
  });
}
