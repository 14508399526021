import { useMemo } from "react";
import { useQuery } from "src/react-query";
import { clientFilterViewQueries } from "src/queries";

export default function useFilterViewsQuery() {
  const query = useQuery(clientFilterViewQueries.filterViews());

  const filterViews = useMemo(
    () => query?.data?.filter_views?.sort((a, b) => a.filter_position - b.filter_position) || [],
    [query.data]
  );

  return {
    filterViewsQuery: query,
    filterViews,
  };
}
