// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-modal-upgrade-clients-modal-upgrade-clients-modal-styles__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
}

.src-modal-upgrade-clients-modal-upgrade-clients-modal-styles__iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  border: unset;
  font-size: 1.3rem;
  color: var(--cps-color-cool-gray);
  background-color: var(--cps-color-bumble);
  border-radius: 0.5rem;
}

.src-modal-upgrade-clients-modal-upgrade-clients-modal-styles__iconButton cps-icon {
  color: var(--cps-color-monsoon);
}

.src-modal-upgrade-clients-modal-upgrade-clients-modal-styles__iconButton:hover,
.src-modal-upgrade-clients-modal-upgrade-clients-modal-styles__iconButton:focus {
  background-color: var(--cps-color-chrome);
}

.src-modal-upgrade-clients-modal-upgrade-clients-modal-styles__body {
  height: 34.6rem;
}
`, "",{"version":3,"sources":["webpack://./src/modal/upgrade-clients-modal/upgrade-clients-modal.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,aAAa;EACb,iBAAiB;EACjB,iCAAiC;EACjC,yCAAyC;EACzC,qBAAqB;AACvB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;;EAEE,yCAAyC;AAC3C;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: none;\n}\n\n.iconButton {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 3.2rem;\n  height: 3.2rem;\n  border: unset;\n  font-size: 1.3rem;\n  color: var(--cps-color-cool-gray);\n  background-color: var(--cps-color-bumble);\n  border-radius: 0.5rem;\n}\n\n.iconButton cps-icon {\n  color: var(--cps-color-monsoon);\n}\n\n.iconButton:hover,\n.iconButton:focus {\n  background-color: var(--cps-color-chrome);\n}\n\n.body {\n  height: 34.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `src-modal-upgrade-clients-modal-upgrade-clients-modal-styles__header`,
	"iconButton": `src-modal-upgrade-clients-modal-upgrade-clients-modal-styles__iconButton`,
	"body": `src-modal-upgrade-clients-modal-upgrade-clients-modal-styles__body`
};
export default ___CSS_LOADER_EXPORT___;
